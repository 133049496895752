import velas from '../../assets/img/velas.png'
import fundo from '../../assets/img/vermelho-veludo.jpg'
import styled from "styled-components";
import {BotaoDestaque} from "../../ui/ComponentesDeEstilo";
export const DobraLoja=()=>{
    const handleProximo=()=>{
        window.location.href='/landing-page#comecar'
    }
    return<>
        <div id={'loja'}></div>
        <Recipiente>
            <Fotos>
                <Img src={velas} alt=""/>
            </Fotos>
            <div>
                <Titulo>Dropshipping de artigos esotéricos</Titulo>
                <Paragrafo>
                    Agendamentos e consultas por vídeo chamada em seu site proporcionam uma maneira conveniente e acessível para conectar-se com seus clientes. Oferecemos uma solução integrada que permite aos usuários agendar facilmente suas consultas online, proporcionando uma experiência personalizada e eficiente. A plataforma de vídeo chamada garante interações em tempo real, proporcionando a flexibilidade de receber consultas e realizar reuniões virtuais, promovendo um atendimento de qualidade sem a necessidade de deslocamento. Simplifique o processo de agendamento e proporcione um serviço excepcional aos seus clientes, tudo isso diretamente através do seu site.
                </Paragrafo>
                <Botao onClick={handleProximo}>Começar</Botao>
            </div>
        </Recipiente>
    </>
}
const Botao = styled(BotaoDestaque)`
    color: black;
    padding: 1em 3em;
    margin-top: 2em;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eca342;
`;
const Titulo=styled.h1`
    text-align: left;
    font-weight: 300;
    font-size: 300%;
    color: white;
    margin-top:4em;
`
const Paragrafo=styled.p`
    width: 650px;
    margin-top: 2em;
    line-height: 1.5em;
    font-size: 120%;
    margin-bottom: 2em;
    color:white;
`
const Img=styled.img`
    border-top: 1px solid black;
    border-bottom: 3px solid black;
`
const Fotos=styled.div`
    background-color: whitesmoke;
    padding: 5em 0 3em 0;
    max-width: 593px;
    max-height: 300px;
    box-shadow: 1px 3px 1px 3px rgba(0, 0, 0, 0.92);
    border-radius: .5em;
`
const Recipiente=styled.div`
    background-image: url(${fundo});
    color: black;
    padding: 7em 11em 10em 11em;
    background-color: whitesmoke;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
`