import styled from "styled-components"
export const CardPainelDeControle=(props)=>{
    return<>
        <Cartao color={props.corFundo}>
            <Quantidade color={props.corQTD}>{props.quantidade}</Quantidade>
            <Texto color={props.corTexto}>{props.texto}</Texto>
        </Cartao>
    </>
}

const Quantidade=styled.h1`
    font-size: 5rem;
    color: ${props=>props.color||'#3f3f3f'};
    font-weight: 300;
`
const Texto=styled.p`
    color:${props=>props.color||'#3f3f3f'};
`
const Cartao=styled.div`
    min-width: 6rem;
    padding: 0 2rem;
    min-height: 8rem;
    background-color: ${props=>props.color||'whitesmoke'};
    border-radius: .2rem;
    box-shadow: 0px 0px 1.5 #3f3f3f;
    border-bottom: 1px solid lightgray;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
