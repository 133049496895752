import DataProvider from 'contexts/DataContext';
import { Rotas } from './Rotas';
import { EstilosGlobais } from './ui/EstilosGlobais';
function App() {
  return <>
    <EstilosGlobais />
    <DataProvider>
      <Rotas/>
    </DataProvider>
  </>
}

export default App;
