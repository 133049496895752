const CryptoJS = require("crypto-js");
const secretKey = "your-secret-key";

export const criptografar = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
}

export const decriptografar = (data) => {
    if (data === null || data === undefined) {
        //console.error('Erro: valor de entrada é nulo ou indefinido');
        return null;
    }
    try {
        const bytes = CryptoJS.AES.decrypt(data, secretKey);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    } catch (error) {
        console.error('Err ao decriptografar:', error);
        return null;
    }
}
