import styled from "styled-components"
export const RodaPe = () => {
    return<>
        <Recipiente>
            <div className="rodape__esquerda">
                <p>© 2023 - daLvz | A era da inligência - Todos os direitos reservados</p>
            </div>
            <div className="rodape__direita">
                <p>CNPJ: 49.069.430/0001-5 </p>
            </div>
        </Recipiente>

    </>
}
const Recipiente = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    color: whitesmoke;
    padding: 1rem;
    font-size: 0.8rem;
    .rodape__esquerda{
        width: 50%;
        text-align: left;
    }
    .rodape__direita{
        width: 50%;
        text-align: right;
    }
    a{
        color: whitesmoke;
        text-decoration: none;
    }
    @media (max-width: 768px){
        flex-direction: column;
        .rodape__esquerda{
            width: 100%;
            text-align: center;
        }
        .rodape__direita{
            width: 100%;
            text-align: center;
        }
    }
`

