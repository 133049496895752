import React from 'react';
import { FlexColuna } from 'ui/ComponentesDeEstilo';
import styled from 'styled-components';
import { LogoCereja } from '../componentes/LogoCereja';

export const ELTermosECondicoes = () => {
  return <>
        <LogoCereja tamanho="5rem" position="fixed" top="1rem" left="1rem" url="/"/>
        <FlexConlunaEstilizado>
        <Titulo>Termos e Condições de Uso</Titulo>
        
        <Subtitulo>1. Introdução</Subtitulo>
        <p>Ao utilizar nosso site, você concorda com estes termos e condições na íntegra; caso discorde de qualquer parte destes termos e condições, não deve usar nosso site.</p>

        <Subtitulo>2. Direitos de propriedade intelectual</Subtitulo>
        <p>Salvo indicação em contrário, nós somos proprietários ou detentores de licença de todos os direitos de propriedade intelectual do nosso site, e dos materiais publicados nele. Todos estes direitos estão reservados.</p>
        
        <Subtitulo>3. Uso aceitável</Subtitulo>
        <p>Você não deve usar nosso site de qualquer maneira que cause, ou possa causar, danos ao site ou prejudicar a disponibilidade ou acessibilidade do site, ou de qualquer forma que seja ilegal, fraudulenta ou prejudicial, ou em conexão com qualquer finalidade ou atividade ilegal, fraudulenta ou prejudicial.</p>

        <Subtitulo>4. Conteúdo do usuário</Subtitulo>
        <p>Em relação ao conteúdo do usuário, com a sua permissão, você nos concede uma licença mundial, irrevogável, não exclusiva e royalty-free para usar, reproduzir, adaptar, publicar, traduzir e distribuir em qualquer meio existente ou futuro.</p>

        <Subtitulo>5. Política de privacidade</Subtitulo>
        <p>Utilizamos a sua informação pessoal de acordo com a nossa Política de Privacidade. Por favor, dedique um tempo para ler a nossa Política de Privacidade, já que inclui termos importantes que se aplicam a você.</p>

        <Subtitulo>6. Limitações de responsabilidade</Subtitulo>
        <p>Nada nestes termos e condições irá: (a) limitar ou excluir a nossa responsabilidade por morte ou danos pessoais resultantes de negligência; (b) limitar ou excluir a nossa responsabilidade por fraude ou deturpação fraudulenta; (c) limitar qualquer uma das nossas responsabilidades de qualquer maneira que não seja permitida pela lei aplicável; ou (d) excluir qualquer uma das nossas responsabilidades que não possam ser excluídas sob a lei aplicável.</p>

        <Subtitulo>7. Indenização</Subtitulo>
        <p>Você por este meio indeniza-nos e compromete-se a nos manter indenizados contra quaisquer perdas, danos, custos, responsabilidades e despesas (incluindo, sem limitação, despesas legais e quaisquer quantias pagas por nós a terceiros em acordos de qualquer reivindicação ou disputa a nosso critério) incorridas ou sofridas por nós e decorrentes direta ou indiretamente de qualquer violação por você de qualquer disposição destes termos e condições.</p>

        <Subtitulo>8. Violação destes termos e condições</Subtitulo>
        <p>Se você violar estes termos e condições de qualquer maneira, podemos tomar as ações que julgarmos apropriadas para lidar com a violação, incluindo a suspensão do seu acesso ao site, a proibição de acesso ao site, o bloqueio de computadores usando o seu endereço IP de acessar o site, o contato com o seu provedor de serviços de internet para solicitar que eles bloqueiem o seu acesso ao site e/ou iniciar procedimentos judiciais contra você.</p>
        
        <Subtitulo>9. Variação</Subtitulo>
        <p>Podemos revisar estes termos e condições de tempos em tempos. Os termos e condições revisados serão aplicáveis ao uso deste site a partir da data de publicação dos termos e condições revisados neste site. Por favor, verifique esta página regularmente para garantir que está familiarizado com a versão atual.</p>
        </FlexConlunaEstilizado>
    </>
}
const Subtitulo = styled.h2`
    margin-top: 2rem;
    margin-bottom: 1rem;
`
const Titulo = styled.h1`
    margin-bottom: 2rem;
`
const FlexConlunaEstilizado = styled(FlexColuna)`
    line-height: 1.5rem;
    width: 50%;
    margin: 0 auto;
    padding-top: 7rem;
    padding-bottom: 7rem;
`
