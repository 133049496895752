import { Restful } from "servidor/restful"

export const formularFrase = async (dados) => {
    try {
        const res = await Restful.post('/gpt', { "question": dados });
        console.log(res.data.answer);
        return res.data.answer;
    } catch (err) {
        console.log(err);
        return null;  // ou talvez throw err, dependendo de como você quer lidar com erros
    }
};
