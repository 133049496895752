export const pegarParametrosDaURL=(url)=> {
    var params = {};
    var parser = new URL(url);
    var query = parser.search.slice(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
}
export const dadoLocal=(nome,valor)=>{
    localStorage.setItem(nome,valor)
}
export const buscarDadoLocal=(nome)=>{
    const dado=localStorage.getItem(nome)
    return dado
}
export const dataDeHojeFormatada=()=>{
    let data=new Date()
    let dia=data.getDate()
    let mes=data.getMonth()+1
    let ano=data.getFullYear()
    let dataFormatada=`${ano}-${mes}-${dia}`
    return dataFormatada
}
export const InverterData=(data)=>{
    if (!data) {
        throw new Error('A data fornecida é nula ou indefinida');
    }
    let dataSeparada=data.split('-')
    let dataInvertida=`${dataSeparada[2]}/${dataSeparada[1]}/${dataSeparada[0]}`
    return dataInvertida
}
export const formatarValor = (valor) => {
    if (valor !== null && valor !== undefined) {
        valor = parseFloat(valor).toFixed(2);  // valor agora é uma string
        const valorFormatado = valor.replace('.', ',');  // substitui ponto por vírgula
        return valorFormatado;
    }
    return null;
}
let gerarNumeros=new Set()
export const gerarCodigo=()=>{
    let number;
    do{
        number=Math.floor(Math.random()*1000000)
        number=String(number).padStart(6,'0')
        number=`${number.slice(0,3)}-${number.slice(3)}`
    }while(gerarNumeros.has(number))
    gerarNumeros.add(number)
    return number
}
export const gerarIdCustomizado=(tamanho)=>{
    const caracteresPermitidos = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let chave = '';

    for (let i = 0; i < tamanho; i++) {
        const indiceAleatorio = Math.floor(Math.random() * caracteresPermitidos.length);
        chave += caracteresPermitidos.charAt(indiceAleatorio);
    }

    return chave;
}
