import styled from "styled-components"
import { useState, useEffect } from "react"
import { BotaoPrincipal, ElementoCentralizado, Input, Label } from "../../ui/ComponentesDeEstilo"
import { pegarParametrosDaURL } from '../../uteis/funcoes';
import { alterarSenhaDoUsuario, buscarUsuarioPorTokenESistema, excluirToken } from "../../paginas/Genesi/Controllers";
import { Logo } from "componentes/global/Logo";
import { Alerta } from "componentes/global/Alertas";

export const AlterarSenha = () => {
    const [mostrarAlertaSucesso, setMostrarAlertaSucesso] = useState(false);
    const [erroSenhas, setErroSenhas] = useState('');
    const [mostraErro, setMostraErro] = useState(false);
    const [status, setStatus] = useState('');

    useEffect(() => {
        if (status === 200) {
            setMostrarAlertaSucesso(true);
            setTimeout(() => {
                setMostrarAlertaSucesso(false);
                window.location.href = '/login';
            }, 3000);
        }
    }, [status]);

    const verificarIgualdadeDasSenhas = (novaSenha, confirmarNovaSenha) => {
    
        // Verificar se as senhas são iguais
        if (novaSenha !== confirmarNovaSenha) {
            setErroSenhas("As senhas não são iguais.");
            return false; 
        }
        
        // Se as senhas são iguais, verificar se têm pelo menos 8 caracteres
        if (novaSenha.length < 8) {
            setErroSenhas("A senha deve ter pelo menos 8 caracteres.");
            return false; 
        }
    
        setErroSenhas(""); // Se passar por ambas as verificações, limpe qualquer erro.
        return true; 
    };
    
    

    return (
        <>
            <ElementoCentralizado>
                <Logo width="5rem" paddingBottom="1rem" />
                <p>Agora você pode alterar sua senha</p>
                <Formulario
                        onSubmit={async (event) => {
                            event.preventDefault();
                            
                            const novaSenha = document.getElementById('nova_senha').value;
                            const confirmarNovaSenha = document.getElementById('confirmar_nova_senha').value;

                            // Verifica se as senhas são válidas
                            if(!verificarIgualdadeDasSenhas(novaSenha, confirmarNovaSenha)) {
                                return; // Se a senha não for válida, pare aqui e não prossiga
                            }

                            const url = window.location.href;
                            const token = pegarParametrosDaURL(url);
                            const oToken = token.token;
                            const resposta = await buscarUsuarioPorTokenESistema(oToken);

                            if (resposta.status === 401) {
                                setMostraErro(true)
                                return;
                            }

                            if (resposta && resposta.tokenValido && resposta.tokenValido.usuario_id) {
                                const usuario_id = resposta.tokenValido.usuario_id;
                                const dados = {
                                    "usuario_id": usuario_id,
                                    "senha": novaSenha
                                };
                                alterarSenhaDoUsuario(dados, setStatus);
                                excluirToken(oToken)
                                setMostrarAlertaSucesso(true);
                            } else {
                                console.error("Resposta inesperada", resposta);
                            }
                        }}
                    >


                        <div>
                            <Label htmlFor="nova_senha">Nova senha</Label>
                            <Input 
                                type="password" 
                                id="nova_senha" 
                                name="nova_senha" 
                                placeholder="********"
                            />
                        </div>
                        <div>
                            <Label htmlFor="confirmar_nova_senha">Confirmar nova senha</Label>
                            <Input 
                                type="password" 
                                id="confirmar_nova_senha" 
                                name="confirmar_nova_senha" 
                                placeholder="********"
                            />
                            {erroSenhas && <p style={{color: 'red'}}>{erroSenhas}</p>}
                        </div>
                        <BtnAlterar>Redefinir senha</BtnAlterar>
                    </Formulario>

            </ElementoCentralizado>
            {mostraErro&&<Alerta tipo="erro" mensagem="Token expirado" />}
            {mostrarAlertaSucesso && <Alerta tipo="sucesso" mensagem="Senha redefinida com sucesso!" />}
        </>
    );
};

const BtnAlterar = styled(BotaoPrincipal)`
    display: flex;
    width: 10rem;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    border-radius: 10rem;
    background-color: whitesmoke;
    color: black;
    &:hover{
        background-color: #d6d6d6;
    }
`;

const Formulario = styled.form`
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
`;
