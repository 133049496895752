import { limparCEP, limparCPF, limparMascaraTelefone } from "../../uteis/validacoes"
import { Sistema } from "./../../config/sistema"
import { Restful } from "./../../servidor/restful"
import {dadosDoUsuario} from "../../uteis/gerenciarSessao";
export const criarUsuario=async(dados,setErroCadastro)=>{
    const cpf=limparCPF(dados.cpf)
    const cep=limparCEP(dados.cep)
    const telefone=limparMascaraTelefone(dados.telefone)
    return await Restful.post('/criar-usuario',{
        "nome_completo": dados.nome_completo,
        "nascimento": dados.nascimento,
        "sexo": dados.sexo,
        "cpf": cpf,
        "telefone": telefone,
        "cep": cep,
        "rua": dados.rua,
        "numero": dados.numero,
        "complemento": dados.complemento,
        "bairro": dados.bairro,
        "cidade": dados.cidade,
        "estado": dados.estado,
        "email": dados.email,
        "senha": dados.senha,
        "permissao":'usuario',
        "sistema": Sistema
    }).then(resposta => {
        return { sucesso: true, mensagem: "Usuário criado com sucesso!" };
    })
    .catch(erro => {
        console.log("erro", erro.response.data.message);
        const resposta = erro.response.data.message;
        if (resposta === "Email já cadastrado") {
            console.log("Este email já está cadastrado no sistema!");
            setErroCadastro("Este email já está cadastrado no sistema!");
            return { sucesso: false, mensagem: "Este email já está cadastrado no sistema!" };
        }
        return { sucesso: false, mensagem: erro };
    })
}
export const logarUsuario=async(dados,setErro)=>{
    return await Restful.post('/logar-usuario',{
        "email": dados.email,
        "senha": dados.senha,
        "sistema": Sistema
    }).then(resposta=>{
        return resposta
    }).catch(erro=>{
        console.log(erro.response.data.Erro.status)
        setErro(erro.response.data.Erro.status)
        return erro
    })
}
export const buscarUsuarioPorTokenESistema=async(token)=>{
    try {
        const resposta = await Restful.get(`/buscar-usuario-por/${token}/${Sistema}`)
        return resposta.data
    } catch (erro) {
        if(erro.response.data.status===401){
            const status=erro.response.data.status
            const mensagem=erro.response.data.message
            console.log({status,mensagem})
            return {status,mensagem}
        }
    }
}
export const excluirToken=async(token)=>{
    return await Restful.delete(`/excluir-token/${token}/${Sistema}`).then(resposta=>{
        console.log(resposta)
        return resposta
    }).catch(erro=>{
        return erro
    })
}
export const validarEmailDoUsuario=async(usuario_id)=>{
    return await Restful.patch(`/alterar-dados-do-usuario/${usuario_id}`,{
        "email_verificado": true
    }).then(resposta=>{
        return resposta
    }).catch(erro=>{
        return erro
    })
}
export const enviarEmailDeRecuperacaoDeSenha=async(email)=>{
    return await Restful.post('/enviar-email-recuperacao-de-senha',{
        "email": email,
        "sistema": Sistema
    }).then(resposta=>{
        return resposta
    }).catch(erro=>{
        return erro
    })
}
export const enviarEmailDeConvite=async(id_customizado,email,nome,subcategoria,token)=>{
    const id=dadosDoUsuario().id
    return await Restful.post('/enviar-convite',{
        "id_customizado":id_customizado,
        "email": email,
        "nome":nome,
        "subcategoria":subcategoria,
        "convidado_por":id,
        "status":1,
        "token":token,
        "endereco":`http://localhost:3000/landing-page?token=${token}`
    }).then(resposta=>{
        return resposta
    }).catch(erro=>{
        return erro
    })
}
export const alterarSenhaDoUsuario=async(dados,setStatus)=>{
    return await Restful.patch(`/alterar-senha-do-usuario/${dados.usuario_id}`,{
        "senha": dados.senha,
    }).then(resposta=>{
        setStatus(resposta.status)
        return resposta.status
    }).catch(erro=>{
        return erro
    })
}
