import styled from "styled-components"
import { FaleConosco } from "paginas/Genesi/FaleConosco"
import fundo from 'assets/img/fundo-contato.jpg'
export const DobraQuatro = () => {
    return<>
        <Dobra>
            <FaleConosco/>
        </Dobra>
    </>
}
const Dobra = styled.div`
    background-color: black;
    background-image: url(${fundo});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
`