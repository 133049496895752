import { useState } from 'react';
import { validaBairro, validaCEP, validaCNPJ, validaCPF, validaCidade, validaDataNascimento, validaEmail, validaEstado, validaNome, validaNomeCompleto, validaNumero, validaPais, validaRua, validaSenha, validaSexo, validaTelefone, validaTermos } from '../../uteis/validacoes';

export const useValidacaoFormulario = () => {
    const [erros, setErros] = useState({})

    const validaCampos = (campo, valor) => {
        let erro = '';
        switch (campo) {
            case 'nome_completo':
                erro = validaNomeCompleto(valor)
                break
            case 'dataNascimento':
                erro = validaDataNascimento(valor)
                break
            case 'sexo':
                erro = validaSexo(valor)
                break
            case 'cep':
                erro = validaCEP(valor)
                break
            case 'rua':
                erro = validaRua(valor)
                break
            case 'numero':
                erro = validaNumero(valor)
                break
            case 'bairro':
                erro = validaBairro(valor)
                break
            case 'cidade':
                erro = validaCidade(valor)
                break
            case 'estado':
                erro = validaEstado(valor)
                break
            case 'pais':
                erro = validaPais(valor)
                break
            case 'telefone':
                erro = validaTelefone(valor)
                break
            case 'email':
                erro = validaEmail(valor)
                break
            case 'senha':
                erro = validaSenha(valor)
                break
            case 'cpf':
                erro = validaCPF(valor)
                break
            case 'cnpj':
                erro = validaCNPJ(valor)
                break;
            case 'termos':
                erro = validaTermos(valor)
                break;
            default:
                break
        }
        setErros(prevState => ({...prevState, [campo]: erro}));
        return !erro; // Retorna verdadeiro se não houver erro
    };

    const formularioEhValido = (campos) => {
        let novosErros = {};
        for (let campo in campos) {
            let erroCampo = validaCampos(campo, campos[campo]);
            if (erroCampo) {
                novosErros[campo] = erroCampo;
            }
        }
        setErros(novosErros);
        return !Object.values(novosErros).some(erro => erro !== '');
    }

    return { erros, validaCampos, formularioEhValido }
};
