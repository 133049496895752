import { useEffect } from "react"
import { listarSubcategorias, listarUmaCategoriaPorID } from "../Controller"
import styled from "styled-components"
import { useState } from "react"
import { IconeEditar, IconeFechar } from "ui/Icones"
import { CriarUmaSubcategoria } from "../subcategorias/CriarUmaSubcategoria"
import { ExibirTexto } from "componentes/global/ExibirTexto"
import { Linque } from "ui/ComponentesDeEstilo"
import { decriptografar } from "uteis/crypto"
export const ListarUmaCategoria = (props) => {
    const [umaCategoria, setUmaCategoria] = useState('')
    const [subcategorias, setSubcategorias] = useState([])
    const [mostraCadastrarSubcategoria, setMostraCadastrarSubcategoria] = useState(false)
    const [mostraCriarSubcategoria, setMostraCriarSubcategoria] = useState(false)
    const [mostraListarSubcategoria, setMostraListarSubcategoria] = useState(false)
    const [mostrarBtnNovaSub, setMostrarBtnNovaSub] = useState(true)
    console.log(subcategorias)
    useEffect(() => {
    if(subcategorias.length===0){
        setMostraCadastrarSubcategoria(true)
        setMostrarBtnNovaSub(true)
    }else{
        setMostraCadastrarSubcategoria(false)
        setMostraListarSubcategoria(true)
    }
    }, [subcategorias])
    useEffect(() => {
        
        listarUmaCategoriaPorID(props.id, setUmaCategoria)
    }, [])
    useEffect(() => {
        const oi=listarSubcategorias(props.id, setSubcategorias)
    }, [])
    const handleMostrarCadastroSubcategoria=()=>{
        setMostraCriarSubcategoria(true)
        setMostraCadastrarSubcategoria(false)
    }
    const handleNovaSub=()=>{
        setMostraCriarSubcategoria(true)
        setMostrarBtnNovaSub(false)
    }
    const handleEditar=()=>{
        window.location.href=`/categoria-editar?categoria=${umaCategoria.id}`
    }
    const handleFechar=()=>{
        window.location.href='/categoria'
    }
    return<>
        <Recipiente>
            <FlexRow>
                <Fechar>
                    <Linque to={`/categoria-editar?categoria=${umaCategoria.id}`}>
                        <IconeEditar/>
                    </Linque>
                    <IconeFechar onClick={handleFechar}/>
                </Fechar>
                <Imagem src={umaCategoria.url_arquivo} alt={umaCategoria.titulo} />
            </FlexRow>
                {mostraCriarSubcategoria&&<CriarUmaSubcategoria/>}
            <Div>
                {mostraListarSubcategoria&&
                    <FlexLista>
                        {subcategorias.map((subcategoria, index)=>{
                            return <>
                                <DivListaSubcategorias to={`/subcategoria?id=${subcategoria.id}`} key={index}>
                                    <FotoSubCategoria src={subcategoria.url_arquivo} alt="" />
                                    <p>{subcategoria.titulo}</p>
                                </DivListaSubcategorias>
                            </>
                        }
                        )}
                    </FlexLista>
                }
                {mostrarBtnNovaSub&&<BtnNovaSub onClick={handleNovaSub}>Criar nova subcategoria</BtnNovaSub>}
            </Div>
            <Div>
                <Titulo>{umaCategoria.titulo}</Titulo>
                <ExibirTexto texto={umaCategoria.descricao}/>
            </Div>
                {mostraCadastrarSubcategoria&&
                    <Div>
                        <P>Você ainda não cadastrou subcategorias para esta categoria.</P>
                        <CadastrarSubcategoria onClick={handleMostrarCadastroSubcategoria}>Cadastrar nova subcategoria</CadastrarSubcategoria>
                    </Div>
                }
        </Recipiente>
    </>
}
const BtnNovaSub= styled.button`
    background-color: whitesmoke;
    color: #3f3f3f;
    border: none;
    border-radius: .2rem;
    padding: .5rem;
    text-align: left;
    cursor: pointer;
    width: 15rem;
    margin-bottom: 1em;
    transition: .3s;
    &:hover{
        background-color: #ffffffb7;
    }
`
const FlexLista=styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 70rem;
`
const DivListaSubcategorias= styled(Linque)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;
    text-align: left;
    background-color: white;
    color: #3f3f3f;
    padding: .8em;
    border-radius: 3rem;
    margin-right: 1rem;
    cursor:pointer;
`
const FotoSubCategoria= styled.img`
    width: 2rem;
    height: 1.5rem;
    border-radius: 50%;
    margin-right: .5rem;
`
const CadastrarSubcategoria= styled.button`
    background-color: #3f3f3f;
    color: whitesmoke;
    border: none;
    border-radius: .2rem;
    padding: .5rem;
    cursor: pointer;
    margin-bottom: 1em;
    transition: .3s;
    &:hover{
        background-color: #2f2f2f;
    }
`
const P= styled.p`
    margin-bottom: 1em;
`
const Fechar= styled.div`
    position:relative;
    top: 3em;
    left: 54rem;
    cursor: pointer;
    background-color: lightgray;
    width: 4rem;
    border-radius: .2rem;
    display: flex;
    justify-content: space-around;
    padding:.5em;
    color: #3f3f3f;
`
const Div= styled.div`
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    padding: 2em;
    line-height: 1em;
    border-radius:.2em;
    margin-bottom:1em ;
    border-bottom: 1px solid lightgray;
`
const Recipiente = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 60rem;
`
const Titulo = styled.h1`
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    color: #3f3f3f;
`
const Imagem = styled.img`
    width: 100%;
    border-radius:.2em;
    margin-bottom: 1em;
    border-bottom: .3rem solid lightgray;
    
`
const FlexRow= styled.div`
    display: flex;
    flex-direction: column;
    width: 60rem;
`


