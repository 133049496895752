import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ca01 from "../assets/imagens/carousel/ca01.jpg";
import ca02 from "../assets/imagens/carousel/ca02.jpg";
export const Carrossel = () => {
    const carouselSettings = {
        showThumbs: false,
        autoPlay: true,
        infiniteLoop: true,
        showStatus: false,
        interval: 5000,
        // outras configurações
      };
    return<>
      <Carousel {...carouselSettings}>
        <div>
          <img src={ca01} alt="Image 1" />
        </div>
        <div>
          <img src={ca02} alt="Image 2" />
        </div>
      </Carousel>
    </>
  }  