import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { IconeBold, IconeItalico, IconeSublinhado } from 'ui/Icones';
import { OniChamar } from './oni-chat/OniChamar';
import { formularFrase } from './controller';
import { Linque } from 'ui/ComponentesDeEstilo';
import { useState } from 'react';
export const EditorDeTexto = ({ aoAlterarConteudo, initialValue }) => {
    const editorRef = useRef(null);
    const[formarFrase,setFormarFrase]=useState('')
    useEffect(() => {
        if (initialValue && editorRef.current) {
            editorRef.current.innerHTML = initialValue;
        }
    }, [initialValue]);

    const obterEstilosSelecao = () => {
        const selecao = window.getSelection();
        const elementoPai = selecao.anchorNode?.parentElement;
        if (!elementoPai) return {};
        const estilo = window.getComputedStyle(elementoPai);
        return {
            sublinhado: estilo.textDecorationLine.includes('underline'),
            negrito: estilo.fontWeight === 'bold' || estilo.fontWeight >= 700,
            italico: estilo.fontStyle === 'italic',
        };
    };

    const formatarTexto = (comando) => {
        const estilos = obterEstilosSelecao();
        if (comando === 'underline') {
            const selecao = window.getSelection();
            if (selecao.rangeCount) {
                const range = selecao.getRangeAt(0);
                const elementoPai = selecao.anchorNode.parentElement;
                if (elementoPai && elementoPai.style.textDecoration === 'underline') {
                    range.selectNode(elementoPai);
                    document.execCommand('removeFormat', false, null);
                    document.execCommand('underline', false, null);
                } else {
                    document.execCommand('underline', false, null);
                }
            }
        } else {
            document.execCommand(comando, false, null);
        }
    };

    const handleMudancaConteudo = () => {
        const conteudoAtualizado = editorRef.current.innerHTML;
        if (aoAlterarConteudo) {
            aoAlterarConteudo(conteudoAtualizado);
        }
    };

    const handleNegritoClick = (e) => {
        e.preventDefault();
        formatarTexto('bold');
    };

    const handleItalicoClick = (e) => {
        e.preventDefault();
        formatarTexto('italic');
    };

    const handleSublinhadoClick = (e) => {
        e.preventDefault();
        formatarTexto('underline');
    };
    const handleTextoAi = async () => {
        // Suponha que formularFrase seja uma função assíncrona que retorna uma Promise.

        const frase = await formularFrase(`formule a descrição do produto "${sessionStorage.getItem('AI')}" para o e-commerce, por favor.`);
        
        setFormarFrase(frase); // Atualiza o estado local
        
        // Atualiza o conteúdo do editor
        if (editorRef.current) {
            editorRef.current.innerHTML = frase;
        }
    
        // Chama a função para atualizar o conteúdo externamente (se necessário)
        if (aoAlterarConteudo) {
            aoAlterarConteudo(frase);
        }
    };
    
    return (
        <div>
            <BarraFerramentas>
                <Linque onClick={handleTextoAi} ><OniChamar width="1.3em"/></Linque>
                <button onClick={handleNegritoClick}><IconeBold/></button>
                <button onClick={handleItalicoClick}><IconeItalico/></button>
                <button onClick={handleSublinhadoClick}><IconeSublinhado/></button>
            </BarraFerramentas>
            <ConteudoEditor
                ref={editorRef}  
                contentEditable="true"
                onBlur={handleMudancaConteudo}
                onClick={() => editorRef.current.focus()}
                spellCheck="false"
                autoComplete="off"
            />
        </div>
    );
}
const BarraFerramentas = styled.div`
    margin-bottom: -2.5rem;
    margin-left:.5rem;
    position:relative;
    z-index:2;
    button {
        margin-right: 3px;
        border:none;
        padding: .5em;
        margin-bottom: -5rem;
        color: #3f3f3f;
        background-color: transparent;
    }
`
const ConteudoEditor = styled.div`
    min-height: 10rem;
    background-color: white;
    box-shadow: 0 .1em .2rem rgba(0,0,0,.1);
    border:none;
    border-radius: .5rem;
    padding: 3em 1em 3em 1em;
    margin-top: 12px;
    cursor: text;
    font-weight: 200;
    color:#6f6f6f;
    caret-color: #3f3f3f;
    position: relative;
    z-index:1;
    font-size:80%;
    line-height: 1em;
    b, strong {
        font-weight: 700;
    }
    &:focus {
        outline: none;
    }
`