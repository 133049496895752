import { Sistema } from "config/sistema"
import { Restful } from "servidor/restful"
export class UsuarioController{
    criarUsuario=async(dados)=>{
        await Restful.post('/criar/usuario',{
            "id_customizado": localStorage.getItem('id_customizado'),
            "nome": dados.nome,
            "sobrenome": dados.sobrenome,
            "cpf": dados.cpf,
            "nascimento": dados.nascimento,
            "telefone": dados.telefone,
            "sexo": dados.sexo,
            "cep": dados.cep,
            "rua": dados.rua,
            "numero": dados.numero,
            "complemento": dados.complemento,
            "bairro": dados.bairro,
            "cidade": dados.cidade,
            "estado": dados.estado,
            "email": dados.email,
            "senha": dados.senha,
            "permissao":'comprador',
            "sistema": Sistema
        }).then(resposta=>{
            console.log(resposta)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    criarComprador=async(dados)=>{
        await Restful.post('/criar/comprador',{
            "id_customizado": localStorage.getItem('id_customizado'),
            "nome": dados.nome,
            "sobrenome": dados.sobrenome,
            "cpf": dados.cpf,
            "nascimento": dados.dataNascimento,
            "telefone": dados.telefone,
            "email": dados.email,
            "sexo": dados.sexo,
            "cep": dados.cep,
            "rua": dados.rua,
            "numero": dados.numero,
            "complemento": dados.complemento,
            "bairro": dados.bairro,
            "cidade": dados.cidade,
            "estado": dados.estado,
            "permissao":'comprador',
            "sistema": Sistema
        }).then(resposta=>{
            console.log(resposta.data.id)
            localStorage.setItem('comprador',resposta.data.id)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    logarUsuario=async(dados)=>{
        await Restful.post('/logar/usuario',{
            "email":dados.email,
            "senha":dados.senha,
            "sistema":Sistema
        }).then(resposta=>{
            const id=resposta.data.usuario.id
            const token=resposta.data.valor
            const permissao=resposta.data.usuario.permissao
            const id_customizado=resposta.data.usuario.id_customizado
            if(permissao==='comprador'){
                localStorage.setItem('UsuarioID',id)
                localStorage.setItem('id_customizado',id_customizado)
                localStorage.setItem('token',token)
                localStorage.setItem('permissao',permissao)
                window.location.href='/'
            }else if(permissao==='administrador'){
                localStorage.setItem('UsuarioID',id)
                localStorage.setItem('id_customizado',id_customizado)
                localStorage.setItem('token',token)
                localStorage.setItem('permissao',permissao)
                window.location.href='/painel-de-controle'
            }else if(permissao==='vendedor'){
                localStorage.setItem('UsuarioID',id)
                localStorage.setItem('id_customizado',id_customizado)
                localStorage.setItem('token',token)
                localStorage.setItem('permissao',permissao)
                window.location.href='/painel-de-controle'
            }else{
                //window.location.href='/cadastro'
            }
            return resposta
        }).catch(erro=>{
            console.log(erro.response.status)
            window.location.href='/login?erro=404'
            return erro.response.status
        })
    }
    listarUsuarioPorId=async(setNome,setSobrenome,setPermissao)=>{
        const id=localStorage.getItem('UsuarioID')
        await Restful.get(`/listar/usuario/por/id/${id}`).then(resposta=>{
            setNome(resposta.data.nome)
            setSobrenome(resposta.data.sobrenome)
            setPermissao(resposta.data.permissao)
            return resposta
        }).catch(erro=>{
            console.log(erro)
            return erro
        })
    }
    listarUsuarioPorIdCustomizado=async(id,setUsuario)=>{
        await Restful.get(`/listar/usuario/id_customizado/${id}`).then(resposta=>{
            setUsuario(resposta.data)
            return resposta
        }).catch(erro=>{
            console.log(erro)
            return erro
        })
    }
    listarUsuariosPorSistema=async(setUsuarios)=>{
        await Restful.get(`/listar/usuarios/por/sistema/${Sistema}`).then(resposta=>{
            setUsuarios(resposta.data)
            return resposta
        }).catch(erro=>{
            console.log(erro)
            return erro
        })
    }
    listarUsuarioPorIdESistema=async(id,setNome,setSobrenome,setCEP,setPermissao)=>{
        await Restful.get(`/listar/usuario/por/id/sistema/${id}/${Sistema}`).then(resposta=>{
            setNome(resposta.data.nome)
            setSobrenome(resposta.data.sobrenome)
            setCEP(resposta.data.cep)
            setPermissao(resposta.data.permissao)
            return resposta
        }).catch(erro=>{
            console.log(erro)
            return erro
        })
    }
    alterarPermissaoDoUsuario=async(id,permissao)=>{
        await Restful.patch(`/altarar/usuario/id/sistema/${id}/${Sistema}`,{
            "permissao":permissao
        }).then(resposta=>{
            console.log(resposta)
            return resposta
        }).catch(erro=>{
            console.log(erro)
            return erro
        })
    }
}