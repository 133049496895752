import img from '../../assets/img/video-exemplo-tarot.png'
import styled from "styled-components";
import {BotaoDestaque} from "../../ui/ComponentesDeEstilo";
import fundo from '../../assets/img/azul-veludo.jpg'
export const CompartilhamentoDeVideos=()=>{
    const handleProximo=()=>{
        window.location.href='/landing-page#oni'
    }
    return<>
        <div id={'agendamento'}></div>
        <Recipiente>
            <Img src={img} alt=""/>
            <Div>
                <H1>Agendamentos e consultas <br/> por vídeo chamada em seu site!</H1>
                <P>Agendamentos e consultas por vídeo chamada em seu site proporcionam uma maneira conveniente e acessível para conectar-se com seus clientes. Oferecemos uma solução integrada que permite aos usuários agendar facilmente suas consultas online, proporcionando uma experiência personalizada e eficiente. A plataforma de vídeo chamada garante interações em tempo real, proporcionando a flexibilidade de receber consultas e realizar reuniões virtuais, promovendo um atendimento de qualidade sem a necessidade de deslocamento. Simplifique o processo de agendamento e proporcione um serviço excepcional aos seus clientes, tudo isso diretamente através do seu site.</P>
                <Botao onClick={handleProximo}>Continuar...</Botao>
            </Div>
        </Recipiente>
    </>
}
const Botao = styled(BotaoDestaque)`
    color: black;
    padding: 1em 3em;
    font-weight: 500;
    background-color: #eca342;
`;
const Div=styled.div`
    padding-top:8em;
`
const P=styled.p`
    width: 650px;
    margin-top: 2em;
    line-height: 1.5em;
    color: white;
    font-size: 120%;
    margin-bottom: 2em;
`
const Recipiente=styled.div`
    padding: 10em;
    display: flex;
    justify-content: space-around;
    background-color: rgb(15, 34, 73);
    margin-top: -1em;
    background-image: url(${fundo});
`
const Img=styled.img`
    width: 700px;
    max-height: 27rem;
`
const H1=styled.h1`
    text-align: left;
    font-weight: 300;
    font-size: 300%;
    color: white;
`