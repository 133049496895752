import { MenuLateralSistema } from "componentes/painel-de-controle/sistema/MenuLateralSistema"
import { TopoSistema } from "componentes/painel-de-controle/sistema/TopoSistema"
import { useState } from "react"
import { useEffect } from "react"
import { pegarParametrosDaURL } from "uteis/funcoes"
import styled from "styled-components"
import { listarSubcategoria } from "componentes/painel-de-controle/sistema/Controller"
export const UmaSubcategoria = () => {
    const [idUrl, setIdUrl] = useState('');
    const [subcategoria, setSubCategoria] = useState('');
    useEffect(() => {
        const url = window.location.href;
        const extractedId = pegarParametrosDaURL(url);
        setIdUrl(extractedId);
    }, []); // Apenas executar uma vez, após a montagem inicial

    useEffect(() => {
        if (idUrl) { // Verificar se idUrl é válido antes de buscar as subcategorias
            listarSubcategoria(idUrl.id, setSubCategoria)
        }
    }, [idUrl]); // Executar quando idUrl for atualizado

    return (
        <>
            <TopoSistema />
            <MenuLateralSistema />
            <Recipiente>
                <img  alt={''} src={subcategoria&&subcategoria.url_arquivo}/>
                <Paragrafo>
                    {subcategoria&&subcategoria.titulo}
                </Paragrafo>
                <Paragrafo>
                    {subcategoria&&subcategoria.descricao}
                </Paragrafo>
            </Recipiente>
        </>
    );
};

const Recipiente=styled.div`
    top:10rem;
    position: absolute;
    left:10rem;
    z-index:5;
`
const Paragrafo=styled.p`
    color:#3f3f3f;
`