import { MenuLateralSistema } from "componentes/painel-de-controle/sistema/MenuLateralSistema"
import { TopoSistema } from "componentes/painel-de-controle/sistema/TopoSistema"
import { ListarAppsPorUsuario } from "componentes/painel-de-controle/sistema/usuarios/ListarAppsPorUsuario"
import { VincularUsuarioApp } from "componentes/painel-de-controle/sistema/usuarios/VincularUsuarioApp"

export const Usuario=()=>{
    return<>
        <TopoSistema/>
        <MenuLateralSistema/>
        <ListarAppsPorUsuario/>
        <VincularUsuarioApp/>
    </>
}