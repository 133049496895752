import { BoletoBancario } from "../pagamentos/BoletoBancario"
import { CartaoDeCredito } from "../pagamentos/CartaoDeCredito"
import { Pix } from "../pagamentos/Pix"
import { Resumo } from "../pagamentos/Resumo"
import { Topo } from "../pagamentos/Topo"
export const ELPagamentos=()=>{
    return<>
        <Topo/>
        <Pix/>
        <BoletoBancario/>
        <CartaoDeCredito/>
        <Resumo/>
    </>
}