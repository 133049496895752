import { useState,useEffect } from "react";
import styled from "styled-components";
import { ElementoCentralizado, ErroInput, Input, Label } from "ui/ComponentesDeEstilo"
import { Storage } from "../Storage"
import { dadosDoUsuario } from "uteis/gerenciarSessao"
import { decriptografar } from "uteis/crypto";
import { Carregando } from "componentes/global/Carregando";
import { criarCategoria, editarStorageEmUso } from "../Controller";
import { EditorDeTexto } from "componentes/global/EditorDeTexto";

export const CriarUmaCategoriaProdutos = ({ usuario_id, onFileUpload }) => {
    const [mostraEnviar, setMostraEnviar] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [urlArquivo, setUrlArquivo] = useState(localStorage.getItem('storage'));
    const [erroUrlArquivo, setErroUrlArquivo] = useState('');
    const [titulo, setTitulo] = useState('');
    const [erroTitulo, setErroTitulo] = useState('');
    const [descricao, setDescricao] = useState('');
    const [erroDescricao, setErroDescricao] = useState('');
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [tokenSessao, setTokenSessao] = useState(null);
    const decriptografarStorage=decriptografar(localStorage.getItem("storage"))
    const storage_id=decriptografarStorage?.id
    const storage_url=decriptografarStorage?.url

    useEffect(() => {
        // Descriptografa o token apenas se estiver disponível no localStorage
        if (token) {
            const decryptedToken = decriptografar(token);
            setTokenSessao(decryptedToken);
        }
    }, [token]);
    const handleEnviarArquivo = async (event) => {
        event.preventDefault()
        try {
          if(!storage_url){
            setErroUrlArquivo("Selecione uma imagem")
          }else{
            setErroUrlArquivo("")
          }
          if(!titulo){
            setErroTitulo("Digite um titulo")
          }else{
            setErroTitulo("")
          }
          if(!descricao){
            setErroDescricao("Digite uma descrição")
          }else{
            setErroDescricao("")
          }
            if (storage_url && titulo && descricao) {
                await criarCategoria({
                    url_arquivo: storage_url,
                    titulo: titulo,
                    descricao: descricao,
                    sistema: tokenSessao?.token // Use tokenSessao apenas se estiver disponível
                });
            editarStorageEmUso(storage_id)
            setMostraEnviar(true)
            
              setTimeout(()=>{
                localStorage.removeItem("storage")
                setMostraEnviar(false)
                window.location.reload()
              },3000)
          }
      
        } catch (error) {
          console.error(error);
        }
      }
      const usuarioId=dadosDoUsuario().id
    return <>
        <Recipiente>
        <Storage usuario_id={usuarioId} onFileUpload={setPreviewImage} foto_de_perfil={0}  />
        <ErroInput>{erroUrlArquivo}</ErroInput>
            <Formulario  onSubmit={handleEnviarArquivo}>
                <div>
                    <InputCustomizada
                        onChange={(e) => {
                            setTitulo(e.target.value);
                            sessionStorage.setItem('AI',e.target.value)
                        }}
                        type="text"
                        value={titulo}
                        autoFocus
                    />
                    <ErroInput>{erroTitulo}</ErroInput>
                </div>
                <div>
                    <EditorDeTexto
                      aoAlterarConteudo={(novoConteudo) => {
                          setDescricao(novoConteudo);
                      }}
                      initialValue={descricao} 
                      id="descricao"
                    />
                </div>
                <ErroInput>{erroDescricao}</ErroInput>
                <BtnEnviar>Cadastrar nova categoria {mostraEnviar&&<Carregando tamanho="p"/>}</BtnEnviar>
            </Formulario>
        </Recipiente>
        </>
  }

  const InputCustomizada=styled(Input)`
    border:none;
    margin-bottom: -.1em;
    margin-top: 2em;
    background-color: #f1f1f1;
    padding:0;
    padding: 1em;
 `
const BtnEnviar = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3f3f3f;
    border: 1px solid #3f3f3f;
    border-radius: .2rem;
    color: whitesmoke;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    padding: .9rem 1rem;
    transition: all .5s ease-in-out;
    width: 100%;
    margin-top: 1rem;
    &:hover{
        background-color: #4f4f4f;
    }
`
const Recipiente=styled(ElementoCentralizado)`
    flex-direction: column; 
    padding: 3rem;
    z-index: 4;
    width: 50%;
    border-bottom: 1px solid #ccc;
    border-radius: .5rem;
    background-color: white;
    box-shadow: 0 0 .1rem rgba(0,0,0,.2);
`
const Formulario = styled.form`
`