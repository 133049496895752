import styled from "styled-components";
export const Card = ({ children, titulo, texto }) => {
    return (
        <>
            <Recipiente>
                <Icone>
                    {children}
                </Icone>
                <div>
                    <Titulo>{titulo}</Titulo>
                    <Texto>{texto}</Texto>
                </div>
            </Recipiente>
        </>
    );
}
const Icone = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3f3f3f;
    font-size: 3em;
    transition: .3s;
`
const Titulo = styled.h1`
    font-size: 1.2rem;
    color: #3f3f3f;
    font-weight: 300;
`
const Texto = styled.p`
    font-size: 1.2em;
    color: #3f3f3f;
    font-weight: 200;
`
const Recipiente = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 10em;
    height: 10em;
    border-radius: .2em;
    padding: 2.5em;
    transition: .7s;
    background-color: #d4d4d4;
    &:hover{
        background-color: #c2c2c2;
    }
`

