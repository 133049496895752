import { Restful } from "servidor/restful"
import{Sistema} from '../config'
export class CompraController{
    cadastrarCompra=async(id)=>{
        await Restful.post('/cadastrar/compra',{
            "cobranca_id":id,
            "estado":'pendente',
            "arrayJSON":localStorage.getItem('carrinho'),
            "usuario_id":localStorage.getItem('UsuarioID'),
            "sistema":Sistema
        }).then(resposta=>{
            console.log(resposta)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarTodasAsComprasPorSistema=async(setCompras)=>{
        await Restful.get(`/listar/compras/sistema/${Sistema}`).then(resposta=>{
            setCompras(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarComprasPorUsuario=async(setCompras)=>{
        await Restful.get(`/listar/compras/${localStorage.getItem('UsuarioID')}`).then(resposta=>{
            setCompras(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarComprasPorUsuarioESistema=async(setCompras)=>{
        await Restful.get(`/listar/compras/${localStorage.getItem('UsuarioID')}/${Sistema}`).then(resposta=>{
            setCompras(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarCompraPorCobrancaID=async(id,setCompras)=>{
        await Restful.get(`/listar/compra/cobranca/${id}`).then(resposta=>{
            setCompras(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarComprasPorUsuarioESistemaEQuantidade=async(quantidade,setCompras)=>{
        await Restful.get(`/listar/compras/${localStorage.getItem('UsuarioID')}/${Sistema}/${quantidade}`).then(resposta=>{
            setCompras(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarUmaCompraPorId=async(id,setCompra)=>{
        await Restful.get(`/listar/uma/compra/${id}`).then(resposta=>{
            setCompra(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    editarCompra=async(dados)=>{
        await Restful.patch(`/editar/compra/${dados.id}`,{
            "estado":dados.estado
        }).then(resposta=>{
            console.log(resposta)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    deletarCompra=async(id)=>{
        await Restful.delete(`/deletar/compra/${id}`).then(resposta=>{
        }).catch(erro=>{
            console.log(erro)
        })
    }
}