import styled from 'styled-components';
import logo from '../assets/imagens/logoCereja.png';
export const LogoCereja = (props) => {
    const handleClick = () => {
        window.location.href = props.url || "#"
    }
    return <>
        <Img onClick={handleClick} src={logo} tamanho={props.width} paddingTop={props.paddingTop} paddingBottom={props.paddingBottom} position={props.position} top={props.top} left={props.left} right={props.right} bottom={props.bottom} alt={props.alt} />
    </>
}
const Img = styled.img`
    width:${props => props.tamanho || "2rem"};
    padding:${props=>props.padding || "0"};
    padding-top:${props => props.paddingTop || "0"};
    padding-bottom:${props => props.paddingBottom || "0"};
    position:${props => props.position || "relative"};
    top:${props => props.top || "0"};
    left:${props => props.left || "0"};
    right:${props => props.right || "0"};
    bottom:${props => props.bottom || "0"};
    cursor: pointer;
`