import Axios from 'axios'
export const RotaPagamentos = Axios.create({
    //baseURL: 'http://localhost:1212',
    baseURL: 'https://www.onisciente.com:2222',
    timeout: 10000,
    headers:{
        'Content-Type':'application/json',
        'Accept':'application/json',
    },
})

