import { useData } from "contexts/DataContext"
import styled from "styled-components"
import oniIco from "ui/oni-ico2.png"
export const OniChamar=(props)=>{
    const {data,setData}=useData()
    const handleData=()=>{
        setData(true)
    }
    return<>
        <Img onClick={handleData} width={props.width} src={oniIco}/>
    </>
}
const Img=styled.img`
    cursor: pointer;
    width: ${props=>props.width};
    margin:0;
    padding:0;
`