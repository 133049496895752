import { MenuLateral } from "componentes/global/MenuLateral"
import { OniChatGenesi } from "componentes/global/oni-chat/OniChatBase"
import { BtnAdicionar } from "componentes/painel-de-controle/sistema/BtnAdicionar"
import { MenuLateralSistema } from "componentes/painel-de-controle/sistema/MenuLateralSistema"
import { TopoSistema } from "componentes/painel-de-controle/sistema/TopoSistema"
import { CriarUmaCategoriaProdutos } from "componentes/painel-de-controle/sistema/categorias/CriarUmaCategoriaProdutos"
import { ListarCategorias } from "componentes/painel-de-controle/sistema/categorias/ListarCategorias"
import { myMenuItems } from "componentes/painel-de-controle/sistema/usuarios/itensMenu"
import { decriptografar } from "uteis/crypto"
import { dadosDoUsuario } from "uteis/gerenciarSessao"
export const CategoriaProdutos = () => {
    const usuario = dadosDoUsuario();
    const items = myMenuItems();

    if (usuario.permissao === 'usuario') {
        return (
            <>
                <TopoSistema />
                <MenuLateral menuItems={items} />
                <ListarCategorias/>
                <BtnAdicionar>
                    <CriarUmaCategoriaProdutos />
                </BtnAdicionar>
            </>
        );
    } else if (usuario.permissao === 'sistema') {
        return (
            <>
                <TopoSistema />
                <MenuLateralSistema />
                <ListarCategorias/>
                <BtnAdicionar>
                    <CriarUmaCategoriaProdutos />
                </BtnAdicionar>
                <OniChatGenesi />
            </>
        );
    }
};
