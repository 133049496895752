import styled from "styled-components"
import { IconeEnviar } from "ui/Icones"
import { adicionarAntonimo, adicionarPalavra, adicionarSinonimo, analisarEResponder, criarVetor, parafrasearTexto } from "./ControllerOni"
import { useState } from "react"
import { useEffect } from "react"
import { pegarParametrosDaURL } from "uteis/funcoes"

export const ChatOni = () => {
    const[frase,setFrase] = useState('');
    const[conversas,setConversas] = useState([{ type: 'bot', text: 'Seja bem vindo! No que posso ajudar?' }]);
    const[respostaOni,setRespostaOni] = useState([])
    const[palavra,setPalavra]=useState('rápido')
    const[sinonimoUm,setSinonimoUm]=useState('veloz')
    const[sinonimoDois,setSinonimoDois]=useState('ligeiro')
    const[antonimoUm,setAntonimoUm]=useState('lento')
    const[antonimoDois,setAntonimoDois]=useState('vagaroso')
    const[vetor,setVetor]=useState('')
    const url=window.location.href
    const parametro=pegarParametrosDaURL(url)
    const treinamento=parametro.treinamento
    useEffect(() => {
      if (respostaOni.length > 0) {
        setConversas((prevConversas) => [
          ...prevConversas,
          { type: 'bot', text: respostaOni },
        ]);
      }
    }, [respostaOni]);
    const enviarMensagem = async () => {
      if (!frase.trim()) {
        setConversas([...conversas, { type: 'bot', text: 'Olá! Insira uma pergunta, por favor.' }]);
        return;
      }
      setConversas([...conversas, { type: 'user', text: frase }]);

      await analisarEResponder(frase, setRespostaOni);
      setFrase('');
    }
    if(treinamento==='1'){
        return<>
              <Recipiente>
              <Select
              onChange={(e)=>{
                  window.location.href=`/onisciente?treinamento=${e.target.value}`
              }}
            >
                  <option>Treinando Vetor</option>
                  <option value={'0'}>Finalizar treinamento</option>
              </Select>
                  <Conversa>
                      {conversas.map((conversa, index) => (
                          <Frase key={index} className={conversa.type}>
                              {conversa.type === 'user' ? 'Você: ' : 'Onisciente: '}
                              {conversa.text}
                          </Frase>
                      ))}
                  </Conversa>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault()
                    enviarMensagem()
                    criarVetor(vetor)
                  }}
                >
                  <Input
                    value={frase}
                    onChange={(e) =>{ 
                        setFrase(e.target.value)
                        setVetor(e.target.value)
                    }}
                    autoFocus
                  />
                  <Enviar>
                    <IconeEnviar />
                  </Enviar>
                </Form>
              </Recipiente>
            </>
    }else if(treinamento==='2'){

      return<>
        <RecipientePalavra>
        <Select
        onChange={(e)=>{
            window.location.href=`/onisciente?treinamento=${e.target.value}`
        }}
      >
            <option>Treinando palavras</option>
            <option value={'0'}>Finalizar treinamento</option>
        </Select>


        <FormPalavras
            onSubmit={async (e) => {
              e.preventDefault();
              if (palavra && sinonimoUm && sinonimoDois) {
                let palavraInput = await adicionarPalavra(palavra)
                let sinonimoUmInput = await adicionarPalavra(sinonimoUm);
                let sinonimoDoisInput = await adicionarPalavra(sinonimoDois);
                let antonimoUmInput = await adicionarPalavra(antonimoUm);
                let antonimoDoisInput = await adicionarPalavra(antonimoDois);
                const palavra_id = palavraInput.id;
                const sinonimoUm_id = sinonimoUmInput.id;
                const sinonimoDois_id = sinonimoDoisInput.id;
                const antonimoUm_id = antonimoUmInput.id;
                const antonimoDois_id = antonimoDoisInput.id;
                await adicionarSinonimo(palavra_id, sinonimoUm_id);
                await adicionarSinonimo(palavra_id, sinonimoDois_id);
                await adicionarAntonimo(palavra_id, antonimoUm_id);
                await adicionarAntonimo(palavra_id, antonimoDois_id);
              }
              setPalavra('');
              setSinonimoUm('');
              setSinonimoDois('');
              setAntonimoUm('');
              setAntonimoDois('');
            }}
          >
            <div>
                <Input
                  value={palavra}
                  placeholder="Palavra"
                  onChange={(e) =>{ 
                      setPalavra(e.target.value)
                  }}
                  autoFocus
                />
            </div>
            <div>
                <Input
                  value={sinonimoUm}
                  placeholder="Sinônimo 1"
                  onChange={(e) =>{ 
                      setSinonimoUm(e.target.value)
                  }}
                />
            </div>
            <div>
                <Input
                  value={sinonimoDois}
                  placeholder="Sinônimo 2"
                  onChange={(e) =>{ 
                      setSinonimoDois(e.target.value)
                  }}
                />
            </div>
            <div>
                <Input
                  value={antonimoUm}
                  placeholder="Antônimo 1"
                  onChange={(e) =>{ 
                      setAntonimoUm(e.target.value)
                  }}
                />
            </div>
            <div>
                <Input
                  value={antonimoDois}
                  placeholder="Antônimo 2"
                  onChange={(e) =>{ 
                      setAntonimoDois(e.target.value)
                  }}
                />
            </div>

            <Enviar>
              <IconeEnviar />
            </Enviar>
          </FormPalavras>
        </RecipientePalavra>
    </>
    }else if(treinamento==='3'){
      return<>
              <Recipiente>
              <Select
              onChange={(e)=>{
                  window.location.href=`/onisciente?treinamento=${e.target.value}`
              }}
            >
                  <option>Teste de paráfrase</option>
                  <option value={'0'}>Finalizar treinamento</option>
              </Select>
                  <Conversa>
                      {conversas.map((conversa, index) => (
                          <Frase key={index} className={conversa.type}>
                              {conversa.type === 'user' ? 'Você: ' : 'Onisciente: '}
                              {conversa.text}
                          </Frase>
                      ))}
                  </Conversa>
                <Form
                  onSubmit={async(e) => {
                    e.preventDefault();
                    const parafrase = await parafrasearTexto(frase);
                  
                    // Adicionando a entrada do usuário e a resposta parafraseada ao estado 'conversas'
                    setConversas(prevConversas => [
                      ...prevConversas,
                      { type: 'user', text: frase },
                      { type: 'bot', text: parafrase.textoParafraseado }
                    ]);
                  
                    // Limpar a frase após enviá-la
                    setFrase('');
                  }}
                  
                >
                  <Input
                    value={frase}
                    onChange={(e) =>{ 
                        setFrase(e.target.value)
                    }}
                    autoFocus
                  />
                  <Enviar>
                    <IconeEnviar />
                  </Enviar>
                </Form>
              </Recipiente>
            </>
    }else{
      return (
        <>
          <Recipiente>
          <Select
              onChange={(e)=>{
                  window.location.href=`/onisciente?treinamento=${e.target.value}`
              }}
          >
              <option>Iniciar novo treinamento</option>
              <option value={'1'}>Resposta de vetor</option>
              <option value={'2'}>Ensinar palavras</option>
              <option value={'3'}>Testar Paráfrases</option>
          </Select>
              <Conversa>
                  {conversas.map((conversa, index) => (
                      <Frase key={index} className={conversa.type}>
                          {conversa.type === 'user' ? 'Você: ' : 'Onisciente: '}
                          {conversa.text}
                      </Frase>
                  ))}
              </Conversa>
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                enviarMensagem()
              }}
            >
              <Input
                value={frase}
                onChange={(e) =>{ 
                  setFrase(e.target.value)
              }}
                autoFocus
              />
              <Enviar>
                <IconeEnviar />
              </Enviar>
            </Form>
          </Recipiente>
        </>
      )
    }
}
const Select=styled.select`
    margin-bottom: 1rem;
    border:none;
    font-family: 'Kanit';
    font-weight: 200;
    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
`
const Frase=styled.p`
    margin-bottom: 1em;
    font-weight: 200;
`
const Conversa = styled.div`
  height: 40rem;  // Defina a altura desejada para a sua conversa
  overflow-y: auto;  // Adicione uma barra de rolagem vertical quando o conteúdo exceder a altura do contêiner
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 3rem;
  // Adicione quaisquer outras propriedades de estilo que você deseja aqui
`;
const Recipiente=styled.div`
    position: fixed;
    bottom:0;
    width: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    padding: .2rem;
`
const RecipientePalavra=styled.div`
    position: fixed;
    width: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    padding: .2rem;
`
const Form=styled.form`
     width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
`
const FormPalavras=styled.form`
     width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
`
const Enviar=styled.button`
    border: none;
    font-size: 130%;
    cursor:pointer;
    padding: 0;
    transition: all 1s;
    background-color: transparent;
    position: absolute;
    bottom: 1.4em;
    color: #3f3f3f;
    right: 1rem;
    &:hover{
        color: white;
    }
`
const Input = styled.input`
    border: 1px solid #ced4da;
    border-radius: 0.20rem;
    font-size: 1rem;
    font-weight: 400;
    padding: 1em;
    padding-right: 3em;
    box-sizing: border-box;
    margin-bottom: 1rem;
    width: 100%;
    color:gray;
    background-color: whitesmoke;
    border: none;
    ::placeholder{
        font-size: 16px;
        color:lightgray;
    }
    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
`