import styled from "styled-components"
import { BotaoPrincipal, ElementoCentralizado, Input, Label } from "../../ui/ComponentesDeEstilo"
import { useState } from "react"
import { enviarEmailDeRecuperacaoDeSenha } from "../../paginas/Genesi/Controllers"
import { Alerta } from "componentes/global/Alertas"
import { Logo } from "componentes/global/Logo"
export const RecuperarSenha = () => {
    const[mostrarAlertaSucesso, setMostrarAlertaSucesso] = useState(false)
    return <>
        <ElementoCentralizado>
            <Formulario
                onSubmit={(event) => {
                    event.preventDefault()
                    console.log(event.target.email.value)
                    enviarEmailDeRecuperacaoDeSenha(event.target.email.value).then(resposta => {
                        event.target.reset()
                        setMostrarAlertaSucesso(true)
                    }).catch(erro => {
                        console.log(erro)
                    })
                }}
            >
                <Logo width="5rem" paddingBottom="1em" url="/"/>
                <p>Informe o e-mail cadastrado para receber instruções e recuperar sua senha.</p>
                <div>
                    <Label htmlFor="email">E-mail</Label>
                    <Input type="email" id="email" name="email" placeholder="INSIRA SEU E-MAIL CADASTRADO EM NOSSO SISTEMA." autoFocus />
                </div>
                <BtnEnviar type="submit">Enviar</BtnEnviar>
            </Formulario>
        </ElementoCentralizado>
        {mostrarAlertaSucesso&&<Alerta tipo="sucesso" mensagem="Email de recuperação de senha enviado com sucesso!"/>}
    </>
}
const BtnEnviar = styled(BotaoPrincipal)`
    display: flex;
    width: 10rem;
    align-items: center;
    justify-content: center;
    margin-top:1rem;
    border-radius: 10rem;
    background-color: whitesmoke;
    color: black;
    &:hover{
        background-color: #d6d6d6;
    }
`
const Formulario = styled.form`
    padding: 2rem;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    h1{
        font-size: 1.5rem;
        margin-bottom: 1em;
        text-transform: uppercase;
    }
    p{
        font-size: 1rem;
        margin: 0;
        margin-bottom: 2rem;
        text-align: left;
        line-height: 1.2em;
    }
    div{
        margin-bottom: 1rem;
    }
    @media(max-width: 1024px){
        max-width: 100%;
    }
    @media(max-width: 768px){
        max-width: 100%;
    }
`
