import { decriptografar } from "./crypto";
export const checarPermissaoUsuario = (setPermissao) => {
    const usuario = localStorage.getItem("usuario");
    if (usuario) {
        const usuarioDecriptografado = decriptografar(usuario);
        if (usuarioDecriptografado && usuarioDecriptografado.permissao) {
            const permissaoDoUsuario = usuarioDecriptografado.permissao
            setPermissao(permissaoDoUsuario)
        } else {
            window.location.href = "/login"
        }
    } else {
        window.location.href = "/login"
    }
}
export const dadosDoUsuario = () => {
    const usuario = localStorage.getItem("usuario")
    if (usuario) {
        const usuarioDecriptografado = decriptografar(usuario)
        if (usuarioDecriptografado) {
            return usuarioDecriptografado
        } else {
            console.error('Usuário decriptografado é nulo')
        }
    } else {
        console.error('Nenhum usuário no localStorage')
    }
}
