import styled from "styled-components"
import { IconeEnvelope, IconeSair, IconeUsuario } from "../../../ui/Icones"
import { Linque } from "../../../ui/ComponentesDeEstilo"
import { deslogarUsuario } from "./Controller"

export const MenuSistema = () => {
    const handleSair = () => {
        deslogarUsuario()
        window.location.href = "/login"
    }
    return (
        <Recipiente>
            <ItemMenu><IconeUsuario/><Paragrafo to="/meus-dados">Meus dados</Paragrafo></ItemMenu>
            <ItemMenu><IconeEnvelope/><Paragrafo>Minhas mensagens</Paragrafo></ItemMenu>
            <ItemMenu onClick={handleSair}><IconeSair/><Paragrafo>Sair</Paragrafo></ItemMenu>
        </Recipiente>
    )
}
const Recipiente = styled.div`
    background-color: whitesmoke;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    width: 15rem;
    z-index: 1;
    position: absolute;
    right: 6rem;
    top: 5rem;
`
const ItemMenu = styled.div`
    color: #212529;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 200;
    padding: .5rem;
    margin: 0;
    display: flex;
    align-items: center;
    &:hover {
        background-color: #f8f9fa;
    }
`
const Paragrafo=styled(Linque)`
    margin-left: .8em;
`