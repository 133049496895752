import { Link } from 'react-router-dom';
import styled from 'styled-components';
export const Verde="#198754"
export const Preto="#3f3f3f"
export const CorBotaoSecundario='#4E8FFF '
export const CorBotaoDestaque='#FF7F50'
export const CorAlerta='#FF4500'
export const CorInformacao='#4169E1'
export const CorErro='#FF0000'
export const CorTexto='#212529'
export const BotaoPrincipal = styled.button`
    background-color: ${Preto};
    border: 0;
    border-radius: 0.20rem;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    padding: 1em 1em;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.5s ease-in-out;
    &:hover{
        background-color: #2f2f2f;
    }
`
export const Recipiente = styled.div`
    background-color: ${props=>props.corFundo};
`
export const ElementoCentralizado =styled.div`
    position: absolute;
    left: 50%;
    top: 12rem;
    transform: translate(-50%, 0);
    padding: 3rem;
    z-index: 1;
`
export const Linque = styled(Link)`
    text-decoration: none;
    color: ${CorTexto};
`
export const BotaoDestaque = styled.button`
    background-color: #FCF071;
    border: 0;
    border-radius: 0.25rem;
    color: #7E71B1;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0.5rem 1rem;
    text-align: center;
`
export const AlertaSucesso=styled.div`
    background-color: ${CorAlerta};
    border: 0;
    border-radius: 0.25rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0.5rem 1rem;
    text-align: center;
`
export const AlertaInformacao=styled.div`
    background-color: ${CorInformacao};
    border: 0;
    border-radius: 0.25rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0.5rem 1rem;
    text-align: center;
`;
export const AlertaErro=styled.div`
    background-color: ${CorErro};
    border: 0;
    border-radius: 0.25rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0.5rem 1rem;
    text-align: center;
`
export const AlertaErroTexto=styled.div`
    color: ${CorErro};
    border: 0;
    border-radius: 0.25rem;
    font-size: 1rem;
    font-weight: 300;
    margin: -10px 0 0;
    width: 100%;
`
export const FlexColuna = styled.div`
    display: flex;
    flex-direction: column;
`
export const FlexLinha = styled.div`
    display: flex;
    flex-direction: row;
`
export const Input = styled.input`
    border: 1px solid #ced4da;
    border-radius: 0.20rem;
    font-size: 1rem;
    font-weight: 400;
    margin: .3em 0 1em 0;
    padding: 1em 1em;
    width: 100%;
    box-sizing: border-box;
    color:gray;
    ::placeholder{
        font-size: 16px;
        color:lightgray;
    }
    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
`
export const Select = styled.select`
    border: 1px solid #ced4da;
    border-radius: 0.20rem;
    font-size: 1rem;
    font-weight: 400;
    margin: .3em 0 1em 0;
    padding: 1em 1em;
    width: 100%;
    color:gray;
    ::placeholder{
        font-size: 16px;
    }
    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
`
export const Textarea = styled.textarea`
    border: 1px solid #ced4da;
    border-radius: 0.20rem;
    font-size: 1rem;
    font-weight: 400;
    padding: .5em .5em;
    width: 100%;        
    color:gray;
    box-sizing: border-box;
    ::placeholder{
        font-size: 16px;
        color:lightgray;
    }
    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
`
export const Label = styled.label`
    font-size: .7rem;
    font-weight: 400;
    margin: 0 0 0.5em 0;
    padding: 0;
    color:gray;
    width: 100%;
    text-transform: uppercase;
`
export const ErroInput = styled.p`
    color: ${CorErro};
    font-size: .7rem;
    font-weight: 400;
    margin: -1em 0 0.5em 0;
    padding: 0;
    text-transform: uppercase;
`