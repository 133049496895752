import { listarPublicacaoPorQTD } from "componentes/painel-de-controle/sistema/Controller"
import { useState } from "react"
import { useEffect } from "react"
import { ElementoCentralizado } from "ui/ComponentesDeEstilo"

export const LinhaDotempo=()=>{
    const[timelines,setTimeline]=useState([])
    useEffect(()=>{
        listarPublicacaoPorQTD('10',setTimeline)
    },[])
    return<>
        <ElementoCentralizado>
            {timelines.map(timeline=>{
                return<>
                    <p>{timeline.titulo}</p>
                </>
            })}
        </ElementoCentralizado>
    </>
}