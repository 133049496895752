import { Sistema } from "config/sistema"
import { Restful } from "servidor/restful"
import { criptografar } from "uteis/crypto";
import { dadosDoUsuario } from "uteis/gerenciarSessao"
const UsuarioID = dadosDoUsuario() ? dadosDoUsuario().id : null;

export const deslogarUsuario=async()=>{
    localStorage.removeItem('token')
    localStorage.removeItem('usuario')
    setTimeout(()=>{
        window.location.reload()
    },2000)
}
export const listarFotoDePerfil = async (usuario_id, setFotoDePerfil) => {
    try {
        const resposta = await Restful.get(`/listar-foto-por-id-e-sistema/${usuario_id}/${Sistema}`);
        console.log('foto de perfil',resposta.data.storage.url_arquivo)
        setFotoDePerfil(resposta.data.storage.url_arquivo)
        return resposta
    } catch (erro) {
        return erro
    }

}
export const listarConvidados = async () => {
    try {
        const resposta = await Restful.get('listar-convidados');
        return resposta.data;
    } catch (erro) {
        return erro;
    }
};

export const listarCategoriasPorSistema = async (quantidade,setCategorias,sistema) => {
    try {
        const resposta = await Restful.get(`/categoria/${sistema||Sistema}/${quantidade}`);
        setCategorias(resposta.data)
        return resposta
    } catch (erro) {
        console.log(erro)
        return erro
    }
  }
  export const qtdeCategoriasPorSistema = async (setQtdeCategorias) => {
    try {
        const resposta = await Restful.get(`/qtde-categoria/${Sistema}`)
        setQtdeCategorias(resposta.data)
        return resposta.data
    } catch (erro) {
        return erro
    }
  }
  export const qtdeSubcategoriasPorSistema = async (setQtdeSubcategorias) => {
    try {
        const resposta = await Restful.get(`/qtde-subcategoria/${Sistema}`)
        setQtdeSubcategorias(resposta.data)
        return resposta.data
    } catch (erro) {
        console.log(erro)
        return erro
    }
  }
export const enviarArquivo = async (file,usuario_id,foto_de_perfil,sistema) => {
    const data = new FormData()
    data.append('foto', file)
    data.append('usuario_id', usuario_id)
    data.append('foto_de_perfil', foto_de_perfil)
    data.append('sistema', sistema)
    try {
      const response = await Restful.post('/enviar-arquivo', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }
  export const editarStorageEmUso = async (id) => {
    try {
      const resposta = await Restful.patch(`/editar-storage/${id}`, {
        em_uso: 1
      });
      return resposta
    } catch (erro) {
      console.error("erro", erro.response?.data?.message);
      return erro
    }
  }
  export const emailRecuperacaoDeSenha=async(email)=>{
    return await Restful.post('/enviar-email-recuperacao-de-senha',{
        "email": email,
        "sistema": Sistema
    }).then(resposta=>{
        return resposta
    }).catch(erro=>{
        return erro
    })
}
export const criarCategoria = async (dados) => {
  try {
    const dadosUsuario = dadosDoUsuario();
    const resposta = await Restful.post('/categoria', {
      usuario_id: dadosUsuario.id,
      url_arquivo: dados.url_arquivo,
      titulo: dados.titulo,
      descricao: dados.descricao,
      sistema: dados.sistema || Sistema
    })

    return resposta.data.categoriaProduto.id;

  } catch (erro) {
    console.error("erro", erro.response?.data?.message);
    const resposta = erro.response?.data?.message;
    if (resposta === "Categoria de produtos já cadastrada") {
      return { sucesso: false, mensagem: "Esta categoria de produtos já está cadastrada no sistema!" };
    }
    return { sucesso: false, mensagem: erro };
  }
}
export const listarUmaCategoriaPorID = async (id,setUmaCategoria) => {
  try {
    const resposta = await Restful.get(`/uma-categoria/${id}`)
    setUmaCategoria(resposta.data)
    return resposta.data
  } catch (erro) {
    console.error("erro", erro.response?.data?.message)
    return erro
  }
}
export const criarSubcategoria = async (dados) => {
  try {
    const dadosUsuario = dadosDoUsuario();
    const resposta = await Restful.post('/subcategoria', {
      usuario_id: dadosUsuario.id,
      categoria_id: localStorage.getItem('categoriaID'),
      url_arquivo: dados.url_arquivo,
      titulo: dados.titulo,
      descricao: dados.descricao,
      sistema: dados.sistema
    })
    console.log(resposta)
    return resposta.data.subcategoriaProduto.id
  } catch (erro) {
    console.error("erro", erro.response?.data?.message);
    const resposta = erro.response?.data?.message;
    if (resposta === "Subcategoria de produtos já cadastrada") {
      return { sucesso: false, mensagem: "Esta subcategoria de produtos já está cadastrada no sistema!" }
    }
    return { sucesso: false, mensagem: erro }
  }
}
export const listarSubcategorias=async(categoria_id, setSubcategorias)=>{
  try {
    const resposta = await Restful.get(`/subcategorias/${categoria_id}`)
    console.log(resposta.data)
    setSubcategorias(resposta.data)
    return resposta
  } catch (erro) {
    return erro
  }
}
export const listarSubcategoria=async(subcategoria_id, setSubcategorias)=>{
  try {
    const resposta = await Restful.get(`/subcategoria/${subcategoria_id}`)
      console.log(resposta)
    setSubcategorias(resposta.data)
    return resposta
  } catch (erro) {
    return erro
  }
}
export const listarSubcategoriasPorSistema=async(setSubcategorias)=>{
  try {
    const resposta = await Restful.get(`/subcategorias/${Sistema}`)
    setSubcategorias(resposta.data)
    return resposta.data
  } catch (erro) {
    return erro
  }
}
export const listarPublicacaoPorQuantidadeESistema=async(quantidade, setPublicacoes)=>{
  try {
    const resposta = await Restful.get(`/publicacaoes/${quantidade}/${Sistema}`)
    console.log("resposta",resposta.data)
    setPublicacoes(resposta.data)
    return resposta
  } catch (erro) {
    return erro
  }
}
export const listarPublicacaoPorID=async(id,setPublicacao)=>{
  try{
    const resposta=await Restful.get(`/publicacao/${id}`)
    setPublicacao(resposta.data)
    console.log(resposta)
    return resposta.data
  }catch(err){
    return err
  }
}
export const listarPublicacaoPorQTD=async(qtd,setTimeLine)=>{
  try{
    const resposta=await Restful.get(`/linha-do-tempo/${qtd}`)
    setTimeLine(resposta.data)
    console.log(resposta)
    return resposta.data
  }catch(err){
    return err
  }
}
export const listarCategoriaPorSistema=async(setCategorias)=>{
  try {
    const resposta = await Restful.get(`/categoria/${Sistema}`)
    setCategorias(resposta.data)
    return resposta
  } catch (erro) {
    return erro
  }
}
export const listarQtdePublicacoesPorSistema=async(setQtdePublicacoes)=>{
  try {
    const resposta = await Restful.get(`/qtde-publicacoes/${Sistema}`)
    setQtdePublicacoes(resposta.data)
    return resposta
  } catch (erro) {
    console.log(erro)
    return erro
  }
}
export const criarNovaPublicacao=async(dados)=>{
  try {
    const resposta = await Restful.post('/publicacao', {
      categoria_id: dados.categoria_id,
      subcategoria_id: dados.subcategoria_id,
      usuario_id: dadosDoUsuario().id,
      sistema: Sistema,
      url_arquivo: dados.foto,
      titulo: dados.titulo,
      descricao: dados.descricao,
      status: dados.status
    })
    return resposta.data.servico.id
  } catch (erro) {
    console.error("erro", erro.response?.data?.message);
    const resposta = erro.response?.data?.message;
    if (resposta === "Serviço já cadastrado") {
      return { sucesso: false, mensagem: "Esta publicação já está cadastrado no sistema!" }
    }
    return { sucesso: false, mensagem: erro }
  }
}
export const listarTodosOsUsuariosDaApi=async(setUsuarios)=>{
  try {
    const resposta = await Restful.get(`/usuarios-api`)
    setUsuarios(resposta.data)
    return resposta
  } catch (erro) {
    return erro
  }
}
export const alterarDadosCategoriaPorUsuarioESistema=async(categoria_id,dados)=>{
    await Restful.patch(`/categoria/${categoria_id}/${Sistema}`,{
      "titulo":dados.titulo,
      "url_arquivo":dados.url_arquivo,
      "descricao":dados.descricao
    }).then(res=>{
      console.log(res)
      return res
    }).catch(err=>{
      console.log(err)
      return err
    })
}
export const vincularUsuarioApp=async(usuario_id,subcategoria_id,nome_app,url_arquivo)=>{
  const token=criptografar({usuario:usuario_id,app:subcategoria_id})
  await Restful.post('/usuario-app',{
    "usuario_id":usuario_id,
    "app":subcategoria_id,
    "nome_app":nome_app,
    "url_arquivo":url_arquivo,
    "token":token,
    "status":1
  }).then(res=>{
    console.log(res)
    return res
  }).catch(err=>{
    console.log(err)
    return err
  })
}
const id=UsuarioID
export const listarUsuarioAppPorUsuario=async(setUsuarioApp)=>{
  await Restful.get(`/usuario-app/${id}`)
    .then(res=>{
      setUsuarioApp(res.data)
      console.log(res.data)
      return res
    }).catch(err=>{
      console.log(err)
      return err
    })
}
export const listarUmUsuarioAppPorUsuario=async(setUsuarioApp)=>{
  await Restful.get(`/usuario-app-um/${UsuarioID}`)
    .then(res=>{
      setUsuarioApp(res.data)
      console.log(res.data)
      return res
    }).catch(err=>{
      console.log(err)
      return err
    })
}

