import styled from "styled-components"
import { IconeTrancar } from "ui/Icones"
export const CompraSegura = () => {
    return<>
        <SeparadorLocal>
            <IconeTrancarLocal/>
            <FlexColuna>
                <ParagrafoLocal>Compra 100%</ParagrafoLocal>
                <ParagrafoSegura>segura</ParagrafoSegura>
            </FlexColuna>
        </SeparadorLocal>
    </>
}
const FlexColuna=styled.div`
    display:flex;
    flex-direction:column;
`
const ParagrafoSegura=styled.p`
    font-size:1rem;
    font-weight:bold;
    text-transform: uppercase;

`
const IconeTrancarLocal=styled(IconeTrancar)`
    font-size:3rem;
`
const ParagrafoLocal=styled.p`
    font-size:.8rem;
    text-transform: uppercase;
    text-align:left;
`
const SeparadorLocal=styled.div`
    display:flex;
    width:8.7rem;
    text-align:left;
    align-items:center;
`