import { useState } from "react"
import styled from "styled-components"
import { useEffect } from "react"
import { Carregando } from "componentes/global/Carregando"
import { emailRecuperacaoDeSenha } from "./Controller"
import { dadosDoUsuario } from "uteis/gerenciarSessao";
import { Alerta } from "componentes/global/Alertas";
export const ConfirmacaoAlterarSenha = () => {
    const[mostraCarregando, setMostraCarregando]=useState(false)
    const[mostraAlertaSucesso, setMostraAlertaSucesso]=useState(false)
    const[usuario, setUsuario]=useState([])
    const usuarioEmail=dadosDoUsuario().email
    useEffect(()=>{
        dadosDoUsuario(setUsuario)
    },[])
    const handleReload=()=>{
        window.location.reload()
    }
    return<>
        <Recipiente
            onSubmit={(event)=>{
                event.preventDefault()
                setMostraCarregando(true)
                setTimeout(()=>{
                    setMostraCarregando(false)
                }, 3000)
                    emailRecuperacaoDeSenha(usuarioEmail)
                    setMostraAlertaSucesso(true)
                setTimeout(()=>{
                    window.location.reload()
                }, 3000)
            }}
        >
            <Fechar onClick={handleReload}>X</Fechar>
            <label htmlFor="confirmar">Você receberá um email para recuperar sua senha</label>
            <Botao>
                {mostraCarregando&&<Carregando tamanho="p"/>}
                Confirmar envio de email
            </Botao>
        </Recipiente>
        {mostraAlertaSucesso&&<Alerta tipo="sucesso" mensagem="Email enviado com sucesso!"/>}
    </>
}
const Fechar=styled.a`
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.3s;
    padding: 0.5rem;
    
    &:hover{
        color: #333;
    }
`
const Botao=styled.button`
    background-color: #3f3f3f;
    color: #fff;
    border: none;
    width: 18rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    transition: 0.3s;
    &:hover{
        background-color: #4f4f4f;
    }
`
const Recipiente=styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: whitesmoke;
    position:absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
    height: 15rem;
    border-radius: 0.5rem;
    label{
        font-size: 1rem;
        margin-bottom: 1rem;
    }
    button{
        background-color: #000;
        color: #fff;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        font-size: 1rem;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background-color: #333;
        }
    }
`

