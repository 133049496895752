import styled from "styled-components";
import { Linque } from "ui/ComponentesDeEstilo";
import { IconeAlertaCircular, IconeFechar, IconeVisto } from "ui/Icones";

export const Alerta = (props) => {
    if(props.tipo==="sucesso"){
        return <>
            <MensagemAlerta bottom={props.bottom} role="alert">
                <VistoIcone/>
                <div>
                    <Paragrafo>{props.mensagem} </Paragrafo>
                    <Linque to={props.url}>{props.urlTexto}</Linque>
                </div>
            </MensagemAlerta>
        </>
    }
    if(props.tipo==="erro"){
        return <>
            <MensagemAlerta role="alert">
                <FecharIcone/>
                <Paragrafo>{props.mensagem}</Paragrafo>
            </MensagemAlerta>
        </>
    }
    if(props.tipo==="informacao"){
        return <>
            <MensagemAlerta role="alert">
                <AlertaIcone/>
                <Paragrafo>{props.mensagem}</Paragrafo>
            </MensagemAlerta>
        </>
    }
}
const FecharIcone = styled(IconeFechar)`
    font-size: 1.5rem;
    margin-right: 1em;
`
const VistoIcone = styled(IconeVisto)`
    font-size: 1.5rem;
    margin-right: 1em;
`
const AlertaIcone = styled(IconeAlertaCircular)`
    font-size: 1.5rem;
    margin-right: 1em;
`
const Paragrafo = styled.p`
    font-size: 1rem;
`
const MensagemAlerta = styled.div`
    position: fixed;
    left: 50%;
    bottom: ${props=>props.bottom || '10rem'};
    transform: translate(-50%, 0);
    background-color: whitesmoke;
    border: 1px solid #eeeeee;
    color: black;
    padding: 1.5em 5em;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.5;
    text-align: center;
    z-index: 4;
`
