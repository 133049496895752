import { Restful } from "servidor/restful";
import {Sistema}from "../config"
export class CarrinhoDeComprasController {
criarCarrinhoDeCompras = async (novoItem, tamanhoSelecionado, corSelecionada, quantidade) => {
  let carrinhoObj
  const carrinho = window.localStorage.getItem("carrinho")

  if (carrinho) {
    carrinhoObj = JSON.parse(carrinho);

    if (Array.isArray(carrinhoObj)) {
      carrinhoObj.push({ item: novoItem, tamanho: tamanhoSelecionado, cor: corSelecionada, quantidade, estado: "carrinho" });
    } else {
      const novoCarrinho = [
        { item: carrinhoObj, tamanho: tamanhoSelecionado, cor: corSelecionada, quantidade, estado: "carrinho" },
        { item: novoItem, tamanho: tamanhoSelecionado, cor: corSelecionada, quantidade, estado: "carrinho" }
      ];
      carrinhoObj = novoCarrinho
    }
  } else {
    const novoCarrinho = [{ item: novoItem, tamanho: tamanhoSelecionado, cor: corSelecionada, quantidade, estado: "carrinho" }]
    carrinhoObj = novoCarrinho;
  }

  window.localStorage.setItem("carrinho", JSON.stringify(carrinhoObj));
}
atualizarEstadoCarrinho = () => {
  const carrinho = window.localStorage.getItem("carrinho");

  if (carrinho) {
    const carrinhoObj = JSON.parse(carrinho);

    if (Array.isArray(carrinhoObj)) {
      const carrinhoFinalizado = carrinhoObj.map((item) => {
        return { ...item, estado: "finalizado" };
      })

      window.localStorage.setItem("carrinho", JSON.stringify(carrinhoFinalizado));
    }
  }
};
listarCarrinhoDeCompras = async (setCarrinhoDeCompras) => {
  const carrinho = window.localStorage.getItem('carrinho');
  if (carrinho) {
    const carrinhoObj = JSON.parse(carrinho);
    const carrinhoFiltrado = carrinhoObj.filter(item => item.estado === 'carrinho');
    setCarrinhoDeCompras(carrinhoFiltrado);
  } else {
    setCarrinhoDeCompras([]);
  }
};
listarCompras = async (setCarrinhoDeCompras) => {
  const carrinho = window.localStorage.getItem('carrinho');
  if (carrinho) {
    const carrinhoObj = JSON.parse(carrinho);
    const carrinhoFiltrado = carrinhoObj.filter(item => item.estado === 'finalizado');
    setCarrinhoDeCompras(carrinhoFiltrado);
  } else {
    setCarrinhoDeCompras([]);
  }
};
deletarItemCarrinhoDeCompras = (id) => {
  const carrinho = window.localStorage.getItem('carrinho');

  if (carrinho) {
    const carrinhoObj = JSON.parse(carrinho);

    // Encontre o índice do item com base no id
    const index = carrinhoObj.findIndex(item => item.item.id === id);

    if (index !== -1) {
      // Remova o item do objeto do carrinho de compras
      carrinhoObj.splice(index, 1);

      // Atualize o carrinho de compras no localStorage
      window.localStorage.setItem('carrinho', JSON.stringify(carrinhoObj));
    }
  }
};
contarItensCarrinhoDeCompras = (setContador) => {
  const carrinho = window.localStorage.getItem('carrinho');
  if (carrinho) {
    const carrinhoObj = JSON.parse(carrinho);
    const numItens = Array.isArray(carrinhoObj) ? carrinhoObj.length : 1;
    const numItensCarrinho = carrinhoObj.filter(item => item.estado === "carrinho").length;
    setContador(numItensCarrinho);
    return numItensCarrinho;
  } else {
    setContador(0);
    return 0;
  }
};
somarPrecosItens = (itens, setSomaPrecos) => {
  const soma = itens.reduce((total, item) => {
    const preco = parseFloat(item.item.preco);
    const quantidade = parseInt(item.quantidade);
    const valorItem = preco * quantidade;
    return total + valorItem;
  }, 0);
    setSomaPrecos(soma.toFixed(2));
  }
  //carrinho banco
criarCarrinhoDeComprasBanco = async (dados) => {
    Restful.post('/criar/carrinho',{
      "produto_id":dados.produto_id,
      "usuario_id":localStorage.getItem('UsuarioID'),
      "produto_cor_id":dados.produto_cor_id,
      "id_customizado":localStorage.getItem('id_customizado'),
      "produto_descricao":dados.produto_descricao,
      "produto_url_foto":dados.produto_url_foto,
      "produto_cor":dados.produto_cor,
      "produto_tamanho":dados.produto_tamanho,
      "valor":dados.valor,
      "quantidade":dados.quantidade,
      "status":"carrinho",
      "sistema":Sistema,
      "referencia_externa":localStorage.getItem('referencia')
    }).then((response) => {
      console.log(response.data);
    }).catch((error) => {
      console.log(error);
    })
  }
removerItemCarrinhoDeComprasBanco = async (tamanho) => {
    Restful.delete(`/deletar/produto/tamanho/${tamanho}`).then((response) => {
      console.log(response.data);
    }).catch((error) => {
      console.log(error);
    })
  }
listarCarrinhoPorReferenciaExterna = async (id,setCarrinhoReferencia) => {
    Restful.get(`/listar/carrinho/referencia/externa/${id}`).then((response) => {
      setCarrinhoReferencia(response.data);
    }).catch((error) => {
      console.log(error);
    })
  }
atualizarEstoqueCorPorId = async (id,quantidade) => {
    Restful.patch(`/atualizar/quantidade/cores/${id}`,{
      "quantidade":quantidade
    }).then((response) => {
      console.log(response.data);
    }).catch((error) => {
      console.log(error);
    })
  }
  estoqueAtualizado = async (id) => {
    Restful.patch(`/estoque/atualizado/${id}`).then((response) => {
      console.log(response.data);
    }).catch((error) => {
      console.log(error);
    })
  }
}
