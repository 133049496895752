import styled from "styled-components"
import { LogoCereja } from "../componentes/LogoCereja"
import { CompraSegura } from "../componentes/CompraSegura"
export const Cabecalho = () => {
    return<>
        <RecipienteLocal>
            <LogoCereja width={'4rem'}/>
            <CompraSegura/>
        </RecipienteLocal>
    </>
}
const RecipienteLocal=styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    padding:1rem;
    border-bottom:1px solid lightgray;
    margin:0 auto;
`