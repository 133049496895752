import styled from "styled-components"
import { Logo } from "componentes/global/Logo"
import { IconeEnviar } from "ui/Icones"
import { useState } from "react"
import { useData } from "contexts/DataContext"
import { useEffect } from "react"
import { analisarEResponder } from "componentes/painel-de-controle/sistema/onisciente/ControllerOni"
export const OniChatGenesi = () => {
    const {data,setData}=useData()
    const[mostraMensagem,setMostraMensagem] = useState(false)
    const[frase,setFrase]=useState('')
    const[mensagens, setMensagens] = useState([]);

    useEffect(()=>{
        if(data===true){
            setMostraMensagem(true)
        }
    })
    const handleAbrir = () => {
        setData(false);
        setMostraMensagem(!mostraMensagem);
        if (!mostraMensagem) {
            setMensagens([{ texto: 'Olá! Como posso ajudar?', tipo: 'recebida' }]);
        }
    }
    return (
        <>
          <Div>
            {mostraMensagem && <Chat>
              <CaixaMensagens>
                <Mensagens>
                  {mensagens.map((mensagem, index) => (
                    mensagem.tipo === "enviada" ? (
                      <MensagemEnviada key={index}>
                        Você: {mensagem.texto}
                      </MensagemEnviada>
                    ) : (
                      <MensagemRecebida key={index}>
                        Onisciente: {mensagem.texto}
                      </MensagemRecebida>
                    )
                  ))}
                </Mensagens>
              </CaixaMensagens>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  analisarEResponder(frase, (resposta) => {
                    setMensagens([...mensagens, { texto: frase, tipo: "enviada" }, { texto: resposta, tipo: "recebida" }]);
                  });
                  setFrase('');
                }}
              >
                <Separador>
                  <Input
                    onChange={(e)=>{
                      setFrase(e.target.value)
                    }}
                    value={frase}
                    type="text"
                    autoFocus
                  />
                  <BtnEnviar>
                    <EnviarIcone />
                  </BtnEnviar>
                </Separador>
              </form>
            </Chat>}
            <div onClick={handleAbrir}>
              <P>0</P>
              <Logo oni={true} width={'3rem'} />
            </div>
          </Div>
        </>
      )
}
const CaixaMensagens = styled.div`
   min-height: 30rem;
   max-height: 30rem;
   overflow-y: auto;

`
const MensagemEnviada = styled.p`
  background-color: whitesmoke;
  padding: 0.5rem;
  margin-bottom: .2rem;
  margin-top: .2rem;
  border-radius: .2rem;
`;

const MensagemRecebida = styled.p`
  background-color: #f5f5f57b;
  padding: 0.5rem;
  margin-bottom: .2rem;
  margin-top: .2rem;
  border-radius:.2rem
`;
const BtnEnviar=styled.button`
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 89%;
    bottom:1.6em;
`
const Separador=styled.div`
    margin-top: 1rem;
`
const Mensagens=styled.div`
    font-weight: 200;
    line-height: 1.5em;
    margin-bottom: 1em;
`
const EnviarIcone=styled(IconeEnviar)`

    color: #3f3f3f;
`
const Input=styled.input`
    width: 100%;
    box-sizing: border-box;
    border:none;
    background-color: whitesmoke;
    height: 2.5em;
    padding-left: 1em;
    padding-right: 3em;
    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
`
const Chat=styled.div`
    position: absolute;
    z-index: 0;
    right: 4em;
    bottom: 4em;
    background-color: white;
    padding: 1em;
    width: 20rem;
    border-radius: .2rem;
    box-shadow: 0 0 .2rem rgba(0,0,0,.1);

`
const P=styled.p`
    background-color: white;
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3f3f3f;
    padding: .1em;
    border-radius: 50%;
    font-size: 90%;
    margin-bottom: -.9em;
    margin-left: -.2em;
    position: relative;
    z-index: 5;
    font-weight: 400;

`
const Div = styled.span`
    position: fixed;
    bottom: 3.4rem;
    right: 6em;
    color: #000;
    border-radius: 50%;
    transition: all .3s ease-in-out;
    cursor: pointer;
    &:hover{
    }
    `