import {pegarParametrosDaURL} from "../../uteis/funcoes";
import {Topo} from "./Topo";
import {CompartilhamentoDeVideos} from "./CompartilhamentoDeVideos";
import {DobraOni} from "./DobraOni";
import {DobraLoja} from "./DobraLoja";
import {RodaPe} from "../Genesi/RodaPe";
import {CMS} from "./CMS"
import {WhatsApp} from "../../componentes/sites/e-lingerie/componentes/Whatsapp";
export const LandingPage=()=>{
    const url = window.location.href
    const parametro=pegarParametrosDaURL(url)
    localStorage.setItem('token-ativacao',parametro.token)
    return<>
        <Topo/>
        <CompartilhamentoDeVideos/>
        <DobraOni/>
        <DobraLoja/>
        <CMS/>
        <WhatsApp/>
        <RodaPe/>
    </>
}