import React, { useState, useEffect } from "react";
import { Linque } from "ui/ComponentesDeEstilo";
import { ProdutoController } from "../controllers/ProdutoController";
import { Link } from "react-router-dom";
import { IconeFavorito, IconeFavoritoPreenchido} from "ui/Icones";
import { FavoritosController } from '../controllers/FavoritosController';
import styled from "styled-components";
export const QuatroProdutos = () => {
  const [produtos, setProdutos] = useState([])
  useEffect(() => {
    const produtoController = new ProdutoController()
    produtoController.listarProdutosPorQuantidadeESistema(4, setProdutos)
  },[])
  const handleAdicionarFavorito = (id, url, preco) => {
    const favoritosController = new FavoritosController()
    favoritosController.criarFavorito({ id, url, preco })
    window.location.reload()
  }
  return (
    <>
      <H1Local>Lançamentos</H1Local>
      <RecipienteLocal id="lancamentos">
        {produtos.map((produto) => (
          <SeparadorLocal key={produto.id}>
            <IconeFavoritoLocal onClick={() => handleAdicionarFavorito(produto.id, produto.url_foto, produto.preco)} />
            <LinkLocal to={`/produto?id=${produto.id}`}>
              <ImagemLocal src={produto.url_foto} />
              <PrecoDescricaoLocal>
                <H3Local>{produto.descricao}</H3Local>
                <h3>R$ {produto.preco}</h3>
              </PrecoDescricaoLocal>
            </LinkLocal>
          </SeparadorLocal>
        ))}
      </RecipienteLocal>
    </>
  );
};
const H3Local = styled.h3`
  color: #333;
  margin-bottom: 1rem;
  font-weight: 700;
  text-transform: uppercase;
`
const PrecoDescricaoLocal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  margin-top: 1rem;
`
const IconeFavoritoLocal = styled(IconeFavoritoPreenchido)`
  color: #4a004d45;
  font-size: 2.5rem;
  top: 3.8rem;
  margin-left: 0.3rem;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  cursor: pointer;
  &:hover {
    color: #4a004d;
  }
`
const LinkLocal = styled(Linque)`
  text-decoration: none;
  color: #333;
`
const RecipienteLocal = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  padding: 0 20rem 5rem;
  background-color: #fff;
  text-align: center;
  @media(max-width: 768px) {
    padding: 0 10rem 5rem;
  }
  @media(max-width: 1366px) {
    padding: 0 5rem 5rem;
  }
  @media(max-width: 425px) {
    padding: 0 0 5rem;
  }
`
const ImagemLocal = styled.img`
  width: 15rem;
  height: 18rem;
  margin-top: 1rem;

  @media(max-width: 768px) {
    width: 100%;
    height: auto;
    margin-top: 0;

  }
`
const SeparadorLocal = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #979797;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.3);
  }
`
const H1Local = styled.h1`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  margin-top: 6rem;
  font-weight: 700;
  text-transform: uppercase;
`
