import { IconeLixeira } from "ui/Icones"
import { useEffect,useState } from "react"
import { FavoritosController } from '../controllers/FavoritosController';
import { Cabecalho } from "../favoritos/Cabecalho"
import styled from "styled-components"
export const Favoritos = () => {
    const [favoritos, setFavoritos] = useState([]);
    useEffect(() => {
        const favoritosController = new FavoritosController();
        favoritosController.listarFavoritos(setFavoritos);
      }, []);

    const handleRemoverItem = (id) => {
        const favoritosController = new FavoritosController();
        favoritosController.deletarFavoritos(id);
        
        // Atualize a lista de itens do carrinho de compras após excluir o item
        favoritosController.listarFavoritos((novosItens) => {
          setFavoritos(novosItens);
        });
      };

    return<>
        <Cabecalho/>
        <ParagrafoContador>Meus favoritos</ParagrafoContador>
        <RecipienteLocal>
            <FlexColumna>
                {favoritos && favoritos.map((item)=>{
                    return(
                        <>
                        <FlexLinha key={item.id}>
                            <Foto src={item.url}/>
                            <ParagrafoItens> Valor: R$ {item.preco}</ParagrafoItens>
                            <ParagrafoLixeira onClick={()=>handleRemoverItem(item.id)}><IconeLixeira/></ParagrafoLixeira>
                        </FlexLinha>
                        </>
                    )
                })}
            </FlexColumna> 
        </RecipienteLocal>
    </>
}
const ParagrafoLixeira=styled.p`
    color:gray;
    cursor:pointer;
    font-size:1.2rem;
`
const RecipienteLocal=styled.div`
    display:flex;
    align-items:flex-start;
    width:80%;
    margin:0 auto;
`
const FlexColumna=styled.div`
    display:flex;
    flex-direction:row;
`
const FlexLinha=styled.div`
    display:flex;
    flex-direction:column;
    background-color: ghostwhite;
    padding:1rem;
    align-items:center;
`
const ParagrafoItens = styled.p`
    font-size:.8rem;
    color:black;
    cursor:pointer;
    line-height:1.5rem;
    text-align:left;
    text-transform: uppercase;
`
const Foto = styled.img`
    width:8rem;
    @media(max-width: 923px) {
        width:100%;
    }
`
const ParagrafoContador = styled.p`
    text-align:left;
    font-size:2rem;
    font-weight:bold;
    color:black;
    margin:1rem;
    width:80%;
    margin:0 auto;
    padding-bottom: 2rem;
    border-bottom: 1px solid lightgray;
    margin-bottom: 3rem;
    margin-top: 2rem;
    @media(max-width: 923px) {
        width:100%;
        text-align:center;
    }
`

