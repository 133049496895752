import React, { useState } from "react";
import styled from "styled-components";
export const BtnAdicionar = ({children}) => {
    const [mostrarFormulario, setMostrarFormulario] = useState(false);
    const handleClick = () => {
        setMostrarFormulario(!mostrarFormulario);
        localStorage.removeItem("storage")
    }
    return<>
        {mostrarFormulario ? (
            <>
                <Remover onClick={handleClick}>-</Remover>
                {children}
            </>
        ) : (
            <Adicionar onClick={handleClick}>+</Adicionar>
        )}
    </>
}
const Adicionar = styled.button`
    background-color: #1e1e1e;
    color: white;
    border: none;
    border-radius: 0.2rem;
    padding: 0.5rem;
    margin: 1.5rem;
    font-size: 2rem;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0;
    bottom: 0;
    transition: all 1s ease-in-out;
    z-index: 1;
    &:hover {
        background-color: #3c3c3c;
    }
`
const Remover = styled.button`
    background-color: #1e1e1e;
    color: white;
    border: none;
    border-radius: 0.2rem;
    padding: 0.5rem;
    margin: 1.5rem;
    font-size: 2rem;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0;
    bottom: 0;
    transition: all 1s ease-in-out;
    z-index: 1;
    &:hover {
        background-color: #3c3c3c;
    }
`











