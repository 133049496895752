import styled from "styled-components"
import { Card } from "./cards/Card"
import {  IconeAcessibilidade, IconeAndar, IconeImagem, IconePincel, IconeRepetir, IconeServidor, IconeTendencia, IconeTrancar } from "ui/Icones"
export const DobraTres = () => {
    return<>
        <Dobra>
            <Textos>
                <h1>Recursos poderosos</h1>
                <p>Escolha entre uma variedade de layouts pré-construídos e personalizáveis para acelerar seu fluxo de trabalho.</p>
            </Textos>
            <Divisor>
                <Card titulo="Layouts editáveis e responsívos">
                    <IconePincel />
                </Card>
                <Card titulo="Ferramentas de SEO">
                    <IconeTendencia />
                </Card>
                <Card titulo="Servidores de alto desempenho">
                    <IconeServidor />
                </Card>
                <Card titulo="Alta sergurança">
                    <IconeTrancar />
                </Card>
                <Card titulo="Edite de onde estiver">
                    <IconeAndar />
                </Card>
                <Card titulo="Gerenciador de mídias">
                    <IconeImagem />
                </Card>
                <Card titulo="Acessibilidade">
                    <IconeAcessibilidade />
                </Card>
                <Card titulo="Integração com getway de pagamento">
                    <IconeRepetir />
                </Card>
            </Divisor>
        </Dobra>

    </>
}
const Divisor = styled.div`
    width: 55%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1em;
    @media(max-width: 1340px){
        width:45%;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
    }
    @media(max-width: 500px){
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(8, 1fr);
    }
`
const Dobra = styled.div`
    text-align: center;
    background-color: white;
    z-index: 1;
    padding-top: 10em;
    padding-bottom: 10em;
    margin-top: 20em;
    box-shadow: 0 0 .5rem rgba(0,0,0,1);
    color: #3f3f3f;
`
const Textos = styled.div`
    width: 35%;
    line-height: 1.5rem;
    margin: 0 auto 5rem;
    h1{
        font-size: 2.5em;
        margin-bottom: 1em;
    }
    p{
        font-size: 1.2em;
    }
`
