import video from "../../assets/video/topo-tarot.mp4";
import styled from "styled-components";
import {decriptografar} from "../../uteis/crypto";
import {Logo} from "../../componentes/global/Logo";
import {BotaoDestaque} from "../../ui/ComponentesDeEstilo";
import {IconeSetaDireita} from "../../ui/Icones";
export const Topo = () => {
    const dadosToken=localStorage.getItem('token-ativacao')
    const convidado=decriptografar(dadosToken)
    const handleProximo=()=>{
        window.location.href='/landing-page#agendamento'
    }
    return <>
        <Recipiente>
            <Logos>
                <Logo/>
            </Logos>
            <Conteudo>
                <Titulo>Olá {convidado&&convidado.nome}!</Titulo>
                <Subtitulo>Você foi convidado à <br/> conhecer a aplicação <br/> Oraculum CMS e IA</Subtitulo>
                <Paragrafo>O Oraculum é uma aplicação web especializada em astrologia, tarot e que oferece à pouca(o)s ferramentas para exibição e gerenciamento de conteúdo, atendimento com vídeo conferência nativa do site, agendamento de consultas, retenção de clientes e monetização própria também do seu site, direto na sua conta, no momento do pagamento de seu connsulente. Mas tem muito mais, conheça o Oraculum nesta página. </Paragrafo>
                <Botao onClick={handleProximo}>Conhecer</Botao>
            </Conteudo>
            <Video autoPlay={true} loop={true} src={video}></Video>
        </Recipiente>
    </>
}
const Recipiente=styled.div`
    text-align: center;
  
`
const Botao = styled(BotaoDestaque)`
    color: black;
    padding: 1em 3em;
    margin-top: 2em;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 215, 0, 0.89);
`;
const Logos=styled.div`
    padding: .3em;
    background-color: whitesmoke;
    box-shadow: 1px 10px 67px 33px rgba(3, 3, 3, 0.82);
    position: fixed;
    width: 100%;
    z-index: 3;
    top: 0;
`
const Video=styled.video`
    width: 100%;
    box-shadow: 1px 10px 67px 33px rgba(3, 3, 3, 0.82);
`
const Conteudo = styled.div`
    position: absolute;
    z-index: 2;
    top: 25em;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 4em;
`;
const Paragrafo=styled.p`
    color: white;
    line-height: 1.2em;
    width: 30rem;
    margin: 1em auto;
    font-size: 120%;
`
const Titulo=styled.h1`
    font-weight: 300;
    color: white;
    font-size: 400%;
`
const Subtitulo=styled.h2`
    font-weight: 300;
    color: white;
    font-size: 300%;
`
