import { UsuarioController } from '../controllers/UsuarioController';
import { useState } from "react";
import { useEffect } from "react";
import { CorreiosController } from '../controllers/CorreiosController';
import { CarrinhoDeComprasController } from "../controllers/CarrinhoDeComprasController";
import styled from "styled-components";

export const Resumo = () => {
    const [nome, setNome] = useState('');
    const [sobrenome, setSobrenome] = useState('');
    const [CEP, setCEP] = useState('');
    const [permissao, setPermissao] = useState('');
    const [valorFrete, setValorFrete] = useState('');
    const [carrinhoDeCompras, setCarrinhoDeCompras] = useState([]);

    useEffect(() => {
      const carrinhoDeComprasController = new CarrinhoDeComprasController();
      carrinhoDeComprasController.listarCompras(setCarrinhoDeCompras);
    }, []);
  
    useEffect(() => {
      const usuarioController = new UsuarioController();
      const id = localStorage.getItem('UsuarioID');
  
      usuarioController
        .listarUsuarioPorIdESistema(id, setNome, setSobrenome, setCEP, setPermissao)
        .then(resposta => {})
        .catch(erro => {
          console.log(erro);
        });
    }, []);

  
    useEffect(() => {
      const valor = parseFloat(localStorage.getItem('valor')) || 0;
      const subtotal = valor || 0;
      const frete = parseFloat(valorFrete) || 0;
      const total = subtotal + frete;
      localStorage.setItem('Total', total.toFixed(2));
    }, [valorFrete]);
  
    const handleSubmit = evento => {
      evento.preventDefault();
      const correiosController = new CorreiosController();
      correiosController
        .calcularFrete(CEP, setValorFrete)
        .then(resposta => {
          // Fazer algo com a resposta, se necessário
        })
        .catch(erro => {
          console.log(erro);
        });
    };
  
    const valor = parseFloat(localStorage.getItem('valor')) || 0;
    const subtotal = valor || 0;
    const frete = parseFloat(valorFrete) || 0;
    const total = subtotal + frete;
    localStorage.setItem('Total', total.toFixed(2));
  
    return (
      <>
        <Recipiente>
          <Paragrafo>{nome} {sobrenome}</Paragrafo>
          <p>Subtotal: {subtotal}</p>
          <form onSubmit={handleSubmit}>
            <Input placeholder="Insira o CEP" autoFocus onChange={evento => setCEP(evento.target.value)} type="text" />
            <Button type="submit">Calcular frete</Button>
          </form>
          <p>Frete: {valorFrete}</p>
          <p>Total: {total}</p>

          <p>Lista de pedidos</p>
          <FlexLinha>
          {carrinhoDeCompras.map(item => {
            return (
              <Card key={item.item.id}>
                <Img src={item.item.url_foto} alt="" />
                <ParagrafoCard>{item.item.categoria_nome} {item.item.subcategoria_nome}</ParagrafoCard>
              </Card>
            );
          })}
          </FlexLinha>
        </Recipiente>
      </>
    );
  };
  const FlexLinha=styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
  align-items: left;

  `
  const Paragrafo = styled.p`
  font-size: 1.5rem;
  `
  const Recipiente=styled.section`
  color: black;
  background-color: ghostwhite;
  padding: 1.5rem 3rem 3rem 3rem;
  margin: 1rem auto ;
  border-radius: .3rem;
  line-height: 2rem;
  border-bottom: .1rem solid lightgray;
`
  const Button = styled.button`
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: .5rem;
  padding: .5rem;
  margin-bottom: 1rem;
  margin-left: .2rem;
  `
  const Input = styled.input`
  height: 1rem;
  border: 1px solid #ccc;
  border-radius: .5rem;
  padding: .5rem;
  margin-bottom: 1rem;
  `
  const ParagrafoCard = styled.p`
  font-size: .7rem;  
  `
  const Img = styled.img`
  width: 7rem;
  object-fit: cover;
  `
  const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 7rem;
  margin-top: 1rem;
  background-color: white;
  margin-right: 1rem;
  `