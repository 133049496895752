export class FavoritosController{
    criarFavorito = async (novoItem) => {
        let favoritoObj;
        const favorito = window.localStorage.getItem('favorito');
        if (favorito) {
          favoritoObj = JSON.parse(favorito);
          if (Array.isArray(favoritoObj)) {
            favoritoObj.push(novoItem);
          } else {
            const novoFavorito = [favoritoObj, novoItem];
            favoritoObj = novoFavorito;
          }
        } else {
          const novoFavorito = [novoItem];
          favoritoObj = novoFavorito;
        }
        window.localStorage.setItem('favorito', JSON.stringify(favoritoObj));
      }
    listarFavoritos = async (setFavoritos) => {
        const favorito = window.localStorage.getItem('favorito');
        if (favorito) {
          const favoritoObj = JSON.parse(favorito);
          if (Array.isArray(favoritoObj)) {
            setFavoritos(favoritoObj);
          } else {
            setFavoritos([favoritoObj]);
          }
        } else {
            setFavoritos([]);
        }
      }
    deletarFavoritos = (id) => {
        const favorito = window.localStorage.getItem('favorito');
        if (favorito) {
          const favoritoObj = JSON.parse(favorito);
          const index = favoritoObj.findIndex(item => item.id === id);
          if (index !== -1) {
            favoritoObj.splice(index, 1);
            window.localStorage.setItem('favorito', JSON.stringify(favoritoObj));
          }
        }
      }
    contarFavoritos = (setContador) => {
        const favorito = window.localStorage.getItem('favorito');
        if (favorito) {
          const favoritoObj = JSON.parse(favorito);
          const numItens = Array.isArray(favoritoObj) ? favoritoObj.length : 1;
          setContador(numItens);
          return numItens;
        } else {
          setContador(0);
          return 0;
        }
      }
}

