import { Link } from "react-router-dom"
import { BotaoPrincipal, ElementoCentralizado, Linque } from "../../ui/ComponentesDeEstilo"
import styled from "styled-components"
import { IconeVoltar } from "../../ui/Icones"

export const NaoEncontrado = () => {
    const handleVoltar = () => {
        window.location.href="/"
    }
    return<>
        <ElementoCentralizado>
            <h1>Erro 404</h1>
            <h2>Página não encontrada</h2>
            <BotaoVoltar onClick={handleVoltar}><IconeVoltar/></BotaoVoltar>
        </ElementoCentralizado>
    </>
}
const BotaoVoltar=styled(BotaoPrincipal)`
    background-color: transparent;
    padding: 1rem 1rem;
    color: #6e6e6e;
    &:hover{
        background-color: transparent;
        color: whitesmoke;
    }
`