import { MenuLateralSistema } from "componentes/painel-de-controle/sistema/MenuLateralSistema"
import { TopoSistema } from "componentes/painel-de-controle/sistema/TopoSistema"
import { ListarUsuarios } from "componentes/painel-de-controle/sistema/usuarios/ListarUsuarios"
import {BtnAdicionar} from "../../componentes/painel-de-controle/sistema/BtnAdicionar";
import {Convidar} from "../../componentes/painel-de-controle/sistema/usuarios/Convidar";
import {ListarConvidados} from "../../componentes/painel-de-controle/sistema/usuarios/ListarConvidados";

export const Usuarios = () => {
    
    return<>
        <TopoSistema/>
        <MenuLateralSistema/>
        <ListarUsuarios />
        <ListarConvidados/>
        <BtnAdicionar>
            <Convidar/>
        </BtnAdicionar>
    </>
}
