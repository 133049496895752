import Axios from 'axios'
const TOKEN = localStorage.getItem('token')
export const Restful = Axios.create({
    baseURL: 'http://localhost:3001',
    //baseURL: 'https://www.onisciente.com:1111',
    timeout: 10000,
    headers:{
        'Content-Type':'application/json',
        'Accept':'application/json',
        'Authorization': `Bearer ${TOKEN}`
    },
})
