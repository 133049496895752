import { useState } from "react";
import { BotaoPrincipal, ElementoCentralizado, Input, Label, Linque } from "../../ui/ComponentesDeEstilo";
import styled from "styled-components";
import { IconeEntrar } from "../../ui/Icones";
import { useEffect } from "react";
import { TemConta } from "../global/TemConta";
import { logarUsuario } from "../../paginas/Genesi/Controllers";
import { criptografar } from "../../uteis/crypto";
import { Carregando } from "../global/Carregando";
import { Logo } from "componentes/global/Logo";
import { Alerta } from "componentes/global/Alertas";
import { buscarDadoLocal } from "uteis/funcoes";
export const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isLogin, setIsLogin] = useState(false)
    const [noLogin, setNoLogin] = useState(false)
    const [info, setInfo] = useState(false)
    const [erro, setErro] = useState(false)
    const [carregando, setCarregando] = useState(true)
    useEffect(() => {
        if (erro) {
            setNoLogin(true)
        }
    }, [erro])

    useEffect(() => {
        const tempo= setTimeout(() => {
            setCarregando(false)
        }, 1000);
        return () => {
            clearTimeout(tempo)
        }
    }, [])

    if(carregando){
        return <>
            <ElementoCentralizado>
                <Carregando tamanho="p" mensagem="Carregando..."/>
            </ElementoCentralizado>
        </>
    }else{
        const temToken=buscarDadoLocal("token")
        if(temToken){
            window.location.href="/painel-de-controle"
            return
        }
        
        return <>
                <ElementoCentralizadoEstilizado>
                    <Logo width="5rem" paddingBottom="1rem" url="/"/>
                    <form
                        onSubmit={async(e) => {
                            e.preventDefault()
                            if (email ==="" || password ==="") {
                                setInfo(true)
                                setNoLogin(false)
                                setIsLogin(false)
                                return;
                            }else{
                                const dados={
                                    email:email,
                                    senha:password
                                }
                                const resultado=await logarUsuario(dados,setErro)
                                if(resultado&&resultado.data&&resultado.data.valor){
                                    localStorage.setItem("token",resultado.data.valor)
                                }
                                if(resultado&&resultado.status===undefined){
                                    setInfo(true)
                                    setNoLogin(false)
                                    setIsLogin(false)
                                }
                                else if(resultado&&resultado.status===200){
                                    setIsLogin(true)
                                    setNoLogin(false)
                                    setInfo(false)
                                    const UsuarioLogado=criptografar(resultado.data.usuario)
                                    localStorage.setItem("usuario",UsuarioLogado)
                                    setTimeout(() => {
                                        window.location.href="/painel-de-controle"
                                    }, 3000);
                                }else{
                                    setNoLogin(true)
                                    setIsLogin(false)
                                    setInfo(false)
                                }
                            }
                        }}
                    >
                        <Label>E-mail</Label>
                        <Input  type="email" onChange={(e) => setEmail(e.target.value)} placeholder='SEU E-MAIL' autoFocus/>
                        <Label>Senha</Label>
                        <Input  type="password" onChange={(e) => setPassword(e.target.value)} placeholder='********'/>
                        <Linque to="/recuperar-senha">Esqueceu a senha?</Linque>
                        <BotaoEntrar>
                            {isLogin && <Carregando tamanho="p" mensagem="Redirecionando..."/>}
                            {isLogin===false&& <DivBtn> <p>Entrar</p> <EntrarIcone/></DivBtn>}
                            
                        </BotaoEntrar>
                    </form>
                    <TemConta textoUm="Não tem conta?" textoDois="Cadastre-se" url="/cadastro"/>
                </ElementoCentralizadoEstilizado>

                
                {noLogin && <Alerta tipo="erro" mensagem="Email ou senha incorretos"/>}
                {info && <Alerta tipo="erro" mensagem="Email ou senha incorretos"/>}
        </>
    }

}
const DivBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    `

const ElementoCentralizadoEstilizado = styled(ElementoCentralizado)`
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 1rem;
`
const EntrarIcone = styled(IconeEntrar)`
    width: 20px;
    height: 20px;
    margin-left: 10px;
`
const BotaoEntrar = styled(BotaoPrincipal)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:1rem;
    border-radius: 10rem;
    background-color: whitesmoke;
    color: black;
    &:hover{
        background-color: #d6d6d6;
    }
`