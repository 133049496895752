import { useState } from 'react';
import styled from "styled-components";
import { IconeAbrir,  IconeAjudaCircularContorno,  IconeChat,  IconeConstrucao,  IconeFechar,  IconeFerramentas,  IconeFoguete, IconeMegaFone, IconeServicos, IconeSetaDireita, IconeSetaEsquerda, IconeUsuario } from 'ui/Icones';
import { Linque } from 'ui/ComponentesDeEstilo';
import { Logo } from 'componentes/global/Logo';
export const MenuLateral = ({ menuItems }) => {
    const [isOpen, setIsOpen] = useState(false);

    return <>
        <Recipiente isOpen={isOpen}>
            {isOpen ? 
                <Fechar onClick={() => setIsOpen(!isOpen)} /> 
                : 
                <Hamburguer onClick={() => setIsOpen(!isOpen)} />
            }

            {menuItems.map((item, index) => (
                <ItemMenu key={index}>
                    <Icone>{item.icon}</Icone>
                    <Linque target={item.target} to={item.link}>{item.nome}</Linque>
                </ItemMenu>
            ))}
        </Recipiente>
    </>
}
const Icone = styled.span`
    cursor: pointer;
    padding: 0.5rem;
    width: 2rem;
    color:#9f9f9f;
`
const Fechar=styled(IconeSetaEsquerda)`
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    top: .7rem;
    position: absolute;
    right: -2.8rem; //ajuste o valor de acordo com a necessidade
    background-color: whitesmoke;
    padding: 0.3rem;
    z-index: 3;
    color: ligthgr;
`
const Hamburguer = styled(IconeSetaDireita)`
    cursor: pointer;
    height: 2rem;
    top: .7rem;
    position: absolute;
    right: -2.8rem; //ajuste o valor de acordo com a necessidade
    background-color: whitesmoke;
    padding: 0.3rem;
    width: 2rem;
    z-index: 3;
    color: #3f3f3f;
`
const Recipiente = styled.div`
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    position: fixed; //Adicionamos essa propriedade
    top:6em;
    width: 13rem;
    left: ${props => props.isOpen ? "0" : "-13rem"};
    transition: left 0.3s ease-in-out;
    z-index: 3;
    text-align:right;
    box-shadow: 0 0 .1em #aaaaaa;
    border-right: 3px solid #aaaaaa;

`
const ItemMenu = styled.div`
    color: #212529;
    cursor: pointer;
    font-size: 1rem;
    padding: 1rem;
    margin: 0;
    display: flex;
    align-items: center;
    font-weight: 300;
    &:hover {
        background-color: #f8f9fa;
    }
`
