import styled from "styled-components"
import { FlexLinha } from 'ui/ComponentesDeEstilo';
export const Trilha=({etapaAtual})=>{
    return <>
        <FlexLinhaEstilizado>
            <Passo numero={1} descricao="Dados pessoais" etapaAtual={etapaAtual} />
            <Passo numero={2} descricao="Dados de endereço" etapaAtual={etapaAtual} />
            <Passo numero={3} descricao="Dados de acesso" etapaAtual={etapaAtual} />
        </FlexLinhaEstilizado>
    </>
}
const Passo = ({ numero, descricao, etapaAtual }) => {
    return <>
        <PassoEstilizado etapaAtual={etapaAtual} numero={numero}>
            <span>{numero}</span>
            <p>{descricao}</p>
        </PassoEstilizado>
        <LinhaTrastejandoOProgresso/>
    </>
}
const PassoEstilizado = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1.5rem;
    z-index: 1;
    span {
        font-size: .8rem;
        background-color: ${props => props.etapaAtual === props.numero ? 'gray' : 'lightgray'};
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${props => props.etapaAtual === props.numero ? 'white' : 'gray'};
    }
    p {
        font-size: 0.8rem;
        color: ${props => props.etapaAtual === props.numero ? 'black' : 'gray'};
        margin-top: 0.5rem;
    }
`
const FlexLinhaEstilizado = styled(FlexLinha)`
    position: relative;
    justify-content: space-between;
    margin-bottom: 1rem;
`

const LinhaTrastejandoOProgresso = styled.div`
    width: 100%;
    height: 0.1rem;
    background-color: #cccccc;
    position: absolute;
    top: .7rem;
    z-index: 0;
`