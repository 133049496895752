import { MenuLateral } from "componentes/global/MenuLateral"
import { listarSubcategoria, listarUmUsuarioAppPorUsuario } from "componentes/painel-de-controle/sistema/Controller"
import { TopoSistema } from "componentes/painel-de-controle/sistema/TopoSistema"
import { myMenuItems } from "componentes/painel-de-controle/sistema/usuarios/itensMenu"
import { useState } from "react"
import { useEffect } from "react"
import { ElementoCentralizado, Linque } from "ui/ComponentesDeEstilo"
import { criptografar } from "uteis/crypto"
import { pegarParametrosDaURL } from "uteis/funcoes"

export const Aplicacao = () => {
    const [categorias, setSubcategorias] = useState([])
    const url = window.location.href
    const parametro = pegarParametrosDaURL(url)


    const [usuarioApp, setUsuarioApp] = useState([])
    console.log('usuarioApp',usuarioApp)
    useEffect(() => {
      listarUmUsuarioAppPorUsuario(setUsuarioApp);
    }, [])
    const handleSessao=()=>{
      const dados={
        'token':usuarioApp.token,
        'usuario_id':usuarioApp.usuario_id
        
      }
      console.log("outro token",dados.token)
      const tokenCriptografado=criptografar(dados)
      sessionStorage.setItem('tknapp',tokenCriptografado)
    }




    useEffect(() => {
        listarSubcategoria(parametro.id, setSubcategorias);
    }, [parametro.id])
    const items=myMenuItems()
    return <>
            <TopoSistema/>
            <MenuLateral menuItems={items} />
            <ElementoCentralizado>
                <p>{categorias.titulo}</p>
                <Linque to={'/categoria'} onClick={handleSessao}>Estoque</Linque><br />
                <Linque to={`/${categorias.url}`}>Ir para aplicação</Linque>
            </ElementoCentralizado>
        </>
}
