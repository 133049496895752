import { Restful } from "servidor/restful"

export class CorreiosController {
    buncarEnderecoPorCEP = async (cep, setRua,setBairro,setCidade,setEstado) => {
        await Restful.get(`/cep/${cep}`).then(resposta => {
            setRua(resposta.data.logradouro)
            setBairro(resposta.data.bairro)
            setCidade(resposta.data.localidade)
            setEstado(resposta.data.uf)
        }).catch(erro => {
            return erro
        })
    }
    calcularFrete = async (cep, setValorFrete) => {
        await Restful.post(`/frete`,{
            "sCepOrigem": "35179000",
            "sCepDestino": cep,
            "nVlPeso": "1",
            "nCdFormato": "1",
            "nVlComprimento": "20",
            "nVlAltura": "5",
            "nVlLargura": "15",
            "nVlDiametro": "0",
        }).then(resposta => {
            setValorFrete(resposta.data)
        }).catch(erro => {
            return erro
        })
    }
}