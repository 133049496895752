import { Restful } from "servidor/restful"
import { Sistema } from "../config"
export class ProdutoController{
    criarProduto=async(dados)=>{
        const id = localStorage.getItem('UsuarioID')
        await Restful.post('/cadastrar/produto',{
            "codigo":localStorage.getItem('codigoDoProduto'),
            "descricao":dados.descricao,
            "url_foto":dados.url_foto,
            "categoria_id":dados.categoria_id,
            "categoria_nome":dados.categoria_nome,
            "subcategoria_id":dados.subcategoria_id,
            "subcategoria_nome":dados.subcategoria_nome,
            "estoque":dados.estoque,
            "cor":dados.cor,
            "cor_nome":dados.cor_nome,
            "tamanho":dados.tamanho,
            "peso":dados.peso,
            "preco":dados.preco,
            "preco_promocional":dados.preco_promocional,
            "sistema":Sistema,
            "usuario_id":id
        }).then(resposta=>{
            localStorage.setItem('idProduto',resposta.data.id)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    atualizarQuantidadeDeCores = async (compras) => {
        try {
          const response = await Restful.post('/atualizar/quantidade/cores', { compras });
          console.log('Resposta:', response);
        } catch (error) {
          console.error('Erro:', error);
        }
      };
    cadastrarAlbum=async(url)=>{
        await Restful.post('/cadastrar/album',{
            "produto_id":localStorage.getItem('idProduto'),
            "url_foto":url,
            "sistema":Sistema
        }).then(resposta=>{
            console.log(resposta)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarAlbumPorPodutoID=async(id,setAlbum)=>{
        await Restful.get(`/listar/album/produto/${id}`).then(resposta=>{
            setAlbum(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    cadastrarCor=async(dados)=>{
        await Restful.post('/cadastrar/cor',{
            "tamanho_id":dados.tamanho_id,
            "nome":dados.nome,
            "codigo":dados.codigo,
            "quantidade":dados.quantidade,
            "sistema":Sistema
        }).then(resposta=>{
            console.log(resposta)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarCoresPorTamanhoID=async(id,setCores)=>{
        await Restful.get(`/listar/cores/tamanho/${id}`).then(resposta=>{
            setCores(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarProdutosPorTermoESistema=async(termo,setProdutos)=>{
        await Restful.get(`/listar/produtos/${termo}/${Sistema}`).then(resposta=>{
            console.log(resposta.data)
            setProdutos(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    cadastrarTamanho=async(dados)=>{
        await Restful.post('/cadastrar/tamanho',{
            "produto_id":localStorage.getItem('idProduto'),
            "tamanho":dados.tamanho,
            "sistema":Sistema
        }).then(resposta=>{
            const id= resposta.data.id
            window.location.href=`/cadastrar-cores?id=${id}`
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarTamanhoPorID=async(id,setTamanho)=>{
        await Restful.get(`/listar/tamanho/${id}`).then(resposta=>{
            setTamanho(resposta.data.tamanho)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarTamanhosPorPodutoID=async(setTamanhos)=>{
        const id = localStorage.getItem('idProduto')
        await Restful.get(`/listar/tamanhos/produto/${id}`).then(resposta=>{
            setTamanhos(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarProdutosPorQuantidadeESistema=async(quantidade,setProdutos)=>{
        await Restful.get(`/listar/produtos/quantidade/sistema/${Sistema}/${quantidade}`).then(resposta=>{
            
            setProdutos(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarUmProdutoPorIdESistema=async(id,setProduto)=>{
        await Restful.get(`/listar/um/produto/${id}/sistema/${Sistema}`).then(resposta=>{
            setProduto(resposta.data)
            return resposta.data
        }).catch(erro=>{
            console.log(erro)
        })
    }
    editarProduto=async(dados)=>{
        await Restful.patch(`/editar/produto/${dados.id}`,{
            "descricao":dados.descricao,
            "preco":dados.preco,
            "preco_promocional":dados.preco_promocional
        }).then(resposta=>{
            console.log(resposta)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    deletarProduto=async(id)=>{
        await Restful.delete(`/deletar/produto/${id}`).then(resposta=>{
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarProdutosPorCategoriaID=async(id,setProdutos)=>{
        await Restful.get(`/listar/produtos/${id}`).then(resposta=>{
            setProdutos(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarPromocoes=async(setProdutos)=>{
        await Restful.get(`/listar/promocoes`).then(resposta=>{
            setProdutos(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarPromocoesPorQuantidade=async(quantidade,setProdutos)=>{
        await Restful.get(`/listar/promocoes/${quantidade}`).then(resposta=>{
            setProdutos(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
}