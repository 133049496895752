import { useEffect } from "react"
import {  listarTodosOsUsuariosDaApi } from "../Controller"
import { useState } from "react";
import { Linque, Recipiente } from "ui/ComponentesDeEstilo";
import styled from "styled-components";

export const ListarUsuarios = () => {
    const [usuarios, setusuarios] = useState([]);
    useEffect(() => {
        listarTodosOsUsuariosDaApi(setusuarios)
    }, []);
    return<>
        {usuarios.map(usuario=>{
            return(
                <RecipienteEstilizado key={usuario.id}>
                        <Card to={`/usuario?id=${usuario.id}`}> 
                            <Paragrafo>
                                {usuario.nome_completo}
                            </Paragrafo>
                            <Permissao>
                                {usuario.permissao}
                            </Permissao>
                        </Card>
                </RecipienteEstilizado>
            )
        })}
    </>
}
const RecipienteEstilizado=styled(Recipiente)`
    padding: 10em 25% 0 25%;
    display:flex;
    align-items:center;
    `
const Card=styled(Linque)`
    background-color: whitesmoke;
    line-height: 1.2rem;
    padding:1rem;
    border-radius:.2rem;
`
const Paragrafo=styled.p`
    color:#3f3f3f;
`
const Permissao=styled.p`
    text-transform:capitalize;
    font-size:.8rem;
    color:#3f3f3f;
`