import { useState } from 'react';
import styled from "styled-components";
import {IconeSetaDireita, IconeSetaEsquerda,} from 'ui/Icones';
import { Linque } from 'ui/ComponentesDeEstilo';
export const MenuLateralGenesi = () => {
    const [isOpen, setIsOpen] = useState(false); // Adiciona estado para controlar a abertura/fechamento
    return (
        <Recipiente isOpen={isOpen}>
            {isOpen ? 
                <Fechar onClick={() => setIsOpen(!isOpen)} /> 
                : 
                <Hamburguer onClick={() => setIsOpen(!isOpen)} />
            }
            <ItemMenuTopo><LinkEstilizado to="/login">Painel de controle</LinkEstilizado></ItemMenuTopo>
            <ItemMenu><Paragrafo to='/'>Início</Paragrafo></ItemMenu>
            <ItemMenu><Paragrafo to='/categoria-produtos'>Cursos</Paragrafo></ItemMenu>
            <ItemMenu><Paragrafo to='/blog'>Notícias</Paragrafo></ItemMenu>
            <ItemMenu><Paragrafo to='/categoria-produtos'>Shopping</Paragrafo></ItemMenu>
            <ItemMenu><Paragrafo>Fale conosco</Paragrafo></ItemMenu>

        </Recipiente>
    )
}
const LinkEstilizado = styled(Linque)`
    color: white;
    font-weight: 200;
`
const Fechar=styled(IconeSetaEsquerda)`
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    top: .7rem;
    position: absolute;
    right: -2.8rem; //ajuste o valor de acordo com a necessidade
    background-color: white;
    padding: 0.3rem;
    z-index: 3;
    color: #3f3f3f;
`
const Hamburguer = styled(IconeSetaDireita)`
    cursor: pointer;
    height: 2rem;
    top: .7rem;
    position: absolute;
    right: -2.8rem; //ajuste o valor de acordo com a necessidade
    background-color: white;
    padding: 0.3rem;
    width: 2rem;
    z-index: 3;
    color: #3f3f3f;
`
const Recipiente = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    position: fixed; //Adicionamos essa propriedade
    width: 10rem;
    left: ${props => props.isOpen ? "0" : "-10rem"};
    transition: left 0.3s ease-in-out;
    z-index: 3;
    text-align:right;
    box-shadow: 0 0 .1em #aaaaaa;
    border-right: 3px solid #aaaaaa;

`
const ItemMenu = styled.div`
    color: #212529;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    padding: 1rem;
    margin: 0;
    display: flex;
    align-items: center;
    font-weight: 200;
    &:hover {
        background-color: #f8f9fa;
    }
`
const ItemMenuTopo = styled.div`
    font-weight: 200;
    color: white;
    background-color: #7f7f7f;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    padding: 1rem;
    margin: 0;
    display: flex;
    align-items: center;
    &:hover {
        background-color: #9f9f9f;
    }
`
const Paragrafo=styled(Linque)`
`
