import { useEffect } from "react"
import { listarUsuarioAppPorUsuario } from "../Controller"
import { useState } from "react"
import { Linque } from "ui/ComponentesDeEstilo"
import { criptografar } from "uteis/crypto"
import styled from "styled-components"
export const ListarAppsPorUsuario = () => {
    const [usuarioApp, setUsuarioApp] = useState([])
    console.log('usuarioApp',usuarioApp)
    useEffect(() => {
      listarUsuarioAppPorUsuario(setUsuarioApp);
    }, [])
    const handleSessao=()=>{
      const dados={
        'token':usuarioApp.token,
        'usuario_id':usuarioApp.usuario_id
        
      }
      console.log("outro token",dados.token)
      const tokenCriptografado=criptografar(dados)
      sessionStorage.setItem('tknapp',tokenCriptografado)
    }
    return <>
        <Recipiente>
          <Flex>
            {usuarioApp.map((usuario) => {
              return (
                <FlexColuna>
                  <Divisao  key={usuario.id}>
                    <Linque onClick={handleSessao} to={`/aplicacao?id=${usuario.app}`}>
                      <Figure>
                        <Imagem src={usuario.url_arquivo} alt="" />
                        <FigCaption>
                          <Titulo>{usuario.nome_app}</Titulo>
                        </FigCaption>
                      </Figure>
                    </Linque>
                  </Divisao>
                </FlexColuna>
              )
            })}
          </Flex>
        </Recipiente>
    </>
  }
const Flex= styled.div`
    display: flex;
`
const FlexColuna= styled.div`
    display: flex;
`
const Titulo = styled.h1`
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    color: #3f3f3f;
`
const Divisao = styled.div`
    border-bottom: 1px solid #ccc;
    border-radius: .5rem;
    background-color: white;
    box-shadow: 0 0 .1rem rgba(0,0,0,.2);
    border-radius: 1em;
    margin: .5em;
    display: flex;
    cursor: pointer;
    padding: .5em;
`;

const Recipiente = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10em 25% 10em 25%;
    flex-wrap: wrap;
`;

const Figure = styled.figure`
    width: 100%;
`

const Imagem = styled.img`
    height: 11vh;
    width: 10.5rem;
    object-fit: cover;
    border-radius: .2rem;
`;
const FigCaption = styled.figcaption`
`;

