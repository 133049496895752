import styled from "styled-components";
import { Input, Label, Textarea } from "ui/ComponentesDeEstilo";
import {IconeFacebook, IconeInstagram, IconeTikTok} from "ui/Icones";

export const FaleConosco=()=>{
    return<>
        <Container>
                <H3>Entre em contato</H3>
            <Form>
                <Campos>
                    <Label htmlFor="">Seu nome completo:</Label>
                    <Input type="text"/>
                </Campos>
                <Campos>
                    <Label htmlFor="">Seu email:</Label>
                    <Input type="text"/>
                </Campos>
                <Campos>
                    <Label htmlFor="">Sua mensagem:</Label>
                    <Textarea></Textarea>
                </Campos>
                <Button>Enviar uma mensagem</Button>
                <AreaIcones>
                    <TituloIcones>
                        ou siga nas redes sociais
                    </TituloIcones>
                    <Icones>
                        <FaceIcone/>
                        <InstaIcone/>
                        <TikIcone/>
                    </Icones>
                </AreaIcones>
            </Form>
        </Container>
    </>
}
const Container=styled.section`
  background-color: #000000da;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 14rem;
  padding-bottom: 10rem;
  width: 100%;
  @media(max-width: 551px){
    padding-top: 1rem;
    border-radius: 0;
    background-color: white;
  }
  transition: all 1s ease;
  &:hover{
    background-color: #10006d68;
  }

`
const Form=styled.form`
  background-color: #f5f5f5b3;
  padding: 2rem;
  color: black;
  border-radius: .1rem;
  width: 30rem;
  margin: 0 auto;
  @media(max-width: 551px){
    width: 100%;
    border-radius: 0;
  }
`
const H3=styled.h3`
  font-size: 200%;
  margin: -2em auto 5rem;
  color: whitesmoke;
  letter-spacing: .1em;
`
const FaceIcone=styled(IconeFacebook)`
  font-size: 190%;
  color: cornflowerblue;
  cursor: pointer;
`
const InstaIcone=styled(IconeInstagram)`
  font-size: 190%;
  color: indianred;
  cursor: pointer;
`
const TikIcone=styled(IconeTikTok)`
  font-size: 190%;
  color: #483838;
  cursor: pointer;
`
const Icones=styled.section`
  display: flex;
  justify-content: space-between;
  width: 9rem;
  margin: 0 auto;
`
const TituloIcones=styled.h4`
  margin-bottom: 1rem;
`
const AreaIcones=styled.section`
  text-align: center;
  font-size: 120%;
  margin-top: 4rem;
  margin-bottom: 3rem;
`
const Button=styled.button`
  margin-top: .9rem;
  padding: .7rem;
  border-radius: .2rem;
  border: none;
  background-color: #3f3f3f;
  color: whitesmoke;
  font-size: 90%;
  letter-spacing: .1em;
  text-transform: uppercase;
  cursor: pointer;
`

const Campos=styled.section`
  display: flex;
  flex-direction: column;
`