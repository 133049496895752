import { useEffect } from "react"
import { useState } from "react"
import styled from "styled-components"
import { Linque } from "ui/ComponentesDeEstilo"
import { IconeCarrinhoDeCompra } from "ui/Icones"
import { CarrinhoDeComprasController } from "../controllers/CarrinhoDeComprasController"

export const Carrinho=()=>{
    const [mostraContador, setMostraContador] = useState(false)
    const [contador, setContador] = useState(0)
    useEffect(() => {
      const carrinhoDeComprasController = new CarrinhoDeComprasController()
      carrinhoDeComprasController.contarItensCarrinhoDeCompras(setContador)
    }, [])
    useEffect(() => {
      if (contador > 0) {
        setMostraContador(true)
      }
    }, [contador])
    return<>
          <LinkLocal to="/carrinho/de/compras">
            <IconeCarrinhoDeCompraLocal />
            {mostraContador && <QuantidadeDeItens>{contador}</QuantidadeDeItens>}
          </LinkLocal>
    </>
}
const LinkLocal = styled(Linque)`
text-decoration: none;
margin-left: 3rem;
display: flex;
align-items: center;
justify-content: center;
@media(max-width: 400px) {
  margin-left: 0;
}
`
const QuantidadeDeItens = styled.p`
width: 1.5rem;
height: 1.5rem;
background-color: #2b2b2b;
color: #fff;
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
font-size: 0.8rem;
position: relative;
top: -0.7rem;
left: -0.7rem;
`
const IconeCarrinhoDeCompraLocal = styled(IconeCarrinhoDeCompra)`
width: 1.8rem;
height: 1.8rem;
color: #333;
cursor: pointer;
&:hover {
  color: #000;
}
@media(max-width: 400px) {
  margin-left: 0;
}
`