import styled from "styled-components"
import fundo from "assets/img/fundoGenesi.jpg"
export const DobraDois = () => {
    return<>
        <Dobra>
        </Dobra>
    </>
}
const Dobra = styled.div`
    background-color: black;
    background-image: url(${fundo});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 2000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: -1; 
    margin-top: -80em;
    position:fixed;
`