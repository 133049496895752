import styled from "styled-components"
import { CardPainelDeControle } from "./CardPainelDeControle"
import { useEffect } from "react"
import { listarQtdePublicacoesPorSistema, qtdeCategoriasPorSistema, qtdeSubcategoriasPorSistema } from "../Controller"
import { useState } from "react"
import { Carregando } from "componentes/global/Carregando"
import { ElementoCentralizado } from "ui/ComponentesDeEstilo"
export const CardsQTDCadastros=()=>{
    const [qtdeCategorias,setQtdeCategorias]=useState(0)
    const [qtdeSubcategorias,setQtdeSubcategorias]=useState(0)
    const [qtdePublicacoes,setQtdePublicacoes]=useState(0)
    const [carregando,setCarregando]=useState(true)
    useEffect(()=>{
        const tempo=setTimeout(()=>{
            setCarregando(false)
        },1500)
        return()=>{
            clearTimeout(tempo)
        }
    },[])
    useEffect(()=>{
        listarQtdePublicacoesPorSistema(setQtdePublicacoes)
    },[])
    useEffect(()=>{
        qtdeCategoriasPorSistema(setQtdeCategorias)
    },[])
    useEffect(()=>{
        qtdeSubcategoriasPorSistema(setQtdeSubcategorias)
    },[])
    if(carregando){
    return<>
            <ElementoCentralizado>
                <Carregando tamanho="p" mensagem="Carregando..."/>
            </ElementoCentralizado>
        </>
    }else{
        return<>
            <Recipiente>
                <CardPainelDeControle corFundo="#3f3f3f" corTexto="whitesmoke" corQTD="whitesmoke" quantidade={qtdePublicacoes} texto="Publicações"/>
                <CardPainelDeControle quantidade={qtdeCategorias} texto="Categorias"/>
                <CardPainelDeControle quantidade={qtdeSubcategorias} texto="Subcategorias"/>
            </Recipiente>
        </>
    }
}
const Recipiente=styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10rem;
    left: 17rem;
`