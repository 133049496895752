export const mascaraCpf = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}
export const mascaraCNPJ=value=>{
    return value
        .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
}
export const mascaraData=value=>{
    return value
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1");
}
export const mascaraTelefone = value => {
    value = value.replace(/\D/g, "");

    if (value.length === 10) {
        // Formato para telefone fixo (XX)XXXX-XXXX
        return value.replace(/(\d{2})(\d{4})(\d{4})/, "($1)$2-$3");
    } else if (value.length === 11) {
        // Formato para celular (XX)X XXXX-XXXX
        return value.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1)$2 $3-$4");
    }
    return value;
};
export const mascaraSomenteLetras = value => {
    return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, "")
}
export const mascaraSomenteNumeros=value=>{
    return value.replace(/\D/g, "")
}
export const mascaraCEP=value=>{
    return value.replace(/\D/g, "").replace(/^(\d{5})(\d{3})+?$/, "$1-$2")
}
export const mascaraMoeda=value=>{
    return value
        .replace(/\D/g,"")
        .replace(/(\d{1})(\d{17})$/,"$1,$2")
        .replace(/(\d{1})(\d{13})$/,"$1,$2")
        .replace(/(\d{1})(\d{10})$/,"$1,$2")
        .replace(/(\d{1})(\d{7})$/,"$1,$2")
        .replace(/(\d{1})(\d{1,2})$/,"$1,$2")
}
export const tratarData=value=>{
    return value
        .split('/').reverse().join('-')
}
export const verificarSeCPFEValido=value=>{
    let Soma
    let Resto
    Soma=0
    if(value==='00000000000')return false
    for (let i=1;i<=9;i++)
        Soma=Soma+parseInt(value.substring(i-1,i))*(11-i)
    Resto=(Soma*10)%11
    if(Resto===10||Resto===11)Resto=0
    if (Resto!==parseInt(value.substring(9,10)))return false
    Soma=0
    for(let i=1;i<=10;i++)
        Soma=Soma+parseInt(value.substring(i-1,i))*(12-i)
    Resto=(Soma*10)%11
    if(Resto===10||Resto===11)Resto=0
    if (Resto!==parseInt(value.substring(10, 11)))return false
    return true
}
export const limparCPF=(ObjCPF)=>{
    let cpf = ObjCPF;
    console.log('cpf',cpf)
    if (cpf.length === 14) {
        cpf = cpf.charAt(0)+cpf.charAt(1)+cpf.charAt(2)+
            cpf.charAt(4)+cpf.charAt(5)+cpf.charAt(6)+
            cpf.charAt(8)+cpf.charAt(9)+cpf.charAt(10)+
            cpf.charAt(12)+cpf.charAt(13);
        return cpf;
    }
};
export const limparCEP=(cep)=>{
    const cepLimpo= cep.replace(/\.|\-/g, '');
    return cepLimpo
}
export const limparMascaraTelefone = value => {
    return value.replace(/\D/g, "");
  }
  
export const mascaraCartaoDeCredito=(value)=>{
    return value
        .replace(/\D/g, '')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
}


// validações de formulário

export const validaNomeCompleto=(nome_completo)=>{
    if(nome_completo.length<10){
        return 'O nome completo deve ter no mínimo 10 caracteres'
    }
    return ''
}
export const validaDataNascimento=(dataNascimento)=>{
    if(dataNascimento.length<10){
        return 'A data de nascimento deve ter no mínimo 10 caracteres'
    }
    return ''
}
export const validaTermos=(termos)=>{
    if(termos===false){
        return 'Você deve aceitar os termos de uso'
    }
    return ''
}
export const validaSexo=(value)=>{
    if(value===''){
        return 'Escolha uma opção'
    }
    return ''
}
export const validaCEP=(cep)=>{
    if(cep.length<9){
        return 'O CEP deve ter no mínimo 9 caracteres'
    }
    return ''
}
export const validaRua=(rua)=>{
    if(rua.length<3){
        return 'A rua deve ter no mínimo 3 caracteres'
    }
    return ''
}
export const validaNumero=(numero)=>{
    if(numero.length<1){
        return 'O número deve ter no mínimo 1 caracteres'
    }
    return ''
}
export const validaBairro=(bairro)=>{
    if(bairro.length<3){
        return 'O bairro deve ter no mínimo 3 caracteres'
    }
    return ''
}
export const validaCidade=(cidade)=>{
    if(cidade.length<3){
        return 'A cidade deve ter no mínimo 3 caracteres'
    }
    return ''
}
export const validaEstado=(value)=>{
    if(value===''){
        return 'Escolha uma opção'
    }
    return ''
}
export const validaComplemento=(complemento)=>{
    if(complemento.length<3){
        return 'O complemento deve ter no mínimo 3 caracteres'
    }
    return ''
}
export const validaPais=(pais)=>{
    if(pais.length<3){
        return 'O país deve ter no mínimo 3 caracteres'
    }
    return ''
}
export const validaTelefone=(telefone)=>{
    if(telefone.length<13){
        return 'O telefone deve ter no mínimo 13 caracteres'
    }
    return ''
}
export const validaCPF=(cpf)=>{
    if(cpf.length<14){
        return 'O CPF deve ter no mínimo 14 caracteres'
    }
    return ''
}
export const validaCNPJ=(cnpj)=>{
    if(cnpj.length<18){
        return 'O CNPJ deve ter no mínimo 18 caracteres'
    }
    return ''
}

export const validaEmail=(email)=>{
    if(email.length<10){
        return 'O email deve ter no mínimo 10 caracteres'
    }
    return ''
}
export const validaSenha=(senha)=>{
    if(senha.length<6){
        return 'A senha deve ter no mínimo 6 caracteres'
    }
    return ''
}