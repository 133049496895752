import styled from "styled-components"
import meta from "assets/img/meta.png"
export const BannerMeta=()=>{
    return<>
        <Recipiente>
            <H1>Integrado à todas as ferramentas do Meta business!</H1>
            <Img src={meta} alt="" />
        </Recipiente>
    </>
}
const Img=styled.img`
    width: 7rem;
    height: 7rem;
`
const Recipiente = styled.div`
    height: .1rem;
    background-color:  whitesmoke;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5rem 24% 5rem 24%;
`
const H1=styled.h1`
    color:#3f3f3f;
    font-size:1.4rem;
    text-align:center;
    text-transform:uppercase;
    font-weight: 700;
    letter-spacing: .1rem;
`