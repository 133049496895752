import { Logo } from "componentes/global/Logo"
import styled from "styled-components"
import { Label } from "ui/ComponentesDeEstilo"
export const TopoGenesi = () => {
    return<>
        <Centro>
            <Logo width="9rem"/>
            <Titulo>daLvz</Titulo>
            <Label>A era da inteligência</Label>
            <Paragrafo>Aplicações CMS com AI, multiplataforma</Paragrafo>
        </Centro>
    </>
}
const Titulo = styled.h1`
    color: #3f3f3f;
    margin-bottom:.5em;
    margin-top:.5em;
    font-size: 500%;
    font-weight: 500;
`
const Paragrafo = styled.p`
    font-size: 1.2rem;
    text-align: center;
    font-weight: 300;
    color: #3f3f3f;
    text-transform: uppercase;
    padding-bottom: 3em;
`
const Centro= styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
`