import { OniChamar } from "componentes/global/oni-chat/OniChamar"
import styled from "styled-components"
const Card=(props)=>{
    return<>
        <RecipienteCard>
            <OniChamar width={'1rem'}/>
            <Nome>{props.nome}</Nome>
            <Descricao>{props.descricao}</Descricao>
            <Preco>{props.preco}</Preco>
            <Descricao>{props.subDesc}</Descricao>
            <Btn>{props.btn}</Btn>
            <Paragrafo>{props.gigas}</Paragrafo>
            <Paragrafo>{props.opcao01}</Paragrafo>
            <Paragrafo>{props.opcao03}</Paragrafo>
            <Paragrafo>{props.megas}</Paragrafo>
            <Paragrafo>{props.opcao02}</Paragrafo>
            <Paragrafo>{props.opcao04}</Paragrafo>
            <Paragrafo>{props.opcao05}</Paragrafo>
            <Paragrafo>{props.opcao06}</Paragrafo>
            <Paragrafo>{props.opcao07}</Paragrafo>
            <Paragrafo>{props.opcao08}</Paragrafo>
            <Paragrafo>{props.opcao09}</Paragrafo>
            <Paragrafo>{props.opcao10}</Paragrafo>
        </RecipienteCard>

    </>
}
export const CardsPlanos=()=>{
    return<>
        <Recipiente>
            <Card 
                nome={'Inicial'} 
                descricao="Crie seu blog pessoa ou o seu site. São várias opções para você escolher!"
                preco="R$ 54/mês"
                subDesc="R$ 162 cobrados trimestralmente"
                btn="Contratar"
                gigas="5GB de armazenamento"
                megas="Servidor de 16 megas de ram"
                opcao01="1 site + App adminstrativo"
                opcao02=""
                opcao03="1 domínio grátis"
                opcao04="Certificado SSL grátis"
                opcao05="Criador de sites grátis"
                opcao06="Painel de controle cPanel"
                opcao07="Suporte 24/7"
                opcao08="Instalador de aplicativos"
                opcao09="30 dias de garantia"
                opcao10="Tráfego ilimitado"
            />
            <Card
                nome={'Intermediário'}
                descricao="Crie seu blog pessoa ou o seu site. São várias opções para você escolher!"
                preco="R$ 106/mês"
                subDesc="R$ 320 cobrados trimestralmente"
                btn="Contratar"
                gigas="15GB de armazenamento"
                megas="Servidor de 16 megas de ram"
                opcao01="1 site + App adminstrativo Android e vendas online"
                opcao02="Gerenciador de redes sociais integrado"
                opcao03="1 domínio grátis"
                opcao04="Certificado SSL grátis"
                opcao05="Criador de sites grátis"
                opcao06="Painel de controle cPanel"
                opcao07="Suporte 24/7"
                opcao08="Instalador de aplicativos"
                opcao09="30 dias de garantia"
                opcao10="Tráfego ilimitado"
            />
            <Card
                nome={'Avançado'}
                descricao="Crie seu blog pessoa ou o seu site. São várias opções para você escolher!"
                preco="R$ 126/mês"
                subDesc="R$ 378 cobrados trimestralmente"
                btn="Contratar"
                gigas="20GB de armazenamento"
                megas="Servidor de 16 megas de ram"
                opcao01="1 site + App adminstrativo Multiplataforma e vendas online"
                opcao02="5 banco MySQL"
                opcao03="1 domínio grátis"
                opcao04="Certificado SSL grátis"
                opcao05="Criador de sites grátis"
                opcao06="Painel de controle cPanel"
                opcao07="Suporte 24/7"
                opcao08="Instalador de aplicativos"
                opcao09="30 dias de garantia"
                opcao10="Tráfego ilimitado"
            />
        </Recipiente>
    </>
}
const RecipienteCard=styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    width: 300px;
    height: 700px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    line-height: 1.7rem;
    margin: 0 1rem;
    font-weight: 400;
`
const Recipiente=styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 10rem 0 10rem 0;
    background-color: #ffffffca;
    @media(max-width: 768px){
        flex-direction: column;
    }
`
const Nome=styled.p`
    font-size: 2rem;
    font-weight: 400;
    color: #3f3f3f;
    text-align: center;
    margin-bottom: 2rem;

`
const Descricao=styled.p`
    font-size: .9rem;
    color: #3f3f3f;
    text-align: center;
`
const Preco=styled.p`
    font-size: 1.5rem;
    font-weight: 400;
    color: #3f3f3f;
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 1rem;
`
const Paragrafo=styled.p`
    font-size: .9rem;
    color: #3f3f3f;
    text-align: center;
`
const Btn=styled.button`
    width: 100%;
    margin: 10px 0;
    padding: .9rem;
    background-color: #3f3f3f;
    color: #fff;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .2s ease-in-out;
    &:hover{
        background-color: #fff;
        color: #3f3f3f;
        border: 1px solid #3f3f3f;
    }
`

