import { useEffect, useState } from "react"
import { ProdutoController } from "../controllers/ProdutoController"
import { Cabebalho } from "../cabecalho/Cabecalho"
import { Menu } from "../menu/Menu"
import styled from "styled-components"
import { pegarParametrosDaURL } from "uteis/funcoes"
export const ListarProdutos = () => {
    const [produtos, setProdutos] = useState([])
    const[ID,setID]=useState([])
    const produtoController = new ProdutoController()
    useEffect(() => {
        const url=window.location.href
        const id=pegarParametrosDaURL(url)
        setID(id)
      }, [])
    useEffect(() => {
        produtoController.listarProdutosPorCategoriaID(ID, setProdutos)
    }, [ID]) 
    const handleClick = (id) => {
        window.location.href = `/produto?id=${id}`
    }
    return<>
        <Cabebalho/>
        <Menu/>
        <RecipienteLocal>
            {produtos.map((produto) => (
                <Card onClick={()=>handleClick(produto.id)}>
                    <Img src={produto.url_foto} key={produto.id}/>
                    <Paragrafo>{produto.descricao}</Paragrafo>
                    <Paragrafo>R$ {produto.preco}</Paragrafo>
                </Card>
            ))}
        </RecipienteLocal>
    </>
}
const Paragrafo = styled.p`
    font-size: .8rem;
    text-align: center;
    margin-top: .5rem;
    color: black;
`
const RecipienteLocal = styled.div`
  padding: 1rem 20% 1rem 20%;
  color: white;
  background-color: ghostwhite;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @media (max-width: 1756px) {
    padding: 1rem 5% 1rem 5%;
  }
  @media(max-width: 1172px) {
    padding: 1rem 1% 1rem 1%;
  }
`
const Img = styled.img`
    width: 100%;
    height: 80%;
    object-fit: cover;
`
const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 7rem;
    height: 10rem;
    margin: 1rem;
    padding: .7rem;
    border-radius: 1rem;
    background-color: white;
    box-shadow: 0 0 .3rem rgba(0,0,0,.2);
    transition: all .3s;
    &:hover {
        transform: scale(1.3);
    }
`
