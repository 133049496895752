import styled from "styled-components"

export const Propaganda=()=>{
    return<>
        <Div>
        </Div>
    </>
}
const Div=styled.div`
    background-color: whitesmoke;
    padding: 3em;
    margin-bottom: 1.1em;
    border-radius: .5rem;
`