import { useEffect, useState } from "react";
import styled from "styled-components";
import { listarCategoriasPorSistema } from "../Controller";
import { ListarUmaCategoria } from "./ListarUmaCategoria";
import { Carregando } from "componentes/global/Carregando";
import { useData } from "contexts/DataContext";
export const ListarCategorias = (props) => {
    const[categorias, setCategorias] = useState([]);
    const[categoriaSelecionada, setCategoriaSelecionada] = useState(null);
    const[carregar, setCarregar] = useState(false)
    const[mostraCards,setMostraCards]=useState(true)
    const{data,setData}=useData('')
    const sistemaProps=props.sistema
    const [categoriaId,setCategoriaId]=useState()

    useEffect(() => {
      listarCategoriasPorSistema(6, setCategorias,sistemaProps);
    }, []);
    const handleExibirUmaCategoria = (categoria_id) => {
        localStorage.setItem('categoriaID',categoria_id)
        setCategoriaId(categoria_id)
      setCarregar(true)
      setCategoriaSelecionada(categoria_id)
      setCarregar(false)
      setMostraCards(false)
    };
    return (
      <Recipiente>
        <Flex>
          {mostraCards&&<FlexColuna>
            {Array.isArray(categorias) &&
              categorias.map((categoria, index) => (
                <Divisao key={index}>
                  <Figure>
                    <Imagem
                      src={categoria.url_arquivo}
                      alt={categoria.titulo}
                      onClick={() => handleExibirUmaCategoria(categoria.id)}
                    />
                    <FigCaption>
                      <Titulo>{categoria.titulo}</Titulo>
                    </FigCaption>
                  </Figure>
                </Divisao>
              ))}
          </FlexColuna>}
          {carregar && <Carregando tamanho="p" mensagem="carregando..." />}
        </Flex>
        {categoriaSelecionada !== null && (
          <ListarUmaCategoria id={categoriaSelecionada} />
        )}
      </Recipiente>
    );
  };
const Flex= styled.div`
    display: flex;
    flex-direction: column;
`
const FlexColuna= styled.div`
    display: flex;
    flex-direction: row;
`
const Titulo = styled.h1`
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    color: #3f3f3f;
`
const Divisao = styled.div`
    border-bottom: 1px solid #ccc;
    border-radius: .5rem;
    background-color: white;
    box-shadow: 0 0 .1rem rgba(0,0,0,.2);
    border-radius: 1em;
    margin: .5em;
    width: 10.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
`;

const Recipiente = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10em 25% 10em 25%;
    flex-wrap: wrap;
`;

const Figure = styled.figure`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    `;

const Imagem = styled.img`
    width: 95%;
    height: 11vh;
    object-fit: cover;
    margin: 0 auto;
    border-radius: .2rem;
`;

const FigCaption = styled.figcaption`
    text-align: center;
    position: relative;
    padding: 1em 1rem 0 1rem;
`;

