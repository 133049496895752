import { MenuLateralSistema } from "componentes/painel-de-controle/sistema/MenuLateralSistema"
import { TopoSistema } from "componentes/painel-de-controle/sistema/TopoSistema"
import { ChatOni } from "componentes/painel-de-controle/sistema/onisciente/ChatOni"

export const Onisciente=()=>{
    return<>
        <TopoSistema/>
        <MenuLateralSistema/>
        <ChatOni/>
    </>
}