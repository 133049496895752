import {OniExemplo} from "./OniExemplo";
import styled from 'styled-components'
import {BotaoDestaque} from "../../ui/ComponentesDeEstilo";
export const DobraOni=()=>{
    const handleProximo=()=>{
        window.location.href='/landing-page#loja'
    }
    return<>
        <div id={'oni'}></div>
        <Recipiente>
            <AreaTexto>
                <Titulo>Onisciente, Inteligência artificial</Titulo>
                <Paragrafo>
                    Agendamentos e consultas por vídeo chamada em seu site proporcionam uma maneira conveniente e acessível para conectar-se com seus clientes. Oferecemos uma solução integrada que permite aos usuários agendar facilmente suas consultas online, proporcionando uma experiência personalizada e eficiente. A plataforma de vídeo chamada garante interações em tempo real, proporcionando a flexibilidade de receber consultas e realizar reuniões virtuais, promovendo um atendimento de qualidade sem a necessidade de deslocamento. Simplifique o processo de agendamento e proporcione um serviço excepcional aos seus clientes, tudo isso diretamente através do seu site.
                </Paragrafo>
                <Botao onClick={handleProximo}>Prosseguir...</Botao>
            </AreaTexto>
            <Oni>
                <OniExemplo/>
            </Oni>
        </Recipiente>
    </>
}
const Botao = styled(BotaoDestaque)`
    color: black;
    padding: 1em 3em;
    margin-top: 2em;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 215, 0, 0.89);
`;
const Oni=styled.div`
    margin-top: 10em;
`
const Recipiente=styled.div`
    padding: 7em 11em 7em 11em; 
    background-color: whitesmoke;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    box-shadow: 1px 10px 67px 33px rgba(3, 3, 3, 0.82);
    
`
const AreaTexto=styled.div`
    
`
const Titulo=styled.h1`
    text-align: left;
    font-weight: 300;
    font-size: 300%;
`
const Paragrafo=styled.p`
    width: 650px;
    margin-top: 2em;
    line-height: 1.5em;
    font-size: 120%;
    margin-bottom: 2em;
`