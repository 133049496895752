import styled from "styled-components";
import {ElementoCentralizado, Input, Select, Label, BotaoPrincipal} from "../../../../ui/ComponentesDeEstilo";
import {useEffect, useState} from "react";
import {listarSubcategoriasPorSistema} from "../Controller";
import {criptografar, decriptografar} from "../../../../uteis/crypto";
import {enviarEmailDeConvite, enviarEmailDeRecuperacaoDeSenha} from "../../../../paginas/Genesi/Controllers";
import {dadosDoUsuario} from "../../../../uteis/gerenciarSessao";
import {gerarIdCustomizado} from "../../../../uteis/funcoes";

export const Convidar=()=>{
    const[subcategorias,setSubcategorias]=useState([])
    const[nome,setNome]=useState('')
    const[email,setEmail]=useState('')
    const[subCategoriaSelecionada,setSubcategoriaSelecionada]=useState('')
    const[titulo,setTitulo]=useState()
    const[urlArquivo,setUrlArquivo]=useState('')
    useEffect(()=>{
        listarSubcategoriasPorSistema(setSubcategorias)
    },[])

    return<>
        <Recipiente>
            <form
                onSubmit={(event)=>{
                    event.preventDefault()
                    const id_customizado=gerarIdCustomizado(8)
                    const criptografado= {
                        'id_customizado':id_customizado,
                        'nome':nome,
                        'email':email,
                        'subcategoria':subCategoriaSelecionada,
                        'convidado_por':dadosDoUsuario().id
                    }
                    const token=criptografar(criptografado)
                    enviarEmailDeConvite(id_customizado,email,nome,subCategoriaSelecionada,token)
                }}
            >
                <h1>Enviar convite</h1>
                <div>
                    <Label>Nome</Label>
                    <Input
                        onChange={(event)=>{
                            const nome=event.target.value
                            setNome(nome)
                        }}
                        placeholder={'Nome do convidado'}
                    />
                </div>
                <div>
                    <Label>E-mail</Label>
                    <Input
                        placeholder={'E-mail do convidado'}
                        onChange={(event)=>{
                            const email=event.target.value
                            setEmail(email)
                        }}
                    />
                </div>
                <div>
                    <Label>Aplicação</Label>
                    <Select
                        onChange={(event)=>{
                            const subcategoria=event.target.value
                            setSubcategoriaSelecionada(subcategoria)
                        }}
                    >
                        <option value="">Vincular aplicação ao convite</option>
                        {
                            subcategorias.map((subcategoria, index) => (
                                <option key={index} value={subcategoria.titulo}>{subcategoria.titulo}</option>
                            ))
                        }
                    </Select>
                </div>
                <div>
                    <BotaoPrincipal>Convidar</BotaoPrincipal>
                </div>
            </form>
        </Recipiente>
    </>
}
const Recipiente = styled(ElementoCentralizado)`
    background-color: whitesmoke;
`
