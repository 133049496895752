import imagem from '../assets/imagens/whatsapp.png'
import styled from "styled-components";
export const WhatsApp=()=>{
    const handleLinkWhatsApp=()=>{
        window.open('https://api.whatsapp.com/send?phone=553193270479&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20o%20Cereja%20de%20Maio.')
    }
    return<>
        <Img onClick={handleLinkWhatsApp} src={imagem}/>
    </>
}
const Img=styled.img`
  position: fixed;
  bottom: 1.3rem;
  right: 1.3rem;
  width: 4.3rem;
  z-index: 3;
  cursor: pointer;
`