import { useEffect, useState } from "react";
import { listarConvidados } from "../Controller";
import styled from "styled-components";
import {Linque, Recipiente, Textarea} from "../../../../ui/ComponentesDeEstilo";

export const ListarConvidados = () => {
    const [convidado, setConvidado] = useState([]);
    const [mostrarToken,setMostrarToken]=useState(false)
    const [copiado, setCopiado] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const convite = await listarConvidados();
                setConvidado(convite);
            } catch (erro) {
                console.error("Erro ao buscar convidados:", erro);
            }
        };

        fetchData();
    }, []);
    const handleMostrarToken = () => {
        setMostrarToken((estadoAtual) => !estadoAtual);
    };
    const handleCopyToClipboard = (token) => {
        navigator.clipboard.writeText(token)
            .then(() => setCopiado(true))
            .catch((error) => console.error('Erro ao copiar para a área de transferência:', error));
    };
    return (
        <RecipienteEstilizado>
            <>
                {convidado.map((usuario) => (

                    <Card key={usuario.id}>
                        <Paragrafo>{usuario.nome}</Paragrafo>
                        <Paragrafo onClick={handleMostrarToken}>
                            {usuario.status === 1 ? "Convidado" : "Ativar aplicação"}
                        </Paragrafo>
                        {mostrarToken && (
                            <TextArea onClick={() => handleCopyToClipboard(usuario.token)}>
                                {copiado ? "Copiado para a área de transferência!" : usuario.token}
                            </TextArea>
                        )}
                    </Card>
                ))}
            </>
        </RecipienteEstilizado>
    );
};
const TextArea=styled(Textarea)`
    width: 50rem;
`
const RecipienteEstilizado=styled(Recipiente)`
    padding: 10em 25% 0 25%;
    display:flex;
    align-items:center;
    height: 100%;
`
const Card=styled(Linque)`
    background-color: whitesmoke;
    line-height: 1.2rem;
    padding:1rem;
    border-radius:.2rem;
    margin: 1rem;
`
const Paragrafo=styled.p`
    color:#3f3f3f;
`
const Permissao=styled.p`
    text-transform:capitalize;
    font-size:.8rem;
    color:#3f3f3f;
`