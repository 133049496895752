import React, { useEffect, useState } from "react";
import { checarDominio } from "../../paginas/landing-pages/controller";
import {BotaoDestaque, BotaoPrincipal, ElementoCentralizado, Input, Linque} from "../../ui/ComponentesDeEstilo";
import {IconeCircularLupa} from "../../ui/Icones";
import styled from "styled-components";

export const ConsultarDominio = () => {
    const [dominio, setDominio] = useState("www.");
    const [mostrarResposta, setMostrarResposta] = useState(false);
    const [resposta, setResposta] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const resposta = await checarDominio(dominio);
        setResposta(resposta);
        if(resposta==='dominio disponível para registro'){
            localStorage.setItem('dominio',dominio)
        }
        setMostrarResposta(true);
    };

    return<>
            <Recipiente>
                <Titulo>
                    Para começar, procure seu domínio no campo abaixo...
                </Titulo>
                    <Formulario>
                        <Inputs
                            type="text"
                            value={dominio}
                            onChange={(event) => {
                                setDominio(event.target.value);
                                setMostrarResposta(false); // Resetar a exibição da resposta ao alterar o domínio
                            }}
                            placeholder={"...www.seunome.com.br"}
                        />
                        <Botao onClick={handleSubmit} type="submit"><IconeCircularLupa/></Botao>
                    </Formulario>

                    {mostrarResposta && resposta && (
                        <Pre>
                            <pre>{JSON.stringify(resposta, null, 2)}</pre>
                        </Pre>
                    )}
            </Recipiente>
        </>
}
const Botao=styled.button`
    background-color: transparent;
    font-size: 200%;
    border:none;
    position: relative;
    margin-left: -1.5em;
    margin-top: -.1em;
    z-index: 2;
    color: black;
`
const Pre=styled.pre`
    background-color: lime;
    padding: 1em;
    border-radius: .5em;
    margin-top: .3em;
    width: 20rem;
    text-align: center;
`
const Recipiente=styled.div`
    width: 78%;
    margin:0 auto;
`
const Formulario=styled.form`
    display: flex;
`
const Titulo=styled.h2`
    text-align: left;
    font-weight: 300;
    color: white;
    font-size: 300%;
    margin-top: 4.8em;
    margin-bottom: 1em;
`
const Inputs=styled(Input)`
    width: 100%;
`
