import { useEffect } from "react"
import { useState } from "react"
import { IconeLixeira } from "ui/Icones"
import { CarrinhoDeComprasController } from '../controllers/CarrinhoDeComprasController';
import { Cabecalho } from "../carriho-de-compras/Cabecalho"
import styled from "styled-components"
import { BotaoPrincipal } from "ui/ComponentesDeEstilo";
export const Carrinho = () => {
    const [carrinhoDeCompras,setCarrinhoDeCompras]=useState([])
    const [somaPrecos, setSomaPrecos] = useState(0);
    useEffect(() => {
        const carrinhoDeComprasController = new CarrinhoDeComprasController();
        carrinhoDeComprasController.listarCarrinhoDeCompras(setCarrinhoDeCompras);
      }, []);
      useEffect(() => {
        const carrinhoDeComprasController = new CarrinhoDeComprasController();
        carrinhoDeComprasController.somarPrecosItens(
          carrinhoDeCompras,
          setSomaPrecos
        );
      }, [carrinhoDeCompras]);
      const handleRemoverItem = (id,tamanho) => {
        const carrinhoDeComprasController = new CarrinhoDeComprasController();
        carrinhoDeComprasController.deletarItemCarrinhoDeCompras(id);
        carrinhoDeComprasController.removerItemCarrinhoDeComprasBanco(tamanho);
        // Atualize a lista de itens do carrinho de compras após excluir o item
        carrinhoDeComprasController.listarCarrinhoDeCompras((novosItens) => {
          setCarrinhoDeCompras(novosItens);
      
          // Atualize a soma dos preços com a nova lista de itens
          carrinhoDeComprasController.somarPrecosItens(novosItens, setSomaPrecos); // Atualizamos para `novosItens` ao invés de `carrinhoDeCompras`
        });
      };
    const handleFinalizarCompra = () => {
        const UsuarioID=localStorage.getItem("UsuarioID")
        if(!UsuarioID){
            localStorage.setItem("valor",somaPrecos)
            const carrinhoDeComprasController = new CarrinhoDeComprasController()
            carrinhoDeComprasController.atualizarEstadoCarrinho()
            window.location.replace("/identificacao");
        }else{
            setTimeout(() => {
                const carrinhoDeComprasController = new CarrinhoDeComprasController()
                carrinhoDeComprasController.atualizarEstadoCarrinho()
                localStorage.setItem("valor",somaPrecos)
                window.location.replace("/pagamentos");
            }, 2000);
        }
    }
    return (
      <>
        <Cabecalho/>
        <ParagrafoContador>Meu carrinho</ParagrafoContador>
        <RecipienteLocal>
          <FlexColumna>
            {carrinhoDeCompras.map((item) => (
              <FlexLinha key={item.item.id}>
                <Separador>
                  <Foto src={item.item.url_foto} />
                </Separador>
                <Separador>
                  <H1Local>
                    {item.item.categoria_nome} {item.item.subcategoria_nome}
                  </H1Local>
                  <Tamanho>Tamanho: {item.tamanho}</Tamanho>
                  {item.cor && (
                    <ParagrafoItens>Cor: {item.cor.nome}</ParagrafoItens>
                    )}
                  <ParagrafoItens>Quantidade: {item.quantidade}</ParagrafoItens>
                  <ParagrafoItens>Valor: R$ {parseFloat(item.item.preco) * parseInt(item.quantidade)}</ParagrafoItens>
                  <ParagrafoLixeira onClick={() => handleRemoverItem(item.item.id,item.tamanho)}>
                    <IconeLixeira />
                  </ParagrafoLixeira>
                </Separador>
              </FlexLinha>
            ))}
          </FlexColumna>
          <FinalizarCompra>
            <Soma>Subtotal: {somaPrecos}</Soma>
            <BotaoPrincipalLocal onClick={handleFinalizarCompra}>
              Finalizar compra
            </BotaoPrincipalLocal>
          </FinalizarCompra>
        </RecipienteLocal>
      </>
    );
}
const Separador=styled.div`
`
const ParagrafoLixeira=styled.p`
    color:gray;
    cursor:pointer;
    font-size:1.2rem;
    text-align: left;
    @media(max-width: 923px) {
        right:-9rem;
    }
    @media(max-width: 911px) {
        right: 0;
        top: 0;
        left: -5rem;
    }
`
const Tamanho=styled.p`
    font-size:.9rem;
    color:black;
    text-transform: uppercase;
    margin-bottom: .5rem;
`
const H1Local=styled.h1`
    text-align:left;
    color:black;
    font-size:1rem;
    text-transform: uppercase;
    font-weight: lighter;
    margin-bottom: 1rem;
`
const RecipienteLocal=styled.div`
    display:flex;
    justify-content:center;
    align-items:flex-start;
    width:80%;
    margin:0 auto;
    @media(max-width: 923px) {
        flex-direction:column;
        align-items:center;
        width:100%;
    }
`
const FlexColumna=styled.div`
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
`
const FlexLinha=styled.div`
    display:flex;
    flex-direction:row;
    background-color: ghostwhite;
    flex-wrap:wrap;
    padding:1rem;
    margin:0 1rem 1rem;
    align-items:flex-start;
    width:30rem;
    @media(max-width: 923px) {
        width:100%;
        padding:.4rem;
        margin:0;
        align-items:center;
    }
`
const FinalizarCompra=styled.div`
    background-color: ghostwhite;
    width:20rem;
    text-align:center;
    @media(max-width: 923px) {
        width:100%;
    }
`
const BotaoPrincipalLocal=styled(BotaoPrincipal)`
    color:white;
    margin: 1rem .6rem;
    width: 18rem;
    @media(max-width: 357px) {
        width: 15rem;
        margin:0;
    }
`
const Soma=styled.p`
    font-size:1rem;
    color:black;
    margin:1rem;
    text-transform: uppercase;
`
const ParagrafoItens = styled.p`
    font-size:.8rem;
    color:black;
    cursor:pointer;
    line-height:1.5rem;
    text-align:left;
    text-transform: uppercase;
`
const Foto = styled.img`
    width:8rem;
    margin:0 1rem 0 0;
    @media(max-width: 360px) {
        width:100%;
        margin:0;
    }
`
const ParagrafoContador = styled.p`
    text-align:left;
    font-size:2rem;
    font-weight:bold;
    color:black;
    margin:1rem;
    width:80%;
    margin:0 auto;
    padding-bottom: 2rem;
    border-bottom: 1px solid lightgray;
    margin-bottom: 3rem;
    margin-top: 2rem;
    @media(max-width: 923px) {
        width:100%;
        text-align:center;
    }
`

