import {Container, Paragrafo, SubTitulo, Titulo} from "./css";
import logoPix from "ui/logo-pix-icone.png";
import styled from "styled-components";
import { QRCodeCanvas } from "qrcode.react";
import { useEffect,useState } from "react";
import { CobrancasAsaas } from './controllers/Cobrancas';
import { CompraController } from '../controllers/CompraController';
import { dataDeHojeFormatada } from "uteis/funcoes";
import { BotaoPrincipal } from "ui/ComponentesDeEstilo";

export const Pix=()=>{
    const[cobranca,setCobranca]=useState({})
    const[qrCode,setQrCode]=useState("")
    const[mostrarQrCode,setMostrarQrCode]=useState(false)
    const[mostraBotao,setMostraBotao]=useState(true)
    const data=dataDeHojeFormatada()
    useEffect(()=>{
        if(cobranca.id){
        const cobrancaPix=new CobrancasAsaas()
        cobrancaPix.obterQRCodParaPagamentos(cobranca.id,setQrCode)
        const compraController=new CompraController()
        compraController.cadastrarCompra(cobranca.id)
        setMostrarQrCode(true)
        setMostraBotao(false)
        }
    },[cobranca.id])
    const handleGerarCobranca=()=>{
        const valor1=localStorage.getItem("valor")
        const valor2=localStorage.getItem("Total")
        if(valor1!==valor2){
            const dados={
                customer: localStorage.getItem("id_customizado"),
                billingType: "PIX",
                value: localStorage.getItem("Total"),
                dueDate: data,
                description: "Pagamento Pix",
                externalReference: localStorage.getItem("referencia"),
                discount:{
                    value: 0,
                    dueDateLimitDays: 0
                },
                fine:{
                    value: 0
                },
                interest:{
                    value: 0
                }
            }
            const cobrancasAsaas=new CobrancasAsaas()
            cobrancasAsaas.criarNovaCobranca(dados,setCobranca)
            setTimeout(()=>{
                localStorage.removeItem("carrinho")
                localStorage.removeItem("Total")
                localStorage.removeItem("valor")
            },2000)
        }else{
            alert("Insira o CEP")
        }
        
    }
    return<>
        <Container>
            <Titulo> <Logo src={logoPix} alt=""/> Pagar com PIX</Titulo>
            <SubTitulo>Aprovação em minutos</SubTitulo>
            <Paragrafo>
                1 - Após a finalização do pedido, abra o app ou banco de sua preferência. Escolha a opção pagar com código Pix “copia e cola”, ou código QR. O código tem validade de 13 horas.
            <Paragrafo>
                2 - Copie e cole o código, ou escaneie o código Qr com a câmera do seu celular. Confira todas as informações e autorize o pagamento.
            </Paragrafo>
            <Paragrafo>
                3 - Você vai receber a confirmação de pagamento no seu e-mail e através dos nossos canais. E pronto!
            </Paragrafo>
            </Paragrafo>
            {mostrarQrCode && <QRCodeCanvas value={qrCode} size={200}/>}
            {mostraBotao&&<Botao onClick={handleGerarCobranca}>Finalizar pagamento com o PIX</Botao>}
        </Container>
    </>
}
const Botao=styled(BotaoPrincipal)`
`
const Logo=styled.img`
  width: 1.8rem;
`