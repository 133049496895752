import { Logo } from "componentes/global/Logo"
import { Card } from "./Card"
import styled from "styled-components"
import { Propaganda } from "./Propaganda"
import { Lista } from "./Lista"
import { RodaPe } from "paginas/Genesi/RodaPe"
import { SubTopo } from "./SubTopo"
import { Propaganda2 } from "./Propaganda2"
export const Blog=()=>{
    return<>
        <SubTopo/>
        <Topo>
            <Logo width="3rem" alt="Logo da empresa da Luz" url="/"/>
        </Topo>
        <Recipiente>
            <FlexLinha>
                <Texto>
                    <Div>
                        <H1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde aliquam omnis commodi sequi quidem repellendus inventore.</H1>
                        <Nome>postado por francis vagner em 08-09-2023</Nome>
                    </Div>
                </Texto>
                <Img src="https://picsum.photos/202/300" alt="" />
            </FlexLinha>
            <Propaganda/>
            <FlexLinha>
                <div>
                    <Card/>
                    <Card/>
                    <Card/>
                    <Card/>
                </div>
                <div>
                    <Iframe src="https://br.widgets.investing.com/top-cryptocurrencies?theme=darkTheme&cols=priceUsd" width="100%" height="100%" frameborder="0" allowtransparency="true" marginwidth="0" marginheight="0"></Iframe>
                    <Lista/>
                    <Propaganda2/>
                </div>
            </FlexLinha>
            <Propaganda/>
        </Recipiente>
        <RodaPe/>
    </>
}
const Div=styled.div`
    padding: 1em;
`
const Nome=styled.p`
    margin-top: 1em;
    font-size:80%;
`
const Texto=styled.div`
    border: 1px solid #e4e4e4;
    width: 50.5%;
    display: flex;
    align-items: center;
    border-radius: .5em;
`
const Img=styled.img`
    width:48.5%;
    height: 40rem;
    border-radius: .5em;
    transition: 0.3s;
    &:hover{
        transform: scale(1.005);
        
    }
`
const FlexLinha=styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
`
const H1=styled.h1`
    
    color:#4f4f4f;
`
const Iframe=styled.iframe`
    width: 20rem;
    height: 30rem;
    border-radius: .5rem;
`
const Recipiente=styled.div`
    margin: 0 auto;
    width: 70%;
    margin-top: 10em;
    padding-bottom: 3em;
`
const Topo=styled.div`
    background-color: whitesmoke;
    border-bottom: 1px solid #e4e4e4;
    text-align: center;
    padding: 1em;
    position: fixed;
    width: 100%;
    top: 3em;
    z-index: 3;
`