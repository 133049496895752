import { Outlet } from "react-router-dom"
import { Cabebalho } from "./cabecalho/Cabecalho"
import { QuatroProdutos } from "./componentes/QuatroProdutos"
import { WhatsApp } from "./componentes/Whatsapp"
import { Carrossel } from "./dobras/Carrossel"
import { Garantias } from "./dobras/Garantias"
import { Menu } from "./menu/Menu"
import { RodaPe } from "./componentes/RodaPe"
export const Elingerie=()=>{
    return<>
        <Cabebalho/>
        <Menu/>
        <Carrossel/>
        <Garantias/>
        <QuatroProdutos/>
        <WhatsApp/>
        <RodaPe/>
        <Outlet />
    </>
}