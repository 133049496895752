import styled from "styled-components";

export const Container=styled.section`
  color: black;
  background-color: ghostwhite;
  padding: 3rem;
  margin: 1rem auto ;
  border-radius: .3rem;
  line-height: 2rem;
  border-bottom: .1rem solid lightgray;
`
export const Titulo=styled.h1`
  margin-bottom: 3rem;
`
export const SubTitulo=styled.p`
  
`
export const Paragrafo=styled.p`
  font-size: 100%;
`
export const Button=styled.button`
  background-color: darkorange;
  border: none;
  font-weight: bold;
  letter-spacing: .1rem;
  padding: 1rem;
  border-radius: .3rem;
  color: white;
  text-transform: uppercase;
  margin-top: 4rem;
  cursor: pointer;
`
export const Separador=styled.section`
`
export const Input=styled.input`
  height: 3rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: .3rem;
  border: .1rem solid ghostwhite;
  padding-left: .5rem;
  width: 20rem;
`
export const Select=styled.select`
  height: 3rem;
  border-radius: .3rem;
  border: .1rem solid ghostwhite;
  padding-left: .5rem;
  margin-bottom: 1rem;
`
