import React, { useState } from 'react';
import styled from 'styled-components';
import { Sistema } from "../../../config/sistema";
import { enviarArquivo } from './Controller';
import { Label } from 'ui/ComponentesDeEstilo';
import { IconeImagem, IconeVisto } from 'ui/Icones';
import { criptografar } from 'uteis/crypto';
import { Carregando } from 'componentes/global/Carregando';
import { useData } from 'contexts/DataContext';
export const Storage = ({ usuario_id, onFileUpload,foto_de_perfil}) => {
  const [file, setFile] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)
  const [mostraAnexar, setMostraAnexar] = useState(false)
  const [mostraAnexando, setMostraAnexando] = useState(false)
  const [mostraAnexado, setMostraAnexado] = useState(false)
  const {data,setData}=useData()
  const handleImageChange = (event) => {
    event.preventDefault();
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setPreviewImage(reader.result);
      if (onFileUpload) onFileUpload(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      setMostraAnexar(true);
    }
  };
  const handleEnviarArquivo = async (event) => {
    event.preventDefault();
    try {
      const response = await enviarArquivo(file, usuario_id,foto_de_perfil,Sistema)
      console.log(response)
      setTimeout(() => {
        setMostraAnexar(false)
        setMostraAnexando(true)
        setMostraAnexado(false)
      }, 1000)
      setTimeout(() => {
        const id=response.data.storage.id
        const url=response.data.storage.url_arquivo
        const storage=criptografar({id,url})
        localStorage.setItem("storage",storage)
        setData(true)
        setMostraAnexando(false);
        setMostraAnexado(true)
      }, 2000)
    } catch (error) {
      console.error(error)
    }
  };
  const handleResetarBotao = () => {
    setTimeout(() => {
    setMostraAnexar(true)
    }, 2000)
    setMostraAnexando(false)
    setMostraAnexado(false)
  }
  const handleReset=()=>{
    window.location.reload()
  }
  return <>
      <FormEnviarFoto onSubmit={handleEnviarArquivo}>
        {previewImage && <ImgSelecionada id="preview" alt="Preview" src={previewImage} />}
        <EscolherFoto onClick={handleResetarBotao} htmlFor="foto">Buscar Imagem do PC <IconeImagem/> </EscolherFoto>
        <Input type="file" name="foto" id="foto" onChange={handleImageChange} />
        {mostraAnexar && <Anexar>Anexar</Anexar>}
        {mostraAnexando && <Anexar>Anexando <Carregando tamanho="p"/></Anexar>}
        {mostraAnexado && <AnexarClaro>Anexado <IconeVisto/></AnexarClaro>}
      </FormEnviarFoto>
  </>
}
const Fechar=styled.p`
  cursor:pointer;
`
const Anexar=styled.button`
  background-color: #3f3f3f;
  color: whitesmoke;
  padding: .5rem;
  border: none;
  border-radius: .5rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
`
const AnexarClaro=styled.button`
  background-color: whitesmoke;
  color: #3f3f3f;
  padding: .5rem;
  border: none;
  border-radius: .5rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover{
      transform: scale(1.1);
  }
`
const EscolherFoto = styled(Label)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  color:#3f3f3f;
  padding: .5rem;
  border-radius: .5rem;
  width: 10rem;
  cursor: pointer;
`
const Input = styled.input`
  display: none;
`
const ImgSelecionada = styled.img`
  border-radius:.5em;
  width: 100%;
  object-fit: cover;
  object-position: center;
  transition: 0.5s;
`
const FormEnviarFoto = styled.form`
  text-align: center;
  margin-bottom: 1.5rem;
`