import { useState } from 'react'
import { Step1, Step2, Step3 } from './PassosDoCadastro'
import styled from 'styled-components'
import { Trilha } from './Trilha'
import { criarUsuario } from '../../paginas/Genesi/Controllers'
import { useValidacaoFormulario } from '../hooks/useValidacaoFormulario'
import { ElementoCentralizado } from '../../ui/ComponentesDeEstilo'
import { Logo } from 'componentes/global/Logo'
import { Alerta } from 'componentes/global/Alertas'
export const Cadastro = () => {
    const[mostraSucesso, setMostraSucesso] = useState(false)
    const [campos, setCampos] = useState({
        nome_completo: '',
        dataNascimento: '',
        sexo: '',
        cpf: '',
        telefone: '',
        cep: '',
        rua: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: '',
        pais: '',
        email: '',
        senha: '',
        termos: false
    });
    const [etapaAtual, setEtapaAtual] = useState(1)
    const { erros, validaCampos, formularioEhValido } = useValidacaoFormulario()
    const [erroCadastro, setErroCadastro] = useState('')
    const enviarParaAPI = async () => {
        console.log(campos);
        if (campos) {
            const resposta = await criarUsuario(campos, setErroCadastro);
            
            if (!resposta.sucesso) {
                setMostraSucesso(false);
                setErroCadastro(resposta.mensagem);
            } else {
                setMostraSucesso(true);
                
            }
        } else {
            console.error('Erro ao enviar dados!');
        }
    }
    const nextStep = () => {
        setEtapaAtual(etapaAtual + 1)
    }
    const previousStep = () => {
        setEtapaAtual(etapaAtual - 1)
    }
    const enviaFormulario = (evento) => {
        evento.preventDefault();
        if (formularioEhValido(campos)) {
            console.log("Formulário válido!")
        } else {
            console.log("Formulário inválido!", erros)
        }
    }
    return <>
            <Logo paddingTop="1rem" paddingBottom="2rem" position="absolute" left="1rem" alt="Logo da empresa da Luz" url="/"/>
            <ElementoCentralizadoEstilizado>
                <Formulario onSubmit={enviaFormulario}>
                    <Trilha etapaAtual={etapaAtual} />
                    {etapaAtual === 1 && 
                        <Step1 nextStep={nextStep} campos={campos} setCampos={setCampos} erros={erros} validaCampos={validaCampos} />
                    }
                    {etapaAtual === 2 &&
                        <Step2 previousStep={previousStep} nextStep={nextStep} campos={campos} setCampos={setCampos} erros={erros} validaCampos={validaCampos} />
                    }
                    {etapaAtual === 3 &&
                        <Step3 previousStep={previousStep} campos={campos} setCampos={setCampos} erros={erros} validaCampos={validaCampos} enviarParaAPI={enviarParaAPI} />
                    }
                </Formulario>
            </ElementoCentralizadoEstilizado>
            {mostraSucesso&&<Alerta tipo="sucesso" mensagem={`Um email de confirmação foi enviado para ${campos.email}!`}/>}
            {erroCadastro&&<Alerta tipo="informacao" mensagem={'Usuário já existe no sistema'}/>}
    </>
}
const ElementoCentralizadoEstilizado = styled(ElementoCentralizado)`
    top: 2em;
`
const Formulario = styled.form`
    display: flex;
    flex-direction: column;
`

