import { CompraSegura } from "../componentes/CompraSegura"
import { LogoCereja } from "../componentes/LogoCereja"
import styled from "styled-components"
export const Cabecalho = () => {
    const handleVoltar=()=>{
        window.location.href="/"
    }
    return<>
        <RecipienteLocal>
            <LogoCereja width={'4rem'}/>
            <CompraSegura/>
        </RecipienteLocal>
    </>
}
const RecipienteLocal=styled.p`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    padding:1rem;
    border-bottom:1px solid lightgray;
    margin:0 auto;
`
const ImagemLocal=styled.img`
    width:5rem;
    cursor:pointer;
    &:hover{
        opacity:.8;
    }
`