import { Container, Paragrafo, Titulo} from "./css";
import { BotaoPrincipal } from "ui/ComponentesDeEstilo";
import styled from "styled-components";
import { IconeBoletoBancario } from "ui/Icones";
import { CobrancasAsaas } from './controllers/Cobrancas';
import { useEffect } from "react";
import { useState } from "react";
import { CompraController } from "../controllers/CompraController";
import { dataDeHojeFormatada } from "uteis/funcoes";

export const BoletoBancario=()=>{
    const[cobranca,setCobranca]=useState({})
    const[abrirBoleto,setAbrirBoleto]=useState(false)
    const[abrirBotao,setAbrirBotao]=useState(true)
    const[url,setUrl]=useState("")
    console.log(url)
    console.log(cobranca)
    const data=dataDeHojeFormatada()
    useEffect(()=>{
        if(cobranca.id){
            console.log(cobranca.bankSlipUrl)
            setUrl(cobranca.bankSlipUrl)
        }
    },[cobranca.id])
    const handleGerarCobranca=()=>{
        const valor1=localStorage.getItem("valor")
        const valor2=localStorage.getItem("Total")
        if(valor1!==valor2){
        const dados={
            customer: localStorage.getItem("id_customizado"),
            billingType: "BOLETO",
            value: localStorage.getItem("Total"),
            dueDate: data,
            description: "Pagamento Boleto",
            externalReference: localStorage.getItem("referencia"),
            discount:{
                value: 0,
                dueDateLimitDays: 0
            },
            fine:{
                value: 0
            },
            interest:{
                value: 0
            }
        }
        const compraController=new CompraController()
        compraController.cadastrarCompra()
        const cobrancasAsaas=new CobrancasAsaas()
        cobrancasAsaas.criarNovaCobranca(dados,setCobranca)
        setAbrirBoleto(true)
        setAbrirBotao(false)
        setTimeout(()=>{
            localStorage.removeItem("carrinho")
            localStorage.removeItem("Total")
            localStorage.removeItem("valor")
        },2000)
        }else{
            alert("Insira o CEP")
        }
    }
    return<>
        <Container>
            <Titulo> <IconeBoletoBancario/> Pagar com boleto bancário</Titulo>
            <Paragrafo>Você poderá visualizar ou imprimir após a finalização do pedido. A data de vencimento é de 4 dias corridos após a conclusão do pedido. Após esta data, ele perderá a validade.</Paragrafo>
            {abrirBotao&&<Botao onClick={handleGerarCobranca}>Finalizar pagamento com boleto bancário</Botao>}<br />
            {abrirBoleto&&<a href={url} target="_blank">Visualizar boleto</a>}
        </Container>
    </>
}
const Botao=styled(BotaoPrincipal)`
`
