import { pegarParametrosDaURL } from "uteis/funcoes"
import { TopoSistema } from "../TopoSistema"
import { MenuLateralSistema } from "../MenuLateralSistema"
import { BotaoPrincipal, Input, Linque } from "ui/ComponentesDeEstilo"
import { useState,useEffect } from "react"
import { alterarDadosCategoriaPorUsuarioESistema, listarUmaCategoriaPorID } from "../Controller"
import styled from "styled-components"
import { IconeImagem} from "ui/Icones"
import { Carregando } from "componentes/global/Carregando"
import { Alerta } from "componentes/global/Alertas"
import { EditorDeTexto } from "componentes/global/EditorDeTexto"
import { GerenciarStorage } from "../GerenciarStorage"
import { decriptografar } from "uteis/crypto"
export const EditarCategoria = () => {
    const [umaCategoria, setUmaCategoria] = useState(null);
    const [titulo, setTitulo] = useState('');
    const [descricao, setDescricao] = useState('')
    const[mostrarEditarCategoria,setMostrarEditarCategoria]=useState(true)
    const[mostrarCarregar,setMostrarCarregar]=useState(false)
    const[mostraAlerta,setMostraAlerta]=useState(false)
    const[mostrarGerenciarStorage,setMostrarGerenciarStorage]=useState(false)
    const[arquivoLocal,setArquivoLocal]=useState()
    const url = window.location.href;
    const categoria = pegarParametrosDaURL(url);
    const id = categoria.categoria;

    useEffect(() => {
        listarUmaCategoriaPorID(id, (categoria) => {
            setUmaCategoria(categoria);
            setTitulo(categoria.titulo);
            setDescricao(categoria.descricao);
        });
    }, []);
    useEffect(()=>{
        const url_arquivo_local=localStorage.getItem('storage')
        setArquivoLocal(decriptografar(url_arquivo_local))

    })
    const handleImg=()=>{
        setMostrarGerenciarStorage(true)
    }
    if (!umaCategoria) {
        return <div>Carregando...</div>;
    }
    
    return (
        <>
            <TopoSistema />
            <MenuLateralSistema />
            
            <ElementoCentralizadoEstilizado>
                <Div>
                <Img src={umaCategoria.url_arquivo} alt="" />
                {mostrarGerenciarStorage&&<GerenciarStorage foto_de_perfil={false}/>}
                <AbrirGerenciador onClick={handleImg}>
                    <p>Enviar imagem </p>  
                    <ImagemIcone/>
                </AbrirGerenciador>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            const dados = {
                                'titulo': titulo,
                                'descricao': descricao,
                                'url_arquivo': arquivoLocal
                            };
                            alterarDadosCategoriaPorUsuarioESistema(umaCategoria.id, dados).then(res => {
                                setMostrarCarregar(true);
                                setMostrarEditarCategoria(false);
                                setMostraAlerta(false);
                                setTimeout(() => {
                                    setMostrarEditarCategoria(true);
                                    setMostrarCarregar(false);
                                }, 2000);
                                setTimeout(() => {
                                    setMostraAlerta(true);
                                    setTimeout(() => {
                                        setMostraAlerta(false);
                                    }, 2000);
                                }, 2000);
                            });
                            
                            
                        }}
                    >
                        <div>
                            <InputCustomizada
                                onChange={(e) => {
                                    setTitulo(e.target.value);
                                }}
                                type="text"
                                value={titulo}
                            />
                        </div>
                        <div>
                            <EditorDeTexto
                                aoAlterarConteudo={(novoConteudo) => {
                                    setDescricao(novoConteudo);
                                }}
                                initialValue={descricao} // Se você adicionar esta prop ao EditorDeTexto (veja o próximo passo)
                            />
                        </div>

                        <DivFlex>
                            <Linque to="/categoria">Cancelar</Linque>
                            <BotaoPrincialEstilizado>
                                {mostrarEditarCategoria&&<p>Salvar edição</p>}
                                {mostrarCarregar&&<Carregando tamanho="p" mensagem="Editando..."/>}
                            </BotaoPrincialEstilizado>
                            
                        </DivFlex>
                    </form>
                </Div>
            </ElementoCentralizadoEstilizado>
            {mostraAlerta&&<Alerta bottom="3rem" tipo="sucesso" mensagem={'Categoria editada com sucesso!'}/>}
        </>
    )
}
const Div=styled.form`
    padding: 3em;
    border-bottom: 1px solid #ccc;
    border-radius: .2rem;
    background-color: whitesmoke;
    box-shadow: 0 0 .1rem rgba(0,0,0,.2);
`
const ImagemIcone=styled(IconeImagem)`
margin:.7em;
`
const AbrirGerenciador=styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color:#3f3f3f;
    margin-bottom: 1em;
`
const DivFlex=styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
`
const Img=styled.img`
    width: 7rem;
    height: 7rem;
    border-radius:.3rem;
    object-fit: cover;
`
const InputCustomizada=styled(Input)`
    border:none;
    margin-bottom: -.1em;
    margin-top: 2em;
    background-color: #f1f1f1;
    padding:0;
    padding: 1em;
    border-radius: .5em;
`
const BotaoPrincialEstilizado=styled(BotaoPrincipal)`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    color:whitesmoke;
    width: 9rem;
    padding:.5em;
    margin:1em;
    &:hover{
        background-color: #f5f5f561;
    }
`
const ElementoCentralizadoEstilizado=styled.div`
    padding: 10em 20% 3em 20%;;    
`