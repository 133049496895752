import fundo from '../../assets/img/tarologaIA.jpg'
import styled from "styled-components";
import {ConsultarDominio} from "../../componentes/global/ConsultarDominio";
import {CheckOut} from "../checkout/CheckOut";
import {IconeVisto} from "../../ui/Icones";
import {Logo} from "../../componentes/global/Logo";
export const CMS=()=>{
    return<>
        <div id={'comecar'}></div>
        <Recipiente>
            <Pelicula>
            <Cards>
                <Card>
                    <Titulo><IconeVisto/>
                         Aplicação CMS
                    </Titulo>
                    <Paragrafo>
                        Altere cores e fundos, deixe seu site mais parecido com você!
                    </Paragrafo>
                </Card>
                <Card>
                    <Titulo><IconeVisto/>
                          Hospedagem de auto nível e registro de domínio anuais.
                    </Titulo>
                    <Paragrafo>
                         Não se preocupe com isso, cuidamos de tudo pra você.
                    </Paragrafo>
                </Card>
                <Card>
                    <Titulo><IconeVisto/>
                        Atualizações mensais.
                    </Titulo>
                    <Paragrafo>
                        Aprimoramento e criação de novas ferramentas para seu site. Atualize a nova versão em somente um clique!
                    </Paragrafo>
                </Card>
                <Card>
                    <Titulo> <Logo/>
                        Serviço VIP.
                    </Titulo>
                    <Paragrafo>
                        "Nossa intenção não é ser uma gigante do setor de tecnologia, mas sim uma minitech, focada em atender a um número limitado de clientes para proporcionar um serviço de excelência!"
                    </Paragrafo>
                </Card>
            </Cards>
                <div>
                    <ConsultarDominio/>
                    <CheckOut/>
                </div>
            </Pelicula>
        </Recipiente>
    </>
}
const Recipiente=styled.div`
    background-image: url(${fundo});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 1024px;
    box-shadow: 1px 10px 67px 33px rgba(3, 3, 3, 0.82);
    
`
const Pelicula=styled.div`
    background-color: rgba(0, 0, 0, 0.78);
    display: flex;
    justify-content: space-between;
    padding: 7em 11em 7em 11em;
`
const Cards=styled.div`
    
`
const Card=styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 500px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    line-height: 1.7rem;
    margin: 0 1rem 1em;
    font-weight: 400;
`
const Titulo=styled.h2`
    text-align: left;
    font-weight: 300;
`
const Paragrafo=styled.p`
    line-height: 1.5em;
    margin-top: 1em;
`