import React from 'react';
import { IconeCartaoDeCredito, IconeTrancar, IconeAviao, IconeCupom } from 'ui/Icones';
import styled from 'styled-components';

export const Garantias = () => {
  return (
    <>
      <RecipienteLocal>
        <SeparadorLocal>
          <IconeCartaoDeCreditoLocal />
          <Paragrafo>Parcele em até 12x sem juros</Paragrafo>
        </SeparadorLocal>
        <SeparadorLocal>
          <IconeTrancarLocal />
          <Paragrafo>Compra 100% segura</Paragrafo>
        </SeparadorLocal>
        <SeparadorLocal>
          <IconeAviaoLocal />
          <Paragrafo>Entrega para todo o Brasil</Paragrafo>
        </SeparadorLocal>
        <SeparadorLocal>
          <IconeCupomLocal />
          <Paragrafo>Use o cupom e ganhe 10% de desconto</Paragrafo>
        </SeparadorLocal>
      </RecipienteLocal>
    </>
  );
};
const Paragrafo=styled.p``
const SeparadorLocal = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 3px solid #8d02258b;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
`;

const RecipienteLocal = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 2rem 20rem 0;
  background-color: #fff;
  @media(max-width: 768px) {
    padding: 2rem 10rem 0;
  }
  @media(max-width: 425px) {
    padding: 2rem 5rem 0;
  }
  @media(max-width:1366px){
    padding: 2rem 5rem 0;
  }
`;

const IconeCartaoDeCreditoLocal = styled(IconeCartaoDeCredito)`
  width: 2rem;
  height: 2rem;
  color: #333;
`;

const IconeTrancarLocal = styled(IconeTrancar)`
  width: 2rem;
  height: 2rem;
  color: #333;
`;

const IconeAviaoLocal = styled(IconeAviao)`
  width: 2rem;
  height: 2rem;
  color: #333;
`;

const IconeCupomLocal = styled(IconeCupom)`
  width: 2rem;
  height: 2rem;
  color: #333;
`;
