import { LogoCereja } from "../componentes/LogoCereja"
import { Pesquisar } from "./Pesquisar"
import { Entrar } from "./Entrar"
import { Favoritos } from "./Favoritos"
import styled from "styled-components"
import { Carrinho } from "./Carrinho"
export const Cabebalho=()=>{

      return <>
      <RecipienteLocal>
        <LogoCereja width={'5rem'}/>
        <Pesquisar/>
        <SeparadorEntrar>
        <Entrar/> 
        <Favoritos/>  
        <Carrinho/>
        </SeparadorEntrar>
      </RecipienteLocal>
    </>
  }

  const SeparadorEntrar = styled.div`
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  `
  const RecipienteLocal = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 20rem; /* Ajuste o espaçamento horizontal */
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
      @media(max-width: 1510px) {
          padding: 1rem 10rem;
      }
      @media(max-width: 1366px) {
          padding: 1rem 5rem;
      }
      @media (max-width: 768px) {
          padding: 1rem 1rem;
      }
  `


  