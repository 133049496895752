import styled, { keyframes } from 'styled-components';
export const Carregando = (props) => {
    if(props.tamanho==='p'){
        return<>
            <Recipiente>
                <LoaderP/>
                <P>{props.mensagem}</P>
            </Recipiente>
        </>
    }
}

const P = styled.p`
    font-size: .8rem;
    text-align: center;
    color: gray;
    margin-left: 10px;
    text-transform: uppercase;
`;
const Recipiente = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    padding-left: .3rem;
`;
const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const LoaderP = styled.div`
    border: 5px solid white;
    border-top: 5px solid lightgray;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: ${spin} 2s linear infinite;
`