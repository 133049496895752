import { MenuGenesi } from "../MenuGenesi"
import { TopoGenesi } from "../TopoGenesi"
import styled from "styled-components"
export const DobraUm = ()=>{
    return<>
             <Recipiente>
                <TopoGenesi/>
                <MenuGenesi />
            </Recipiente>
            
    </>
}
const Recipiente = styled.div`
    text-align: center;
    background-color: whitesmoke;
    z-index: 1;
    padding-top: 16em;
    padding-bottom: 23em;
    box-shadow: 0 0 .5rem rgba(0,0,0,1);
`

