import { useState } from "react";
import { Carregando } from "../global/Carregando";
import { pegarParametrosDaURL } from "../../uteis/funcoes"
import { buscarUsuarioPorTokenESistema, excluirToken, validarEmailDoUsuario } from "../../paginas/Genesi/Controllers";
import { Alerta } from "componentes/global/Alertas";
import { ElementoCentralizado } from "ui/ComponentesDeEstilo";
export const VerficarEmail = () => {
    const[mostraCarregando,setMostraCarregando]=useState(true)
    const[mostraSucesso,setMostraSucesso]=useState(false)
    const[mostraAlerta,setMostraAlerta]=useState(false)
    const url=window.location.href
    const token=pegarParametrosDaURL(url)
    buscarUsuarioPorTokenESistema(token.token)
    .then(resposta=>{
            const id=resposta.tokenValido.usuario_id
            validarEmailDoUsuario(id)
            excluirToken(token.token)
            setMostraSucesso(true)
            setMostraCarregando(false)
            return resposta
        }).catch(erro=>{
            setMostraCarregando(false)
            setMostraSucesso(true)
            return erro
        })
    return<>
        {mostraCarregando&&<ElementoCentralizado><Carregando tamanho="p" mensagem="Verificando email..."/></ElementoCentralizado>}
        {mostraSucesso&&<Alerta tipo="sucesso" mensagem="E-mail verifcado com sucesso!" url="/login" urlTexto="Clique aqui para fazer login."/>}
        {mostraAlerta&&<Alerta tipo="erro" mensagem="Token expirado"/>}
    </>
}


