import { useState } from "react"
import { Storage } from "./Storage"
import { dadosDoUsuario } from "uteis/gerenciarSessao"
import styled from 'styled-components'
import { ElementoCentralizado } from "ui/ComponentesDeEstilo"
export const GerenciarStorage=(props)=>{
    const [previewImage, setPreviewImage] = useState(null)
    return<>
        <Recipiente>
            <Storage onFileUpload={setPreviewImage} foto_de_perfil={props.foto_de_perfil} categoria_produtos_id={0} usuario_id={dadosDoUsuario().id}  />
        </Recipiente>
    </>
}
const Recipiente=styled(ElementoCentralizado)`
    position:fixed;
    width: 30rem;
    top:5em;
    background-color: whitesmoke;
    z-index:11;
    box-shadow: 0px 0px .1em 0px rgba(0, 0, 0, 0.2);
    display:flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid lightgray;
    border-radius: .2em;
`