import { MenuLateral } from "componentes/global/MenuLateral"
import { TopoSistema } from "componentes/painel-de-controle/sistema/TopoSistema"
import { ListarAppsPorUsuario } from "componentes/painel-de-controle/sistema/usuarios/ListarAppsPorUsuario"
import { myMenuItems } from "componentes/painel-de-controle/sistema/usuarios/itensMenu"
export const Aplicacoes=()=>{
    const items=myMenuItems()
    return<>
        <TopoSistema/>
        <MenuLateral menuItems={items} />
        <ListarAppsPorUsuario/>
    </>
}