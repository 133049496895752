import {Button, Container, Input, Select, Separador, Titulo} from "./css";
import styled from "styled-components";
import {IconeCartaoDeCredito} from 'ui/Icones';
import {useEffect, useState} from "react";
import iconeMasterCard from 'ui/mastercard.svg'
import iconeVisa from 'ui/visa.svg'
import { CobrancasAsaas } from './controllers/Cobrancas';
import { BotaoPrincipal } from "ui/ComponentesDeEstilo";
export const CartaoDeCredito=()=>{
    const[iconeCartao,setIconeCartao]=useState('')
    const[numeroCartao,setNumeroCartao]=useState('')
    const[erroNumeroCartao,setErroNumeroCartao]=useState('')

    const[nomeTitular,setNomeTitular]=useState('')
    const[erroNomeTitular,setErroNomeTitular]=useState('')
    
    const[emailTitular,setEmailTitular]=useState('')
    const[erroEmailTitular,setErroEmailTitular]=useState('')

    const[cpfTitular,setCpfTitular]=useState('')
    const[erroCpfTitular,setErroCpfTitular]=useState('')

    const[cepTitular,setCepTitular]=useState('')
    const[erroCepTitular,setErroCepTitular]=useState('')

    const[numeroResidenciaTitular,setNumeroResidenciaTitular]=useState('')
    const[erroNumeroResidenciaTitular,setErroNumeroResidenciaTitular]=useState('')

    const[complementoTitular,setComplementoTitular]=useState('')
    
    const[telefoneFixoTitular,setTelefoneFixoTitular]=useState('')
    const[erroTelefoneFixoTitular,setErroTelefoneFixoTitular]=useState('')

    const[telefoneCelularTitular,setTelefoneCelularTitular]=useState('')

    const[mesValidade,setMesValidade]=useState('')
    const[erroMesValidade,setErroMesValidade]=useState('')

    const[anoValidade,setAnoValidade]=useState('')
    const[erroAnoValidade,setErroAnoValidade]=useState('')

    const[codigoSeguranca,setCodigoSeguranca]=useState('')
    const[erroCodigoSeguranca,setErroCodigoSeguranca]=useState('')

    let primeiroDigito = numeroCartao;
    primeiroDigito.charAt(0)
            console.log(primeiroDigito.charAt(0))
    useEffect(()=>{
        if(primeiroDigito.charAt(0)==='4'){
            setIconeCartao(iconeVisa)
        }else if(primeiroDigito.charAt(0)==='5'){
            setIconeCartao(iconeMasterCard)
        }else {
            setIconeCartao('')
        }
    })
    return<>
        <Recipiente>
            <Titulo> <IconeCartaoDeCredito/> Pagar com cartão de crédito</Titulo>
            <label>Informe os dados do titular do cartão</label>
            <form
                onSubmit={event=>{
                    event.preventDefault()
                    const valor1=localStorage.getItem("valor")
                    const valor2=localStorage.getItem("Total")
                if(valor1!==valor2){
                    const dados={
                        customer_id: localStorage.getItem("id_customizado"),
                        billingType: "CREDIT_CARD",
                        dueDate: '',
                        value: localStorage.getItem("Total"),
                        description: "teste",
                        externalReference: localStorage.getItem("referencia"),
                        nomeTitular: nomeTitular,
                        emailTitular: emailTitular,
                        cpfTitular: cpfTitular,
                        cepTitular: cepTitular,
                        numeroResidenciaTitular: numeroResidenciaTitular,
                        complementoTitular: complementoTitular,
                        telefoneFixoTitular: telefoneFixoTitular,
                        telefoneCelularTitular: telefoneCelularTitular,
                        numeroCartao: numeroCartao,
                        mesValidade: mesValidade,
                        anoValidade: anoValidade,
                        codigoSeguranca: codigoSeguranca
                    }
                    
                    const cobrancasAsaas = new CobrancasAsaas()
                    cobrancasAsaas.criarNovaCobrancaoComCartaoDeCredito(dados)
                    setTimeout(()=>{
                        localStorage.removeItem("carrinho")
                        localStorage.removeItem("Total")
                        localStorage.removeItem("valor")
                    },2000)
                }else{
                    alert("Insira o CEP")
                }
                }}
            >

     
                <Separador>
                    <Input 
                    onChange={event=>{
                        if(event.target.value.length<11){
                            setErroEmailTitular("O email deve ter no mínimo 11 caracteres")
                        }else{
                            setEmailTitular(event.target.value)
                            setErroEmailTitular("")
                        }
                    }}
                    type="text" 
                    placeholder={'Email*'}
                    />
                </Separador>
                <Separador>
                    <Input
                    onChange={event=>{
                        if(event.target.value.length<11){
                            setErroCpfTitular("O CPF deve ter no mínimo 11 caracteres")
                        }else{
                            setCpfTitular(event.target.value)
                            setErroCpfTitular("")
                        }
                    }}
                    type="text" 
                    placeholder={'CPF*'}
                    />
                </Separador>
                <Separador>
                    <Input 
                    onChange={event=>{
                        if(event.target.value.length<8){
                            setErroCepTitular("O CEP deve ter no mínimo 8 caracteres")
                        }else{
                            setCepTitular(event.target.value)
                            setErroCepTitular("")
                        }
                    }}
                    type="text" 
                    placeholder={'CEP*'}
                    />
                </Separador>
                <Separador>
                    <Input 
                    onChange={event=>{
                        if(event.target.value.length<1){
                            setErroNumeroResidenciaTitular("O número deve ter no mínimo 1 caracteres")
                        }else{
                            setNumeroResidenciaTitular(event.target.value)
                            setErroNumeroResidenciaTitular("")
                        }
                    }}
                    type="text" 
                    placeholder={'Número*'}
                    />
                </Separador>
                <Separador>
                    <Input 
                    onChange={event=>{
                        setComplementoTitular(event.target.value)
                    }}
                    type="text" 
                    placeholder={'Complemento'}
                    />
                </Separador>
                <Separador>
                    <Input 
                    onChange={event=>{
                        if(event.target.value.length<10){
                            setErroTelefoneFixoTitular("O telefone deve ter no mínimo 10 caracteres")
                        }else{
                        setTelefoneFixoTitular(event.target.value)
                        setErroTelefoneFixoTitular("")
                        }
                    }}
                    type="text" 
                    placeholder={'Telefone fixo*'}
                    />
                </Separador>
                <Separador>
                    <Input 
                    onChange={event=>{
                        setTelefoneCelularTitular(event.target.value)
                    }}
                    type="text" 
                    placeholder={'Celular'}
                    />
                </Separador>



                <Separador>
                    <Input
                    onChange={event=>{
                        if(event.target.value.length<3){
                            setErroNomeTitular("O nome deve ter no mínimo 3 caracteres")
                        }else{
                            setNomeTitular(event.target.value)
                            setErroNomeTitular("")
                        }
                    }}
                    id="nomeTitulo" 
                    type="text" 
                    placeholder={'Nome como está no cartão*'}
                    />
                </Separador>
                <SeparadorNumeroCartao>
                    <Input
                        onChange={event=>{
                            if(event.target.value.length<16){
                                setErroNumeroCartao("O número do cartão deve ter no mínimo 16 caracteres")
                                let cartao=event.target.value
                                setNumeroCartao(cartao)
                            }else{
                                let cartao=event.target.value
                                setNumeroCartao(cartao)
                                setErroNumeroCartao("")
                            }
                        }}
                        type="text"
                        placeholder={'Número do cartão*'}
                        value={numeroCartao}
                    /> <img src={iconeCartao} alt=""/>
                </SeparadorNumeroCartao>
                <Separador>
                    <label htmlFor="">Data de validade do cartão: </label><br/>
                    <InputValidades
                        onChange={event=>{
                            setMesValidade(event.target.value)
                        }}
                    >
                        <option value="">Mês</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                    </InputValidades>
                    <InputValidades
                        onChange={event=>{
                            if(event.target.value.length<2){
                                setErroAnoValidade("O ano deve ter no mínimo 2 caracteres")
                            }else{
                                setAnoValidade(event.target.value)
                                setErroAnoValidade("")
                            }
                        }}
                    >
                        <option value="">Ano</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                    </InputValidades>
                </Separador>
                <Separador>
                    <label htmlFor="">Código de segurança: </label><br/>
                    <Input
                        onChange={event=>{
                            if(event.target.value.length<3){
                                setErroCodigoSeguranca("O código de segurança deve ter no mínimo 3 caracteres")
                            }else{
                                setCodigoSeguranca(event.target.value)
                                setErroCodigoSeguranca("")
                            }
                        }}
                     type="text" placeholder={'Código de segurança CVV'}/>
                </Separador>
                <Botao>Finalizar compra com cartão de crédito</Botao>
            </form>
        </Recipiente>
    </>
}
const Recipiente=styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    color: black;
    background-color: ghostwhite;
    padding: 3rem;
    margin: 1rem auto ;
    border-radius: .3rem;
    line-height: 2rem;
    border-bottom: .1rem solid lightgray;
    .alerta{
        text-align: left;
    }
`
const Botao=styled(BotaoPrincipal)`
`
const InputValidades=styled(Select)`
  width: 9.9rem;
  margin-right: 1rem;
`
const SeparadorNumeroCartao=styled.div`
  display: flex;
  align-content: center;
`