import { useEffect } from "react"
import { pegarParametrosDaURL } from "uteis/funcoes"
import { useState } from "react"
import { listarSubcategoriasPorSistema, vincularUsuarioApp } from "../Controller"
import { BotaoPrincipal, ElementoCentralizado, Select } from "ui/ComponentesDeEstilo"

export const VincularUsuarioApp=()=>{
    const url=window.location.href
    const usuario=pegarParametrosDaURL(url)
    const[subcategorias,setSubcategorias]=useState([])
    const[subCategoriaSelecionada,setSubcategoriaSelecionada]=useState('')
    const[titulo,setTitulo]=useState()
    const[urlArquivo,setUrlArquivo]=useState('')
    const nome_app=titulo
    const url_arquivo=urlArquivo
    console.log(nome_app,url_arquivo)
    useEffect(()=>{
        listarSubcategoriasPorSistema(setSubcategorias)
    },[])
    const handleSubcategoriaChange = (e) => {
        const selectedSubcategoriaId = e.target.value;
    
        // Log para debug
        console.log('ID selecionado: ', selectedSubcategoriaId);
    
        const selectedSubcategoria = subcategorias.find(subcategoria => String(subcategoria.id) === String(selectedSubcategoriaId));
    
        // Log para debug
        console.log('Subcategoria selecionada: ', selectedSubcategoria);
    
        if (selectedSubcategoria) {
            setSubcategoriaSelecionada(selectedSubcategoriaId);
            setTitulo(selectedSubcategoria.titulo);
            setUrlArquivo(selectedSubcategoria.url_arquivo);
        } else {
            setSubcategoriaSelecionada('');
            setTitulo('');
            setUrlArquivo('');
        }
    }
    return<>
            <ElementoCentralizado>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    vincularUsuarioApp(usuario.id, subCategoriaSelecionada, nome_app, url_arquivo);
                }}>
                    <Select
                        onChange={handleSubcategoriaChange}
                    >
                        <option value="">Vincular aplicação ao usuário</option>
                        {
                            subcategorias.map((subcategoria, index) => (
                                <option key={index} value={subcategoria.id}>{subcategoria.titulo}</option>
                            ))
                        }
                    </Select>
                    <BotaoPrincipal>Vincular</BotaoPrincipal>
                </form>
            </ElementoCentralizado>
    </>
}