import styled from 'styled-components';
import { PrimeiraLetraDoNome } from "./PrimeiraLetraDoNome";
import { IconeChevronDown } from '../../../ui/Icones';
import { FlexLinha } from '../../../ui/ComponentesDeEstilo';
import { useState } from 'react';
import { useEffect } from 'react';
import { MenuSistema } from './MenuSistema';
import { Logo } from 'componentes/global/Logo';
import { listarFotoDePerfil } from './Controller';
import { dadosDoUsuario } from 'uteis/gerenciarSessao';
export const TopoSistema = () => {
    const [mostra, setMostra] = useState(false)
    const [mostraNotificacao, setMostraNotificacao] = useState(false)
    const [usuario, setUsuario] = useState({})
    const [fotoDePerfil, setFotoDePerfil] = useState(null)
    console.log(fotoDePerfil)
    useEffect(() => {
        const Usuarios=dadosDoUsuario()
        setUsuario(Usuarios)
        const fetchDados = async () => {
          const id=Usuarios.id
            listarFotoDePerfil(id, setFotoDePerfil);
        }
        fetchDados();
      }, [usuario.id]);
    const handleMostra = () => {
        setMostra(prevMostra => !prevMostra)
        if (mostraNotificacao) {
            setMostraNotificacao(false)
        }
    }
    return (
        <Recipiente>
            <Div>
                <Logo width="4rem" url="/painel-de-controle"/>
                <FlexLinhaEstilizado>
                    <FlexLinhaEmail>
                        <Nome>{usuario.nome_completo}</Nome>
                        <ChevronDown onClick={handleMostra} />
                        {mostra &&<MenuSistema/>}
                    </FlexLinhaEmail>
                    {fotoDePerfil 
                        ? <FotoPerfil src={fotoDePerfil} alt="Foto de perfil" />
                        : <PrimeiraLetraDoNome nome={usuario.nome_completo} />
                    }
                </FlexLinhaEstilizado>
            </Div>
        </Recipiente>
      )
  }
const Div=styled.div`
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 1em 0 1em ;
`
const Recipiente = styled.div`
    border-bottom: 1px solid #dee2e6;
    background-color:whitesmoke;
    position:fixed;
    top:0;
    width:100%;
    z-index:10;
`
const FotoPerfil = styled.img`
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
`
const Nome=styled.p`
    margin-right: 1rem;
    color: #3f3f3f;
    font-weight: 300;
`
const FlexLinhaEmail = styled(FlexLinha)`
    justify-content: flex-end;
    width: 20rem;
`
const ChevronDown = styled(IconeChevronDown)`
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 1rem;
    color: #212529;
    cursor: pointer;
`
const FlexLinhaEstilizado = styled(FlexLinha)`
    width: 20rem;
    justify-content: space-between;
    align-items: center;
`


