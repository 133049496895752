import { useEffect, useState } from "react"
import styled from "styled-components";
import { CobrancasAsaas } from '../pagamentos/controllers/Cobrancas';
export const ListarComprasDoUsuario = () => {
    const [cobrancas, setCobrancas] = useState([]);
    console.log(cobrancas)
    useEffect(() => {
      const cobrancasAsaas=new CobrancasAsaas();
      cobrancasAsaas.listarCobrancaPorIdCustomer(setCobrancas)
    }, []);
    const handleVerPedido = (caminho) => {
        window.location.href = `ver-pedido?id=${caminho}`;
    }
    return<>
        <Recipiente>
          <H1>Minhas compras</H1>
          {cobrancas.map(cobranca => (
              <Separador key={cobranca.id}>
                  <Paragrafo>Tipo de pagamento: {cobranca.billingType}</Paragrafo>
                  <Paragrafo>Pagamento: {cobranca.status}</Paragrafo>
                  <Paragrafo>Valor: R$ {cobranca.value}</Paragrafo>
                  <A onClick={()=>handleVerPedido(cobranca.externalReference)}>Ver pedido</A>
                  <a target="_blank" href={cobranca.invoiceUrl}>Ver cobrança</a>
              </Separador>
          ))}
        </Recipiente>
      </>
  }
const A = styled.button`
    text-decoration: none;
    color: #333;
    font-size: .7rem;
    margin-top: .7rem;
    margin-right: 1rem;
    cursor: pointer;
    border: none;
    color: ghostwhite;
    border-radius: .3rem;
    padding: .3rem;
    &:hover{
        color: #333;
    }
`
const Recipiente = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    background-color: ghostwhite;
    padding: 2rem 0 2rem;
`
const H1=styled.h1`
    font-size: 2rem;
    margin-bottom: 3rem;
    text-transform: uppercase;
`
const Paragrafo=styled.p`
    font-size: 1rem;
    color: #333;
    line-height: 1.5rem;
`
const Separador=styled.div`
    background-color: white;
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: .7rem;
    border-bottom: 1px solid #ccc;
    width:20rem;
`