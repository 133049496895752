import {BrowserRouter,Routes,Route} from "react-router-dom"
import { NaoEncontrado } from "./componentes/global/NaoEncontrado"
import { Genesi } from "./paginas/Genesi/Genesi"
import { Login } from "./componentes/autenticacao/Login"
import { Cadastro } from "./componentes/autenticacao/Cadastro"
import { VerficarEmail } from "./componentes/autenticacao/VerificarEmail"
import { RecuperarSenha } from "componentes/autenticacao/RecuperarSenha"
import { AlterarSenha } from "componentes/autenticacao/AlterarSenha"
import { TermosECondicoes } from "componentes/autenticacao/TermosECondicoes"
import { PainelDeControle } from "paginas/painel-de-controle/PainelDeControle"
import { MeusDados } from "componentes/painel-de-controle/sistema/MeusDados"
import { CategoriaProdutos } from "paginas/painel-de-controle/CategoriaProdutos"
import { Onisciente } from "paginas/painel-de-controle/Onisciente"
import { EditarCategoria } from "componentes/painel-de-controle/sistema/categorias/EditarCategoria"
import { Publicacoes } from "paginas/painel-de-controle/Publicacaoes"
import { Elingerie } from "componentes/sites/e-lingerie/Elingerie"
import { Favoritos } from "componentes/sites/e-lingerie/paginas/Favoritos"
import { Carrinho } from "componentes/sites/e-lingerie/paginas/Carrinho"
import { UmProduto } from "componentes/sites/e-lingerie/paginas/UmProduto"
import { ListarProdutos } from "componentes/sites/e-lingerie/produtos/ListarProdutos"
import { ElingerieLogin } from "componentes/sites/e-lingerie/autenticacao/ELingerieLogin"
import { ELCadastro } from "componentes/sites/e-lingerie/autenticacao/ELCadastro"
import { ELRecuperarSenha } from "componentes/sites/e-lingerie/autenticacao/ELRecuperarSenha"
import { ELTermosECondicoes } from "componentes/sites/e-lingerie/autenticacao/ELTermosECondicoes"
import { ELVerficarEmail } from "componentes/sites/e-lingerie/autenticacao/ELVerificarEmail"
import { ELPagamentos } from "componentes/sites/e-lingerie/paginas/ELPagamentos"
import { ELMinhasCompras } from "componentes/sites/e-lingerie/paginas/ELMinhasCompras"
import { UmaSubcategoria } from "componentes/painel-de-controle/sistema/subcategorias/UmaSubcategoria"
import { Usuarios } from "paginas/painel-de-controle/Usuarios"
import { Usuario } from "paginas/painel-de-controle/Usuario"
import { Aplicacoes } from "paginas/painel-de-controle/Aplicacoes"
import { Aplicacao } from "paginas/painel-de-controle/Aplicacao"
import { Blog } from "paginas/blog/Blog"
import { Upload } from "paginas/upload/Upload"
import {LandingPage} from "./paginas/landing-pages/LandingPage";
import {CheckOut} from "./paginas/checkout/CheckOut";
export const Rotas = () => {
    return <>
        <BrowserRouter>
            <Routes>
                <Route index element={<Genesi/>}/>
                <Route path="/termos-e-condicoes" element={<TermosECondicoes/>}/>
                <Route path="/cadastro" element={<Cadastro/>}/>
                <Route path="/verificar-email" element={<VerficarEmail/>}/> 
                <Route path="/login" element={<Login/>}/>
                <Route path="/usuarios" element={<Usuarios/>}/>
                <Route path="/usuario" element={<Usuario/>}/>
                <Route path="/recuperar-senha" element={<RecuperarSenha/>}/>
                <Route path="/alterar-senha" element={<AlterarSenha/>}/>
                <Route path="/painel-de-controle/" element={<PainelDeControle/>}/>
                <Route path="/meus-dados" element={<MeusDados/>}/>
                <Route path="/categoria" element={<CategoriaProdutos/>}/>
                <Route path="/categoria-editar" element={<EditarCategoria/>}/>
                <Route path="/subcategoria" element={<UmaSubcategoria/>}/>
                <Route path="/publicacoes" element={<Publicacoes/>}/>
                <Route path="/onisciente" element={<Onisciente/>}/>
                <Route path="/blog" element={<Blog/>}/>
                <Route path="/upload" element={<Upload/>}/>
                <Route path='/landing-page' element={<LandingPage/>}/>
                <Route path='/checkout' element={<CheckOut/>}/>
                //rotas do usuário

                <Route path="/aplicacoes" element={<Aplicacoes/>}/>
                <Route path="/aplicacao" element={<Aplicacao/>}/>

                <Route path="/e-lingerie" element={<Elingerie/>}/>
                <Route path="/e-lingerie/favoritos" element={<Favoritos/>}/>
                <Route path="/e-lingerie/carrinho" element={<Carrinho/>}/>
                <Route path="/e-lingerie/um-produto" element={<UmProduto/>}/>
                <Route path="/e-lingerie/produtos" element={<ListarProdutos/>}/>
                <Route path="/e-lingerie/login" element={<ElingerieLogin/>}/>
                <Route path="/e-lingerie/autenticar" element={<ELCadastro/>}/>
                <Route path="/e-lingerie/recuperar-senha" element={<ELRecuperarSenha/>}/>
                <Route path="/e-lingerie/termos-e-condicoes" element={<ELTermosECondicoes/>}/>
                <Route path="/e-lingerie/verificar-email" element={<ELVerficarEmail/>}/>
                <Route path="/e-lingerie/pagamentos" element={<ELPagamentos/>}/>
                <Route path="/e-lingerie/minhas-compras" element={<ELMinhasCompras/>}/>
                <Route path="*" element={<NaoEncontrado/>}/>
            </Routes>

        </BrowserRouter>
    </>
}