import { useState } from "react"
import { UsuarioController } from "../controllers/UsuarioController"
import { useEffect } from "react"
import styled from "styled-components"
import { IconeEntrar } from "ui/Icones"
export const Entrar=()=>{
    const [nome,setNome]=useState('')
    const[permissao,setPermissao]=useState('')
    const handleEntrar = () => {
        window.location.href = "/login"
      }
    useEffect(() => {
        const usuarioControler=new UsuarioController()
        usuarioControler.listarUsuarioPorId(setNome,setPermissao)
      },[]) 
    return<>
        <IconeEntrarLocal />
        <SeparadorTextoEntrar onClick={handleEntrar}>
            <ParagrafoEntrar>Oi!</ParagrafoEntrar>
            <ParagrafoEntrar>Entre ou cadastre-se!</ParagrafoEntrar>
        </SeparadorTextoEntrar>
    </>
}
const SeparadorTextoEntrar = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    padding-left: 0.2rem;
    width: 8rem;
`
const ParagrafoEntrar = styled.div`
    text-align: left;
    font-size: 0.8rem;
    color: #333;
    cursor: pointer;
    &:hover {
    color: #000;
    }
`
const IconeEntrarLocal = styled(IconeEntrar)`
    width: 2rem;
    height: 2rem;
    color: #333;
    cursor: pointer;
    &:hover {
    color: #000;
    }
`
