import styled from "styled-components"
import { Linque } from "../../ui/ComponentesDeEstilo"
export const TemConta = (props) => {
    return<>
        <SemConta>
            <p>{props.textoUm}</p>
            <Linque to={`${props.url}`}>{props.textoDois}</Linque>
        </SemConta>
    </>
}
const SemConta = styled.div`
    margin-top: 1rem;
    line-height: 1.5rem;
    text-align: center;
`