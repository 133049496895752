import React, { useState } from 'react';
import styled from 'styled-components';
import { ElementoCentralizado } from 'ui/ComponentesDeEstilo';

const OptionButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 5px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Input = styled.input`
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

const Select = styled.select`
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

const TextArea = styled.textarea`
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

const FileInput = styled.input`
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
`;

export const UploadComponent = () => {
  const [showCategoryForm, setShowCategoryForm] = useState(false);
  const [category, setCategory] = useState('');
  const [fileName, setFileName] = useState('');
  const [filePrice, setFilePrice] = useState('');
  const [file, setFile] = useState(null);
  const [details, setDetails] = useState('');
  const [categories, setCategories] = useState([]);
  const [isCategoryForm, setIsCategoryForm] = useState(false);

  const handleToggleCategoryForm = () => {
    setShowCategoryForm(!showCategoryForm);
    setIsCategoryForm(!showCategoryForm);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  const handleFilePriceChange = (e) => {
    setFilePrice(e.target.value);
  };

  const handleDetailsChange = (e) => {
    setDetails(e.target.value);
  };

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];

    if (uploadedFile) {
      const maxSize = 3 * 1024 * 1024 * 1024;
      if (uploadedFile.name.endsWith('.zip') && uploadedFile.size <= maxSize) {
        setFile(uploadedFile);
      } else {
        alert('Por favor, selecione um arquivo .zip com no máximo 3 gigabytes.');
      }
    }
  };

  const handleCategorySubmit = (e) => {
    e.preventDefault(); // Evita o refresh da página
    if (category) {
      setCategories([...categories, category]);
      setCategory('');
      setIsCategoryForm(false);
    }
  };

  const handleUpload = () => {
    if (!category || !fileName || !filePrice || !file || (isCategoryForm && !details)) {
      alert('Preencha todos os campos e selecione um arquivo .zip.');
      return;
    }

    const formData = new FormData();
    formData.append('catetoria_id', category);
    formData.append('titulo', fileName);
    formData.append('preco', filePrice);
    formData.append('url', file);

    if (isCategoryForm) {
      formData.append('details', details);
    }

    const uploadUrl = 'https://onisciente.com:2222/upload';

    fetch(uploadUrl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          alert('Upload bem-sucedido!');
          setCategory('');
          setFileName('');
          setFilePrice('');
          setFile(null);
          setDetails('');
          setIsCategoryForm(false);
        } else {
          alert('Erro ao fazer upload. Tente novamente.');
        }
      })
      .catch((error) => {
        alert('Erro ao fazer upload. Tente novamente.');
      });
  };

  return (
    <Recipiente>
      <OptionButton onClick={handleToggleCategoryForm}>
        {showCategoryForm ? 'Voltar' : 'Cadastrar Nova Categoria'}
      </OptionButton>
      {showCategoryForm ? (
        <Form>
          <Input
            type="text"
            placeholder="Categoria"
            value={category}
            onChange={handleCategoryChange}
          />
          <Button onClick={handleCategorySubmit}>Cadastrar Categoria</Button>
        </Form>
      ) : (
        <Form>
          <Select onChange={handleCategoryChange}>
            <option value="">Selecione uma categoria</option>
            {categories.map((cat, index) => (
              <option key={index} value={cat}>
                {cat}
              </option>
            ))}
          </Select>
          <Input
            type="text"
            placeholder="Nome do Arquivo"
            value={fileName}
            onChange={handleFileNameChange}
          />
          <Input
            type="text"
            placeholder="Preço do Arquivo"
            value={filePrice}
            onChange={handleFilePriceChange}
          />
          <TextArea
            placeholder="Detalhes do Upload"
            value={details}
            onChange={handleDetailsChange}
          />
          <FileInput
            type="file"
            accept=".zip"
            onChange={handleFileUpload}
          />
          <Button onClick={handleUpload}>Enviar</Button>
        </Form>
      )}
    </Recipiente>
  );
}
const Recipiente=styled(ElementoCentralizado)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
  margin: 0 auto;
  text-align: center; /* Centraliza horizontalmente */
`
