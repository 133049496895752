import styled from "styled-components"
import { IconeFacebook, IconeInstagram } from "ui/Icones"
export const RodaPe = () => {
    const handleInstagram = () => {
        window.open("https://www.instagram.com/cerejademaio/")
    }
    const handleFacebook = () => {
        window.open("https://www.facebook.com/profile.php?id=100093568792246")
    }
    return<>
        <Recipiente>
            <Separador>
                <Separador>
                    Siga-nos nas redes sociais
                    <Icones className="separador-dois">
                        <IconeInstagram onClick={handleInstagram}/>
                    </Icones>
                    <Icones>
                        <IconeFacebook onClick={handleFacebook}/>
                    </Icones>
                </Separador>
            </Separador>
            <Separador>
                <Paragrafo>© 2023 Cereja de Maio - Todos os direitos reservados.</Paragrafo>
            </Separador>
        </Recipiente>
    </>
}
const Icones = styled.div`
    margin: 0 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
`
const Paragrafo = styled.p`
    color: #fff;
    font-size: 1rem;
    margin: 0;
`
const Recipiente = styled.div`
    background-color: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
`
const Separador = styled.div`
    margin: .5rem;
    display: flex;
    flex-direction: row;
    font-size: 1.2rem;
    .separador-dois{
        margin-left: 1.5rem;
    }
    @media(max-width: 400px){
        margin: 1.5rem;
    }
`