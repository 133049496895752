import { useEffect, useState } from 'react'
import { CarrinhoDeComprasController } from '../controllers/CarrinhoDeComprasController'
import { Cabebalho } from '../cabecalho/Cabecalho'
import { Menu } from '../menu/Menu'
import { pegarParametrosDaURL } from 'uteis/funcoes'
import styled from 'styled-components'
export const VerPedido = () => {
    const [carrinhoReferencia, setCarrinhoReferencia] = useState()
    console.log(carrinhoReferencia)
    useEffect(() => {
        const url=window.location.href
        const id=pegarParametrosDaURL(url)
        const carrinhoDeComprasController = new CarrinhoDeComprasController()
        carrinhoDeComprasController.listarCarrinhoPorReferenciaExterna(id,setCarrinhoReferencia)
    }, []);
    
    return<>
        <Cabebalho/>
        <Menu/>
        <Recipiente>
            <H1>Meus pedidos</H1>
            {carrinhoReferencia && carrinhoReferencia.map(carrinho => (
                <FlexLinha key={carrinho.id}>
                    <Img src={carrinho.produto_url_foto}/>
                    <Paragrafo> Produto: {carrinho.produto_descricao}</Paragrafo>
                    <Paragrafo> Cor: {carrinho.produto_cor}</Paragrafo>
                    <Paragrafo> Tamanho: {carrinho.produto_tamanho}</Paragrafo>
                    <Paragrafo> Quantidade: {carrinho.quantidade}</Paragrafo>
                    <Paragrafo> Valor: R$ {carrinho.valor}</Paragrafo>
                </FlexLinha>
            ))}
        </Recipiente>
    </>
}
const Paragrafo = styled.p`
    font-size: 1rem;
    color: #333;
    margin: 1rem 0;
    margin: 1rem;
`
const FlexLinha = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    border-radius: 5px;
`
const Img = styled.img`
    width: 4rem;
    height: 100%;
    margin: 1rem;
`
const Recipiente = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ghostwhite;
    padding: 2rem 0 2rem;
`
const H1=styled.h1`
    font-size: 2rem;
    margin-bottom: 3rem;
    text-transform: uppercase;
`