import { Cabebalho } from "../cabecalho/Cabecalho"
import { Caminho } from "../componentes/Caminho"
import { Menu } from "../menu/Menu"
import { ListarUmProduto } from "../produtos/ListarUm"
export const UmProduto=()=>{
    return<>
        <Cabebalho/>
        <Menu/>
        <Caminho/>
        <ListarUmProduto/>
    </>
}