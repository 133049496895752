import styled from "styled-components"
export const Card=()=>{
    return<>
        <Div>
            <Img src="https://picsum.photos/200/301" alt="" />
            <Texto>
                <H1>Lorem ipsum dolor sit amet consectetur.</H1>
                <P>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore quidem neque ea, et laboriosam, dignissimos cupiditate quae possimus vitae ipsam repellat. Nemo, illum eligendi? Labore dolorum impedit perferendis excepturi eum!  Inventore quidem neque ea, et laboriosam, dignissimos cupiditate quae possimus vitae ipsam repellat. Nemo, illum eligendi? </P>
            </Texto>
        </Div>
    </>
}
const Texto=styled.div`
    width: 32rem;
    margin: .5em;
`
const H1=styled.h1`
    margin-bottom: 1em;
    margin-top: .5em;
`
const Div=styled.div`
    display: flex;
    height: 15rem;
    align-items: top;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 3em;
    margin-bottom: 3em;
`
const P=styled.p`
    width: 29em;
    line-height: 1.5em;
    
`
const Img=styled.img`
    width:25rem;
    height: 15rem;
    border-radius: .5em;
    transition: 0.3s;
    margin-right:1em;
    &:hover{
        transform: scale(1.005);
        
    }
`