import {Restful} from "../../servidor/restful";
export const checarDominio=async(dominio)=>{
    return await Restful.get(`/verificar-dominio/${dominio}`).then(resposta=>{
        if(resposta.data.body==="{}"){
            return 'dominio disponível para registro'
        }else{
            return 'dominio indisponível para registro'
        }
    }).catch(erro=>{
        return erro
    })
}
export const alterarConvite=async (dados)=>{
    return await Restful.patch(`/alterar-convite`,{
        dados
    }).then(res=>{
        console.log(res)
        return res
    }).catch(err=>{
        console.log(err)
        return err
    })
}