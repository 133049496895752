import { IconeAbrir, IconePostagens, IconeServicos } from "ui/Icones";

export const myMenuItem=[
    {icon: <IconeAbrir />, nome: "Visitar site", link: "/", target: "_blank"},
    {icon: <IconePostagens />, nome: "Postagens", link: "/", target: "_self"},
    {icon: <IconeServicos />, nome: "Aplicações", link: "/aplicacoes", target: "_self"},
]
export const myMenuItems=()=>{

    const itens=[
        {icon: <IconeAbrir />, nome: "Visitar site", link: "/", target: "_blank"},
        {icon: <IconePostagens />, nome: "Postagens", link: "/", target: "_self"},
        {icon: <IconeServicos />, nome: "Aplicações", link: "/aplicacoes", target: "_self"},
    ]
    return itens
}