import { Restful } from "servidor/restful"
import { Sistema } from "config/sistema"
export class CategoriaController{
    criarCategoria=async(dados)=>{
        await Restful.post('/criar/categoria',{
            "nome":dados.nome,
            "descricao":dados.descricao,
            "sistema":Sistema
        }).then(resposta=>{
            console.log(resposta)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarCategoriasPorSistema=async(setCategorias)=>{
        await Restful.get(`/listar/categorias/sistema/${Sistema}`).then(resposta=>{
            setCategorias(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarCategoriasPorQuantidadeESistema=async(quantidade,setCategorias)=>{
        await Restful.get(`/listar/categorias/${Sistema}/${quantidade}`).then(resposta=>{
            setCategorias(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    listarUmaCategoriaPorId=async(id,setCategoria)=>{
        await Restful.get(`/listar/uma/categoria/${id}`).then(resposta=>{
            setCategoria(resposta.data)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    editarCategoria=async(dados)=>{
        await Restful.patch(`/editar/categoria/${dados.id}`,{
            "nome":dados.nome,
            "descricao":dados.descricao
        }).then(resposta=>{
            console.log(resposta)
        }).catch(erro=>{
            console.log(erro)
        })
    }
    deletarCategoria=async(id)=>{
        await Restful.delete(`/deletar/categoria/${id}`).then(resposta=>{
        }).catch(erro=>{
            console.log(erro)
        })
    }
}