import { RotaPagamentos } from "../../rotaPagamentos"
export class CobrancasAsaas{
    criarNovaCobranca=async (dados,setCobranca)=>{
        await RotaPagamentos.post(`/criar-cobranca`,{
            "customer": dados.customer,
            "billingType": dados.billingType,
            "dueDate": dados.dueDate,
            "value": dados.value,
            "description": dados.description,
            "externalReference": dados.externalReference,
            "discount": {
                "value": dados.discount.value,
                "dueDateLimitDays": dados.discount.dueDateLimitDays
            },
            "fine": {
                "value": dados.fine.value
            },
            "interest": {
                "value": dados.interest.value
            },
            "postalService": dados.postalService
        }).then(res=>{
            setCobranca(res.data)
            return res.data
        }).catch(err=>{
            console.log(err.message)
            return err
        })
    }
    criarNovaCobrancaoComCartaoDeCredito=async (dados,setCobranca)=>{
        await RotaPagamentos.post(`/criar-cobranca-cartao-credito`,{
            "customer": dados.customer,
            "billingType": dados.billingType,
            "dueDate": dados.dueDate,
            "value": dados.value,
            "description": dados.description,
            "externalReference": dados.externalReference,
            "creditCard": {
                "holderName": dados.nomeTitular,
                "number": dados.numeroCartao,
                "expiryMonth": dados.mesValidade,
                "expiryYear": dados.anoValidade,
                "ccv": dados.codigoSeguranca
            },
            "creditCardHolderInfo": {
                "name": dados.nomeTitular,
                "email": dados.emailTitular,
                "cpfCnpj": dados.cpfTitular,
                "postalCode": dados.cepTitular,
                "addressNumber": dados.numeroResidenciaTitular,
                "phone": dados.telefoneFixoTitular
            }
        }).then(res=>{
            setCobranca(res.data)
            return res.data
        }).catch(err=>{
            console.log(err.message)
            return err
        })
    }
    listarCobrancaPorIdCustomer=async (setCobrancas)=>{
        const id=localStorage.getItem('id_customizado')
        await RotaPagamentos.get(`/listar-cobranca-usuario/${id}`)
            .then(res=>{
                console.log(res.data.data)
                setCobrancas(res.data.data)
                return res.data
            })
            .catch(err=>{
                return err
            })
    }
    recuperarCobranca=async (id)=>{
        await RotaPagamentos.get(`/recupera-cobranca/${id}`)
            .then(res=>{
                return res.data
            })
            .catch(err=>{
                return err
            })
    }
    checarCobranca=async (id,setCobranca)=>{
        await RotaPagamentos.get(`/recuperar-cobranca/${id}`)
            .then(res=>{
                setCobranca(res.data)
                return res.data
            })
            .catch(err=>{
                return err
            })
    }
    obterQRCodParaPagamentos=async (id,setQrCode)=>{
        await RotaPagamentos.get(`/recuperar-qrcode-pix/${id}`)
            .then(res=>{
                setQrCode(res.data.payload)
                return res.data
            })
            .catch(err=>{
                return err
            })
    }
    listarCobrancas=async (setCobrancas)=>{
        await RotaPagamentos.get(`/listar-cobrancas-recebidas`)
            .then(res=>{
                setCobrancas(res.data.data)
                return res
            })
            .catch(err=>{
                return err
            })
    }
    criarCobrancaParcelada=async (dados)=>{
        await RotaPagamentos.post(`/criar-cobranca-parcelada`,{
            "customer": dados.customer,
            "billingType": dados.billingType,
            "installmentCount": dados.installmentCount,
            "installmentValue": dados.installmentValue,
            "dueDate": dados.dueDate,
            "description": dados.description,
            "externalReference": dados.externalReference,
            "discount": {
                "value": dados.discount.value,
                "dueDateLimitDays": dados.discount.dueDateLimitDays
            },
            "fine": {
                "value": dados.fine.value
            },
            "interest": {
                "value": dados.interest.value
            }
        }).then(res=>{
            return res.data
        }).catch(err=>{
            console.log(err.message)
            return err
        })
    }
    listarCobrancaPorReferenciaExterna=async (externalReference)=>{
        await RotaPagamentos.get(`/listar-cobranca-referencia-externa/${externalReference}`)
            .then(res=>{
                console.log(res.data.data[0].status)
                if(res.data.data[0].status==="RECEIVED"){
                    setInterval(()=>{
                        localStorage.removeItem('referencia')
                        window.location.href="/confirmacao-pagamento"
                    },2000)
                }else{
                    return
                }
            })
            .catch(err=>{
                return err
            })
    }
}

