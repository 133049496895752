import { ErroInput, Input, Label, BotaoPrincipal, Select, Verde, Linque } from 'ui/ComponentesDeEstilo'
import styled from 'styled-components'
import { mascaraCEP, mascaraCpf, mascaraTelefone } from 'uteis/validacoes'
import { Link } from 'react-router-dom'
export const Step1 = ({ nextStep, campos, setCampos, erros, validaCampos }) => {
    const avancar = (e) => {
        e.preventDefault()
        const nomeCompletoValido = validaCampos('nome_completo', campos.nome_completo)
        const dataNascimentoValida = validaCampos('dataNascimento', campos.dataNascimento)
        const sexoValido = validaCampos('sexo', campos.sexo)
        const cpfValido = validaCampos('cpf', campos.cpf)
        const telefoneValido=validaCampos ('telefone', campos.telefone)
        if (nomeCompletoValido && dataNascimentoValida && sexoValido && cpfValido && telefoneValido) {
            nextStep()
        }
    }
    return <>
        <FlexColuna>
            <Label>Nome completo*</Label>
            <Input placeholder='SEU NOME COMPLETO' type="text" value={campos.nome_completo} onChange={e => setCampos({...campos, nome_completo: e.target.value})} autoFocus />
            <ErroInput>{erros.nome_completo}</ErroInput>
        </FlexColuna>
        <FlexColuna>
            <Label>Data de Nascimento*</Label>
            <Input type="date" value={campos.dataNascimento} onChange={e => setCampos({...campos, dataNascimento: e.target.value})} />
            <ErroInput>{erros.dataNascimento}</ErroInput>
        </FlexColuna>
        <FlexColuna>
            <Label>Sexo*</Label>
            <Select value={campos.sexo} onChange={e => setCampos({...campos,sexo: e.target.value})}>
                <option value="">SELECIONE O SEXO</option>
                <option value="masculino">Masculino</option>
                <option value="feminino">Feminino</option>
            </Select>
            <ErroInput>{erros.sexo}</ErroInput>
        </FlexColuna>
        <FlexColuna>
            <Label>CPF*</Label>
            <Input type="text" value={campos.cpf} placeholder="DIGITE SEU CPF" onChange={e => setCampos({...campos, cpf: mascaraCpf(e.target.value)})} />
            <ErroInput>{erros.cpf}</ErroInput>
        </FlexColuna>
        <FlexColuna>
            <Label>Telefone*</Label>
            <Input type="text" value={campos.telefone} placeholder="FiXO OU CELULAR" onChange={e => setCampos({...campos, telefone: mascaraTelefone(e.target.value)})} />
            <ErroInput>{erros.telefone}</ErroInput>
        </FlexColuna>
        <BtnContinuar onClick={avancar}>Ir para o próximo passo</BtnContinuar>
        <SemConta>
                <P>Já tem conta?</P>
                <Linque to="/login">Faça o login</Linque>
        </SemConta>
    </>
}
export const Step2 = ({ previousStep, nextStep, campos, setCampos, erros, validaCampos }) => {
    const avancar = (e) => {
        e.preventDefault();
        const cepValido=validaCampos('cep', campos.cep)
        const ruaValida=validaCampos('rua', campos.rua)
        const numeroValido=validaCampos('numero', campos.numero)
        const bairroValido=validaCampos('bairro', campos.bairro)
        const cidadeValida=validaCampos('cidade', campos.cidade)
        const estadoValido=validaCampos('estado', campos.estado)
        if (cepValido && ruaValida && numeroValido && bairroValido && cidadeValida && estadoValido) {
            nextStep();
        }
    }
    return <>
        <FlexColuna>
            <Label>CEP*</Label>
            <Input placeholder='INSIRA O CEP' type="text" value={campos.cep} onChange={e => setCampos({...campos, cep: mascaraCEP(e.target.value)})} />
            <ErroInput>{erros.cep}</ErroInput>
        </FlexColuna>
        <FlexColuna>
            <FelxLinha>
                <div>
                    <Label>Rua*</Label>
                    <Input  type="text" value={campos.rua} placeholder={'RUA OU AVENIDA'} onChange={e => setCampos({...campos, rua: e.target.value})} />
                    <ErroInput>{erros.rua}</ErroInput>
                </div>
                <div>
                    <Label>Número*</Label>
                    <Input placeholder='NÚMERO DE SUA RESIDÊNCIA' type="number" value={campos.numero} onChange={e => setCampos({...campos, numero: e.target.value})} />
                    <ErroInput>{erros.numero}</ErroInput>
                </div>
            </FelxLinha>
        <FlexColuna>
            <Label>Complemento</Label>
            <Input placeholder='COMPLEMENTO DE SUA RESIDÊNCIA' type="text" value={campos.complemento} onChange={e => setCampos({...campos, complemento: e.target.value})} />
        </FlexColuna>
        <FlexColuna>
            <Label>Bairro*</Label>
            <Input placeholder='SEU BAIRRO' type="text" value={campos.bairro} onChange={e => setCampos({...campos, bairro: e.target.value})} />
            <ErroInput>{erros.bairro}</ErroInput>
        </FlexColuna>
        <FlexColuna>
            <FelxLinha>
            <div>
                <Label>Cidade*</Label>
                <Input placeholder='SUA CIDADE' type="text" value={campos.cidade} onChange={e => setCampos({...campos, cidade: e.target.value})} />
                <ErroInput>{erros.cidade}</ErroInput>
            </div>
            <div>
                <Label>Estado*</Label>
                <Select value={campos.estado} onChange={e => setCampos({...campos, estado: e.target.value})}>
                    <option value="">SEU ESTADO</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia </option>
                    <option value="CE">Ceará </option>
                    <option value="DF">Distrito Federal </option>
                    <option value="ES">Espírito Santo </option>
                    <option value="GO">Goiás </option>
                    <option value="MA">Maranhão </option>
                    <option value="MT">Mato Grosso </option>
                    <option value="MS">Mato Grosso do Sul </option>
                    <option value="MG">Minas Gerais </option>
                    <option value="PA">Pará </option>
                    <option value="PB">Paraíba </option>
                    <option value="PR">Paraná </option>
                    <option value="PE">Pernambuco </option>
                    <option value="PI">Piauí </option>
                    <option value="RJ">Rio de Janeiro </option>
                    <option value="RN">Rio Grande do Norte </option>
                    <option value="RS">Rio Grande do Sul </option>
                    <option value="RO">Rondônia </option>
                    <option value="RR">Roraima </option>
                    <option value="SC">Santa Catarina </option>
                    <option value="SP">São Paulo </option>
                    <option value="SE">Sergipe </option>
                    <option value="TO">Tocantins </option>
                </Select>
                <ErroInput>{erros.estado}</ErroInput>
            </div>
            </FelxLinha>
        </FlexColuna>
        </FlexColuna>
            <BtnContinuar onClick={avancar}>Ir para o próximo passo</BtnContinuar>
            <BtnVoltar onClick={previousStep}>Voltar</BtnVoltar>
            
    </>
}
export const Step3 = ({ previousStep, campos, setCampos, erros, validaCampos, enviarParaAPI }) => {

    const avancar = (e) => {
        e.preventDefault();
        const emailValido = validaCampos('email', campos.email);
        const senhaValida = validaCampos('senha', campos.senha);
        const confirmaSenhaValida = validaCampos('confirmaSenha', campos.confirmaSenha);
        const termosValido = validaCampos('termos', campos.termos);
        if (campos.senha !== campos.confirmaSenha) {
            alert('As senhas não correspondem.')
            return
        }

        if (emailValido && senhaValida && confirmaSenhaValida && termosValido) {
            enviarParaAPI()
            return
        }
    }
    return <>
        <FlexColuna>
            <Label>E-mail*</Label>
            <Input placeholder='SEU E-MAIL' type="email" value={campos.email} onChange={e => setCampos({...campos, email: e.target.value})} />
            <ErroInput>{erros.email}</ErroInput>
        </FlexColuna>
        <FlexColuna>
            <Label>Senha*</Label>
            <Input placeholder='SUA SENHA' type="password" value={campos.senha} onChange={e => setCampos({...campos, senha: e.target.value})} />
            <ErroInput>{erros.senha}</ErroInput>
        </FlexColuna>
        <FlexColuna>
            <Label>Confirme a senha*</Label>
            <Input placeholder='CONFIRME SUA SENHA' type="password" value={campos.confirmaSenha} onChange={e => setCampos({...campos, confirmaSenha: e.target.value})} />
            <ErroInput>{erros.confirmaSenha}</ErroInput>
        </FlexColuna>
        <FlexTermos>
            <InputEstilizado type="checkbox" value={campos.termos} onChange={e => setCampos({...campos, termos: e.target.checked})} />
            <Label>Li e concordo com os <LinkTermo target='_blank' to="/termos-e-condicoes">termos e condições</LinkTermo></Label>
        </FlexTermos>
            <ErroInput>{erros.termos}</ErroInput>
        <BtnContinuar onClick={avancar}>Enviar cadastro</BtnContinuar>
        <BtnVoltar onClick={previousStep}>Voltar</BtnVoltar>
    </>
}
const LinkTermo=styled(Link)`
    line-height: 1.5rem;
    text-align: justify;
    color: ${Verde};
`
const InputEstilizado = styled(Input)`
    flex: 0; 
    margin-right: 10px; 
    width: 20px;
    height: 20px;
`;
const FlexTermos = styled.div`
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  align-content: center;
  padding-left: 10px;
`;
const BtnContinuar = styled(BotaoPrincipal)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:1rem;
    border-radius: .4rem;
    background-color: whitesmoke;
    color: black;
    &:hover{
        background-color: #d6d6d6;
    }
`
const BtnVoltar=styled(BotaoPrincipal)`
    margin-top: 1rem;
    background-color: transparent;
    color: black;
    &:hover{
        background-color: transparent;
        color: gray
    }
`
const P=styled.p`
`
const SemConta = styled.div`
    margin-top: 1rem;
    text-align: center;
    line-height: 1.5rem;
`
const FlexColuna = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
`
const FelxLinha = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
