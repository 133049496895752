import { MenuLateralGenesi } from './MenuLateralGenesi'
import { DobraUm } from './dobras/DobraUm'
import { DobraDois } from './dobras/DobraDois'
import { DobraTres } from './dobras/DobraTres'
import { DobraQuatro } from './dobras/DobraQuatro'
import { RodaPe } from './RodaPe'
import { DobraCinco } from './dobras/DobraCinco'
import { BannerMeta } from './dobras/BannerMeta'
import { SubirParaTopo } from './SubirParaTopo'
import { OniChatGenesi } from '../../componentes/global/oni-chat/OniChatBase'
export const Genesi = () => {
    return<>  
            <MenuLateralGenesi />
            <DobraUm/>
            <DobraDois/>
            <DobraTres/>
            <BannerMeta/>
            <DobraCinco/>
            <DobraQuatro/>
            <OniChatGenesi/>
            <SubirParaTopo/>
            <RodaPe/>
        </>
}


