import { useState } from "react"
import { useEffect } from "react"
import { Chart } from "react-google-charts"
import styled from "styled-components"
import { ElementoCentralizado } from "ui/ComponentesDeEstilo"
import { listarTodosOsUsuariosDaApi } from "../Controller"
export const GeoGrafico=()=>{
    const[usuarios,setUsuarios]=useState('')
    console.log()
    const data = [
        ["Country", "Usuários"],
        ["Brazil", usuarios.length],
      ]
    useEffect(()=>{
        listarTodosOsUsuariosDaApi(setUsuarios)
    },[])
    return<>
            <ElementoCentralizadoEstilizado>
            <Chart
                chartEvents={[
                    {
                    eventName: "select",
                    callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const region = data[selection[0].row + 1];
                        console.log("Selected : " + region);                        
                    },
                    },
                ]}
                chartType="GeoChart"
                width="100%"
                height="700px"
                data={data}
                />
            </ElementoCentralizadoEstilizado>
    </>
}
const ElementoCentralizadoEstilizado=styled(ElementoCentralizado)`
    z-index: -1;
    top:7em;
`