import { useState, useEffect } from "react";
import { FlexColuna, FlexLinha, Linque } from "../../../ui/ComponentesDeEstilo";
import { IconeEditar, IconeImagem } from "../../../ui/Icones";
import { dadosDoUsuario } from "uteis/gerenciarSessao";
import { TopoSistema } from "./TopoSistema";
import { MenuLateralSistema } from "./MenuLateralSistema";
import { listarFotoDePerfil } from "./Controller";
import { ConfirmacaoAlterarSenha } from "./ConfirmacaoAlterarSenha";
import { GerenciarStorage } from "./GerenciarStorage";
import { useData } from "contexts/DataContext";
import styled from "styled-components";
export const MeusDados = () => {
    const [fotoDePerfil, setFotoDePerfil] = useState(null)
    const [mostraConfirmacaoSenha, setMostraConfirmacaoSenha] = useState(false)
    const[mostrarGerenciador,setMostrarGerenciador]=useState(false)
    const {data}=useData()
    const usuario=dadosDoUsuario()
    useEffect(() => {
        listarFotoDePerfil(usuario.id,setFotoDePerfil)
    }, [usuario.id])
    const mostraConfirmacaoAlterarSenha = () => {
        setMostraConfirmacaoSenha(true)
    }
    const handleImg=()=>{
        setMostrarGerenciador(true)
    }
    return<>
        <TopoSistema />
        <MenuLateralSistema />
            <FlexColunaEstilizado>
                {fotoDePerfil && <ImgDoBanco src={fotoDePerfil} alt="Foto de perfil" />}
                {mostrarGerenciador&&<GerenciarStorage foto_de_perfil={true}/>}
                <AbrirGerenciador onClick={handleImg}>
                    <p>Enviar imagem </p>  
                    <ImagemIcone/>
                </AbrirGerenciador>
                <DadosExibidos>
                    <H1>{usuario.nome_completo}</H1>
                    <PermissaoEstilizado>{usuario.permissao}</PermissaoEstilizado>
                    <p>{usuario.email}</p>
                    <p>{usuario.cidade}/{usuario.estado}</p>
                </DadosExibidos>
                <LinqueEstilizado onClick={mostraConfirmacaoAlterarSenha}>Alterar senha <IconeEditar/></LinqueEstilizado>
            </FlexColunaEstilizado>
        {mostraConfirmacaoSenha && <ConfirmacaoAlterarSenha />}
    </>
  }
  const ImagemIcone=styled(IconeImagem)`
    margin:.7em;
  `
  const AbrirGerenciador=styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color:#3f3f3f;
    margin-bottom: 1em;
  `
  const H1=styled.h1`
    font-weight:300;
    margin-bottom: 1rem;
    font-size: 150%;
  `
  const ImgDoBanco = styled.img`
    width: 10rem;
    height: 10rem;
    border-radius: .5rem;
    margin-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    object-fit: cover;
    border: 1px solid lightgray;
    object-position: center;
    transition: 0.5s;
    &:hover{
        transform: scale(1.1);
    }
`
const DadosExibidos = styled.div`
    line-height: 1.5rem;
    width: 80%;
`
const PermissaoEstilizado = styled.p`
    margin-right: 1rem;
    background-color: #f1f1f1;
    color: gray;
    border-radius: 0.7rem;
    font-size: 0.5rem;
    text-align: center;
    text-transform: uppercase;
    width: 5rem;
    margin-top: .3rem;
    margin-bottom: 1rem;
`
const LinqueEstilizado = styled(Linque)`
    margin-right: 1rem;
    margin-top:1rem;
    background-color: #3f3f3f;
    color: white;
    padding: 0.2rem;
    border-radius: 0.5rem;
    width: 10rem;
    text-align: center;
    transition: 0.5s;
    &:hover{
        background-color: #4f4f4f;                                                                                                                                                        
    }
`
const FlexColunaEstilizado = styled(FlexColuna)`
    line-height: 2rem;
    margin: 10em 25%
`
