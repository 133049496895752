import { useState } from "react"
import { ProdutoController } from "../controllers/ProdutoController"
import styled from "styled-components"
import { Input } from "ui/ComponentesDeEstilo"
import { IconeCircularLupa } from "ui/Icones"
export const Pesquisar=()=>{
    const [produtos,setProdutos]=useState([])
    return<>
    <FormularioLocal
          onSubmit={(event) => {
            event.preventDefault()
            const produtoController = new ProdutoController()
            produtoController.listarProdutosPorTermoESistema(event.target[0].value,setProdutos)
          }}
        >
          <InputLocal
              autoFocus type="text" 
              placeholder="Pesquisar"
            />
          <IconeCircularLupaLocal />
        </FormularioLocal>
    </>
}
const FormularioLocal = styled.form`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 45rem; /* Ajuste a largura máxima do formulário de pesquisa */
    height: 3rem; /* Ajuste a altura do formulário de pesquisa */
    border: 0.1rem solid #ccc;
    border-radius: 0.5rem;
    padding: 0 0.5rem;
@media(max-width: 600px) {
    display: none;
    }
`
const InputLocal = styled(Input)`
    width: 100%;
    border: none;
    outline: none;
    font-weight: 700;
    color: #333;
    text-align: left;
    background-color: transparent;
    &::placeholder {
    color: #ccc;
    }
`
const IconeCircularLupaLocal = styled(IconeCircularLupa)`
    width: 1.8rem;
    height: 1.8rem;
    color: #333;
    cursor: pointer;
    &:hover {
    color: #000;
    }
`