import styled from "styled-components";
import { LogoCereja } from "../componentes/LogoCereja";
export const Topo=()=>{
    const handleVoltar=()=>{
        window.location.replace('/')
    }
    return<>
        <RecipienteLocal>
            <LogoCereja/>
        </RecipienteLocal>
    </>
}
const RecipienteLocal=styled.div`
  background-color:rgb(245, 245, 245);
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom:1px solid lightgray;
  display:flex;
  flex-direction:row;
  align-items: center;
`
const Img=styled.img`
  width: 4rem;
  cursor: pointer;
  margin-right:1rem;
`