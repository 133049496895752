import { useEffect, useState } from "react"
import { ProdutoController } from "../controllers/ProdutoController"
import { IconeHome, IconeSetaDireita } from "ui/Icones"
import { Linque } from "ui/ComponentesDeEstilo"
import { pegarParametrosDaURL } from "uteis/funcoes"
import styled from "styled-components"
export const Caminho = () => {
    const[id,setId]=useState('')
    const[produto,setProduto]=useState("")
    const produtoController=new ProdutoController()
    useEffect(()=>{
        const url=window.location.href
        const parametro=pegarParametrosDaURL(url)
        setId(parametro.id)
    },[id])
    useEffect(() => {
        produtoController.listarUmProdutoPorIdESistema(id, setProduto);
      }, [id]);
    return<>
        <RecipienteLocal>
            <ParagrafoLocal>
                <LinkLocal to="/">
                    <IconeHome/>
                </LinkLocal>
                <IconeSetaDireitaLocal/>
                {produto.categoria_nome}
                <IconeSetaDireitaLocal/>
                {produto.subcategoria_nome}
            </ParagrafoLocal>
        </RecipienteLocal>
    </>
}
const ParagrafoLocal=styled.p`
    text-align:left;
`
const LinkLocal=styled(Linque)`
    color:black;
`
const RecipienteLocal=styled.div`
    padding: 1rem 20% 1rem 20%; 
    color:white;
    background-color:ghostwhite;
    margin-top:1rem;
`
const IconeSetaDireitaLocal=styled(IconeSetaDireita)`
    margin: 0 .5rem 0 .5rem;
`