import React from 'react'
import styled from 'styled-components'

export const PrimeiraLetraDoNome=(props)=> {
  const getFirstLetter = (nome) => {
    if (nome) { // Checa se 'nome' está definido
      return nome.charAt(0)
    }
    return ""; // Retorna uma string vazia caso 'nome' seja undefined
  }

  return <>
    <PrimeiraLetra>{getFirstLetter(props.nome)}</PrimeiraLetra>
  </>
}

const PrimeiraLetra=styled.p`
    background-color: #3f3f3f;
    border-radius: 50%;
    color: white;
    font-size: 1.4rem;
    font-weight: 500;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    margin: 0;
    text-align: center;


`
