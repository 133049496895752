import {AlertaErroTexto, BotaoDestaque, ElementoCentralizado, Input, Label} from "../../ui/ComponentesDeEstilo";
import {useState} from "react";
import styled from "styled-components";
import {mascaraCpf, mascaraTelefone} from "../../uteis/validacoes";
import {criptografar, decriptografar} from "../../uteis/crypto";
import {alterarConvite, ativar} from "../landing-pages/controller";

export const CheckOut=()=>{
    const[nome,setNome]=useState('')
    const[erroNome,setErroNome]=useState('')

    const[telefone,setTelefone]=useState('')
    const[erroTelefone,setErroTelefone]=useState('')

    const[cpf,setCPF]=useState('')
    const[erroCPF,setErroCPF]=useState('')

    const[email,setEmail]=useState('')
    const[erroEmail,setErroEmail]=useState('')

    const[token]=useState(localStorage.getItem('token-ativacao'))
    const tokenCriptografado=decriptografar(token)
    const emailChecagem=tokenCriptografado.email
    return<>
        <Recipiente>
            <form
                onSubmit={(event) => {
                    event.preventDefault();

                    const dominio=localStorage.getItem('dominio')
                    const status=2
                    if(dominio){
                        const ativacao = {email,nome,dominio,telefone,cpf,status,token,emailChecagem};
                        if(email==='' || nome==='' || dominio==='' || telefone===''||cpf==='') {
                            alert('Por favor, preencha todos os campos para prosseguir')
                        }else {
                            alterarConvite(ativacao)
                        }
                    }else{
                        alert('Por favor, insira primeiro o domínio para continuar')
                    }

                }}
            >

                <Linha>
                    <Separador>
                        <Label>Nome completo:</Label>
                        <Inputs
                            placeholder={'Nome completo'}
                            onChange={(evento) => {
                                const nome = evento.target.value
                                if (nome.length <= 7) {
                                    setErroNome('Nome inválido')
                                } else {
                                    setErroNome('')
                                    setNome(nome)
                                }
                            }}
                        />
                        <AlertaErroTexto>{erroNome}</AlertaErroTexto>
                    </Separador>
                    <div>
                        <Label>CPF:</Label>
                        <Input
                            placeholder={'Seu CPF'}
                            onChange={(event) => {
                                const cpf = event.target.value
                                if (cpf.length < 11) {
                                    setCPF(cpf)
                                    setErroCPF('CPF inválido')
                                } else {
                                    setCPF(mascaraCpf(event.target.value))
                                    setErroCPF('')
                                }
                            }}
                            value={cpf}
                        />
                        <AlertaErroTexto>{erroCPF}</AlertaErroTexto>
                    </div>
                </Linha>
                <Linha>
                    <div>
                        <Label>E-mail:</Label>
                        <InputMail
                            placeholder={'Seu email'}
                            onChange={(event) => {
                                const email = event.target.value
                                if (email.length <= 7) {
                                    setErroEmail('Email deve ter mais que 7 caracteres')
                                } else {
                                    setErroEmail('')
                                    setEmail(email)
                                }
                            }}
                        />
                        <AlertaErroTexto>{erroEmail}</AlertaErroTexto>
                    </div>
                    <div>
                        <Label>Telefone (Whatsapp):</Label>
                        <Input
                            placeholder={'Seu telefone'}
                            onChange={(event) => {
                                const telefone = event.target.value
                                if (telefone.length < 11) {
                                    setTelefone(telefone)
                                    setErroTelefone('Preencha o telefone corretamente')
                                } else {
                                    setTelefone(mascaraTelefone(telefone))
                                    setErroTelefone('')
                                }
                            }}
                            value={telefone}
                        />
                        <AlertaErroTexto>{erroTelefone}</AlertaErroTexto>
                    </div>
                </Linha>
                <Linha>
                    <Botao type={"submit"}>Teste grátis por 3 dias!</Botao>
                </Linha>
                <Aviso>
                    <p>Após o período de teste você receberá uma ordem de pagamento via email.</p>
                    <p>R$ 101,00/ano + R$ 36,00/mês com domínio e hospedagem incluídos. </p>
                </Aviso>
            </form>
        </Recipiente>
    </>
}
const Aviso = styled.div`
    margin-top: 2em;
    padding: 2em;
    border-radius: 2em;
    line-height: 1.3em;
    color: black;
    background-color: rgba(64, 255, 0, 0.89);

`
const Separador = styled.div`
    width: 200px;
`
const InputMail = styled(Input)`
    width: 24rem;
`
const Inputs = styled(Input)`
    width: 30rem;
`
const Botao = styled(BotaoDestaque)`
    color: black;
    padding: 1em 3em;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    background-color: rgba(255, 215, 0, 0.89);
    
`;
const Linha=styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
`
const Recipiente=styled.div`
    width: 78%;
    margin:0 auto;

`