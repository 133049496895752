import styled from "styled-components"

export const Propaganda2=()=>{
    return<>
        <Div>

        </Div>
    </>
}
const P=styled.h3`
    border-bottom: 1px solid #e4e4e4;
    line-height: 2em;
    color: #3f3f3f;
    font-weight: 500;
`
const Div=styled.div`
    background-color: whitesmoke;
    margin-top: 2em;
    padding: 1em;
    border-radius: .5em;
    width: 18rem;
    height: 19rem;
`
const H1=styled.p`
    font-size: 100%;
    margin-top: 1em;
    color:#5f5f5f;
    font-weight: 200;
    padding:.2em;
`