import { useEffect, useState } from "react";
import { ProdutoController } from "../controllers/ProdutoController";
import {BotaoPrincipal} from "ui/ComponentesDeEstilo";
import { CarrinhoDeComprasController } from "../controllers/CarrinhoDeComprasController";
import styled from "styled-components";
import { pegarParametrosDaURL } from "uteis/funcoes";

export const ListarUmProduto = () => {
  const [primeiroParametro, setPrimeiroParametro] = useState("");
  const [urlId,setUrlId]=useState('')
  const [produto, setProduto] = useState(null);
  const [mostraAlerta, setMostraAlerta] = useState(false);
  const [album, setAlbum] = useState([]);
  const [tamanhoSelecionado, setTamanhoSelecionado] = useState(null);
  const [corSelecionada, setCorSelecionada] = useState(null);
  const [quantidadeSelecionada, setQuantidadeSelecionada] = useState(null);
  const [quantidadeInserida, setQuantidadeInserida] = useState(1);
  const [valorACobrar, setValorACobrar] = useState(0)
  const produtoController = new ProdutoController();
  const carrinhoDeComprasController = new CarrinhoDeComprasController();
  console.log(produto && produto.preco_promocional)
  useEffect(() => {
    let valorCobrado = produto && produto.preco_promocional 
    if(valorCobrado){
      setValorACobrar(valorCobrado)
    }else{
      setValorACobrar(produto && produto.preco)
    }
  }, [produto])
  useEffect(() => {
    const url=window.location.href
    const parametro=pegarParametrosDaURL(url)
    setUrlId(parametro.id)
  }, []);

  useEffect(() => {
    produtoController.listarAlbumPorPodutoID(urlId, setAlbum);
  }, [urlId]);

  useEffect(() => {
    produtoController.listarUmProdutoPorIdESistema(urlId, setProduto);
  }, [urlId]);

  const handleClickTamanho = (tamanho) => {
    setTamanhoSelecionado(tamanho);
    setCorSelecionada(null); // Redefine a cor selecionada como null
    setQuantidadeSelecionada(null); // Redefine a quantidade selecionada como null
  };

  const handleClickCor = (cor) => {
    setCorSelecionada(cor);
    const quantidade = produto.tamanhos
      .find((tamanho) => tamanho.tamanho === tamanhoSelecionado)
      ?.cores.find((item) => item.nome === cor.nome)?.quantidade;
    setQuantidadeSelecionada(quantidade);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const referencia=localStorage.getItem("referencia")
    if(referencia){
        const quantidade = Number(quantidadeInserida);
        carrinhoDeComprasController.criarCarrinhoDeCompras(
          produto,
          tamanhoSelecionado,
          corSelecionada,
          quantidade
        )
        const dados ={
          "produto_id":produto.id,
          "produto_cor_id":corSelecionada.id,
          "produto_descricao":produto.descricao,
          "produto_url_foto":produto.url_foto,
          "produto_cor":corSelecionada.nome,
          "produto_tamanho":tamanhoSelecionado,
          "valor":produto.preco,
          "valor_promocional":produto.preco_promocional,
          "quantidade":Number(quantidadeInserida),
        }
        carrinhoDeComprasController.criarCarrinhoDeComprasBanco(dados)
        setMostraAlerta(true)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
    }else{
      localStorage.setItem("referencia",referencia)
      const quantidade = Number(quantidadeInserida);
      carrinhoDeComprasController.criarCarrinhoDeCompras(
        produto,
        tamanhoSelecionado,
        corSelecionada,
        quantidade
      )
      const dados ={
        "produto_id":produto.id,
        "produto_cor_id":corSelecionada.id,
        "produto_descricao":produto.descricao,
        "produto_url_foto":produto.url_foto,
        "produto_cor":corSelecionada.nome,
        "produto_tamanho":tamanhoSelecionado,
        "valor":valorACobrar,
        "valor_promocional":produto.preco_promocional,
        "quantidade":Number(quantidadeInserida),
      }
      carrinhoDeComprasController.criarCarrinhoDeComprasBanco(dados)
      setMostraAlerta(true)
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
  }
  const coresFiltradas =
    tamanhoSelecionado &&
    produto &&
    produto.tamanhos.find((tamanho) => tamanho.tamanho === tamanhoSelecionado)?.cores;
  return (
    <>
      <RecipienteLocal>
        <SeparadorLocalLinha>
          <FlexColuna>
            {album.map((item, index) => (
              <ImagemMini key={index} src={item.url_foto} />
            ))}
          </FlexColuna>
          {produto && (
            <ImagemLocal src={produto.url_foto} />
          )}
          <SeparadorLocalColuna>
            {produto && (
              <>
                <ParagrafoLocalNome>
                  {produto.categoria_nome} {produto.subcategoria_nome}
                </ParagrafoLocalNome>
                <ParagrafoLocalDescricao>{produto.descricao}</ParagrafoLocalDescricao>
                <div>
                  <ParagrafoLocalCores>Tamanhos</ParagrafoLocalCores>
                  <FlexLinha>
                    {produto.tamanhos.map((item, index) => (
                      <Tamanho
                        key={index}
                        onClick={() => handleClickTamanho(item.tamanho)}
                        style={{
                          fontWeight: item.tamanho === tamanhoSelecionado ? "bold" : "normal",
                        }}
                      >
                        {item.tamanho}
                      </Tamanho>
                    ))}
                  </FlexLinha>
                </div>
                <div>
                  <ParagrafoLocalCores>Cores</ParagrafoLocalCores>
                  <FlexLinha>
                    {coresFiltradas ? (
                      coresFiltradas.map((cor) => (
                        <Cor
                          key={cor.id}
                          cor={cor.codigo}
                          selecionada={cor === corSelecionada}
                          onClick={() => handleClickCor(cor)}
                        ></Cor>
                      ))
                    ) : (
                      <div>Selecione um tamanho</div>
                    )}
                  </FlexLinha>
                </div>
                {corSelecionada && (
                  <QuantidadeDisponivel>
                    Estoque disponível: {quantidadeSelecionada} <br />
                    <QuantidadeDeCompra>
                      Quantidade: <InputQuantidade placeholder="1" type="number" value={quantidadeInserida} onChange={(event) => setQuantidadeInserida(Number(event.target.value))} />
                    </QuantidadeDeCompra>
                  </QuantidadeDisponivel>
                )}
                <Preco>R$ {valorACobrar}</Preco>
                <FormularioCarrinho onSubmit={handleSubmit}>
                  <AdicionarAoCarinho>Adicionar ao carrinho</AdicionarAoCarinho>
                </FormularioCarrinho>
              </>
            )}
          </SeparadorLocalColuna>
        </SeparadorLocalLinha>
      </RecipienteLocal>
    </>
  );
};

const QuantidadeDeCompra = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`
const InputQuantidade = styled.input`
  width: 3rem;
  border: 1px solid black;
  border-radius: 0.3rem;
  padding: 0.5rem;
  text-align: center;
  font-size: 1rem;
  outline: none;
  margin-left: 0.5rem;
`
const QuantidadeDisponivel = styled.p`
  font-size: 1rem;
  text-align: left;
  margin: 0 1rem 1rem;
`;
const Cor = styled.div`
  background-color: ${(props) => props.cor};
  margin-right: 0.3rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  ${(props) =>
    props.selecionada && `border: 2px solid black;`} // Adicione o estilo para cor selecionada
`
const ImagemMini = styled.img`
  width: 5rem;
  height: 5.6rem;
  position: relative;
  margin: 0 0.5rem .5rem;
  cursor: pointer;
  z-index: 1;
      transition: all .3s;
    &:hover {
        transform: scale(2.1);
    }
  @media(max-width: 466px) {
    margin: 0 0 .5rem;
  }
`
const FlexLinha = styled.div`
    display: flex;
    flex-direction: row;
    margin: 1rem;
`

const FlexColuna = styled.div`
    display: flex;
    flex-direction: column;
    @media(max-width: 466px) {
      display:flex;
      flex-direction: row;
    }
`
const Tamanho = styled(BotaoPrincipal)`
  color: black;
  margin-right: .3rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  border: 1px solid black;
  text-align: center;
  line-height: 2rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;

`

const FormularioCarrinho = styled.form``;
const AdicionarAoCarinho = styled(BotaoPrincipal)`
  margin: 1rem 0 0 0;
  width: 90%;
  height: 3rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  background-color: #420d0d;
  color: white;
  border-radius: 2rem;
  @media(max-width: 400px) {
    font-size: 100%;
  }
`;
const Preco = styled.p`
  font-size: 2rem;
  font-weight: bold;
  margin: 0 1rem 0 1rem;
  text-transform: uppercase;
  text-align: left;
`;
const ParagrafoLocalCores = styled.p`
  text-align: left;
  font-size: 100%;
  margin: 2rem 1rem 0 1rem;
  text-transform: uppercase;
  font-weight: bold;
`;
const ParagrafoLocalNome = styled.p`
  text-align: left;
  font-size: 2rem;
  font-weight: bold;
  margin: 0 1rem 0 1rem;
  text-transform: uppercase;
`;
const ParagrafoLocalDescricao = styled.p`
  text-align: left;
  font-size: 100%;
  margin: 1rem 1rem 0 1rem;
`;
const RecipienteLocal = styled.div`
  padding: 1rem 5% 1rem 5%;
  display: flex;
  justify-content: center;
  color: white;
  background-color: ghostwhite;
  margin-top: 1rem;
  @media (max-width: 1756px) {
    padding: 1rem 5% 1rem 5%;
  }
  @media(max-width: 1172px) {
    padding: 1rem 1% 1rem 1%;
  }
`;
const SeparadorLocalColuna = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 35rem;
`;
const SeparadorLocalLinha = styled.div`
  margin: 0 1rem 0 1rem;
  display: flex;
  flex-direction: row;
  @media(max-width: 1182px) {
    flex-direction: column;
  }
`;
const ImagemLocal = styled.img`
  min-width: 30rem;
  margin: 0 .5rem ;
`;
