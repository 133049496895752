import { IconeChevronCima } from "ui/Icones"
import styled from "styled-components"
export const SubirParaTopo = ()=>{
    const handleSubir=()=>{
        window.scrollTo(0,0)
    }
    return<>
        <Subir onClick={handleSubir}><IconeChevronCima/></Subir>
    </>
}
const Subir = styled.span`
    position: fixed;
    bottom: 1.7em;
    right: 1em;
    font-size: 2em;
    color: whitesmoke;
    background-color: #3f3f3f;
    border-radius: .2rem;
    padding: .1em;
    box-shadow: 0 0 .2rem rgba(0,0,0,.1);
    transition: all .3s ease-in-out;
    cursor: pointer;
    &:hover{
        color: #fff;
        background-color: #000;
    }
    `