import { Sistema } from "config/sistema"
import { Restful } from "servidor/restful"
import { dadosDoUsuario } from "uteis/gerenciarSessao"
export const analisarEResponder = async (frase, setRespostaOni) => {
    try {
        const res = await Restful.post('/analisar-responder', {
            "frase": frase
        });
        setRespostaOni(res.data);
        console.log(res);
        return res.data;
    } catch (err) {
        console.log(err);
        setRespostaOni("Ocorreu um erro ao obter a resposta.");
        return err;
    }
};

export const criarVetor=async(vetor)=>{
    Restful.post('/criar-vetor',{
        "vetor":vetor
    }).then(res=>{
        console.log(res)
        return res
    }).catch(err=>{
        console.log(err)
        return err
    })
}
export const adicionarPalavra = async (palavra) => {
    try {
        const res = await Restful.post('/adicionar-palavra', {
            "palavra": palavra
        });
        return res.data;
    } catch (err) {
        console.log(err);
        throw err; // Se quiser que os erros sejam tratados fora da função, você pode usar 'throw' em vez de retornar o erro.
    }
}
export const parafrasearTexto=async(texto)=>{
    try{
        const res=await Restful.post('/parafrasear-texto',{
            "texto":texto
        })
        return res.data
    }catch(err){
        console.log(err)
        throw err;
    }
}

export const adicionarSinonimo=async(palavra,sinonimo)=>{
    Restful.post('/adicionar-sinonimo',{
        "palavra_id":palavra,
        "sinonimo_id":sinonimo
    }).then(res=>{
        return res.data
    }).catch(err=>{
        console.log(err)
        return err
    })
}
export const adicionarAntonimo=async(palavra,antonimo)=>{
    Restful.post('/adicionar-antonimo',{
        "palavra_id":palavra,
        "antonimo_id":antonimo
    }).then(res=>{
        return res.data
    }).catch(err=>{
        console.log(err)
        return err
    })
}