import { LinhaDotempo } from "componentes/global/LinhaDoTempo"
import { MenuLateral } from "componentes/global/MenuLateral"
import { OniChatGenesi } from "componentes/global/oni-chat/OniChatBase"
import { MenuLateralSistema } from "componentes/painel-de-controle/sistema/MenuLateralSistema"
import { TopoSistema } from "componentes/painel-de-controle/sistema/TopoSistema"
import { CardsQTDCadastros } from "componentes/painel-de-controle/sistema/cards/CardsQTDCadastros"
import { GeoGrafico } from "componentes/painel-de-controle/sistema/graficos/GeoGrafico"
import { myMenuItems } from "componentes/painel-de-controle/sistema/usuarios/itensMenu"
import { dadosDoUsuario } from "uteis/gerenciarSessao"
export const PainelDeControle = () => {
    const usuario=dadosDoUsuario()
    if(usuario.permissao==='usuario'){
        const items=myMenuItems()
        return<>
            <TopoSistema/>
            <MenuLateral menuItems={items} />
            <LinhaDotempo/>
            <OniChatGenesi/>
        </>
    }
    if(usuario.permissao==='sistema'){
        return <>
            <TopoSistema/>
            <MenuLateralSistema/>
            <CardsQTDCadastros/>
            <GeoGrafico/>
            <OniChatGenesi/>
        </>
    }
}
