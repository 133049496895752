import {analisarEResponder} from "../../componentes/painel-de-controle/sistema/onisciente/ControllerOni";
import {useEffect, useState} from "react";
import styled from 'styled-components'
import {IconeEnviar} from "../../ui/Icones";
import cartaSol from'../../assets/img/carta-sol.png'
import cartaMorte from'../../assets/img/carta-morte.png'
import cartaLouco from'../../assets/img/carta-louco.png'
import fundo from '../../assets/img/azul-veludo.jpg'
export const OniExemplo=()=>{
    const[frase,setFrase]=useState('')
    const[mensagens, setMensagens] = useState([]);
    const handleAbrir = () => {
        setMensagens([{ texto: 'Olá! Como posso ajudar?', tipo: 'recebida' }]);
    }
    return<>
            <RecipienteChat>
                <Chat>
                    <CaixaMensagens>
                        <Mensagens>
                            {mensagens.map((mensagem, index) => (
                                mensagem.tipo === "enviada" ? (
                                    <MensagemEnviada key={index}>
                                        Você: {mensagem.texto}
                                    </MensagemEnviada>
                                ) : (
                                    <MensagemRecebida key={index}>
                                        Onisciente: {mensagem.texto}
                                    </MensagemRecebida>
                                )
                            ))}
                        </Mensagens>
                    </CaixaMensagens>


                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            analisarEResponder(frase, (resposta) => {
                                setMensagens([...mensagens, { texto: frase, tipo: "enviada" }, { texto: resposta, tipo: "recebida" }]);
                            });
                            setFrase('');
                        }}
                    >
                        <Separador>
                            <Input
                                onChange={(e)=>{
                                    setFrase(e.target.value)
                                }}
                                value={frase}
                                type="text"
                            />
                            <BtnEnviar>
                                <EnviarIcone />
                            </BtnEnviar>
                        </Separador>
                    </Form>
                </Chat>
                <CartaMorte src={cartaMorte} alt=""/>
                <CartaSol src={cartaSol} alt=""/>
            </RecipienteChat>
    </>
}
const Form=styled.form`
    width: 100%;
    position: absolute;
    bottom: 0;
`
const Chat=styled.div`
    background-color: white;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.27);
    background-image: url(${cartaLouco});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-width: 18rem;
    min-height: 33rem;
    position: relative;
    z-index: 2;

`
const CartaMorte=styled.img`
    min-width: 19rem;
    min-height: 33rem;
    z-index: 1;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.27);
    position: relative;
    margin-left: -10em;
`
const CartaSol=styled.img`
    min-width: 20rem;
    min-height: 33rem;
    position: relative;
    z-index: 0;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.27);
    margin-left: -10em;
`
const RecipienteChat=styled.div`
    background-color: yellow;
    padding: 1em;
    border-radius: 1em;
    display: flex;
    background-image: url(${fundo});
`
const CaixaMensagens = styled.div`
   overflow-y: auto;

`
const MensagemEnviada = styled.p`
    background-color: whitesmoke;
    padding: 0.5rem;
    border-radius: .2rem;
    margin-bottom: .5em;
`;
const MensagemRecebida = styled.p`
  background-color: #f5f5f57b;
  padding: 0.5rem;
  border-radius:.2rem
`;
const BtnEnviar=styled.button`
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 2;
    right: .8em;
    bottom: .7em;
`
const Separador=styled.div`
`
const Mensagens=styled.div`
    font-weight: 200;
    line-height: 1.5em;
    max-width: 18rem;
`
const EnviarIcone=styled(IconeEnviar)`
    color: #3f3f3f;
    
`
const Input=styled.input`
    border:none;
    background-color: whitesmoke;
    height: 2.5em;
    width: 17.8rem;
    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
`
