import styled from "styled-components"
import { Linque } from "ui/ComponentesDeEstilo"

export const SubTopo=()=>{
    return<>
        <Nav>
            <LinqueEstilizado>Cursos</LinqueEstilizado>
            <LinqueEstilizado>|</LinqueEstilizado>
            <LinqueEstilizado>Shopping</LinqueEstilizado>
        </Nav>
    </>
}
const LinqueEstilizado=styled(Linque)`
    margin-left:1em;
    color: #3f3f3f;
`
const Nav=styled.nav`
    background-color: #ffffff;
    position: fixed;
    left:0;
    top:0;
    width:100%;
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: left;
    padding-left:10%;
    z-index: 3;
`