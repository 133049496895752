import React, { useEffect, useState } from 'react';
import { CategoriaController } from '../controllers/CategoriaController';
import styled from 'styled-components';
import { IconeChevronDown } from 'ui/Icones';

export const Menu = () => {
  const [menuAberto, setMenuAberto] = useState(false);
  const [categorias,setCategorias] = useState([])
  const toggleMenu = () => {
    setMenuAberto(!menuAberto);
  };
useEffect(()=>{
  const categoriaController = new CategoriaController();
  categoriaController.listarCategoriasPorQuantidadeESistema(6,setCategorias)
},[])
const handleLancamntos = () => {
  window.location.href = "/#lancamentos"
}
  return (
    <>
      <NavegacaoLocal>
        <IconeHamburguerLocal onClick={toggleMenu} />
        <UlLocal aberto={menuAberto}>
          <LiLocal onClick={handleLancamntos}>Lançamentos</LiLocal>
          <LiLocal to={'/listar-promocoes'}>Promoções</LiLocal>
          {categorias.map(categoria=>{
            return(
              <LiLocal to={`/lista-por-categoria?id=${categoria.id}`}>{categoria.nome}</LiLocal>
            )})
          }
        </UlLocal>
      </NavegacaoLocal>
    </>
  );
};

const NavegacaoLocal = styled.nav`
  background-color: #fff;
  padding: 3rem 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 7rem 0 0;
  }
`;

const UlLocal = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100rem;
  margin-top: 3rem;
  padding: 0 1rem;
  @media (max-width: 768px) {
    display: ${({ aberto }) => (aberto ? 'flex' : 'none')};
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    background-color: ghostwhite; 
    margin-bottom: 1rem;
    margin-top: 0;
  }
`;

const LiLocal = styled.li`
  text-transform: uppercase;
  color: #333;
  cursor: pointer;
  margin: 0 2rem;
  line-height: 3rem;
  &:hover {
    color: #000;
  }
`;

const IconeHamburguerLocal = styled(IconeChevronDown)`
  width: 2rem;
  height: 2rem;
  color: #333;
  cursor: pointer;
  position:absolute;
  left:0;
  top:5rem;
  &:hover {
    color: #000;
  }
  @media (min-width: 769px) {
    display: none;
  }
`;
