import { useEffect } from "react"
import { useState } from "react"
import styled from "styled-components"
import { Linque } from "ui/ComponentesDeEstilo"
import { IconeFavorito } from "ui/Icones"
import { FavoritosController } from "../controllers/FavoritosController"

export const Favoritos=()=>{
    const [mostraConstadorFavoritos, setMostraConstadorFavoritos] = useState(0)
    const [constadorFavoritos, setContadorFavoritos] = useState(0)

    useEffect(() => {
        if (constadorFavoritos > 0) {
          setMostraConstadorFavoritos(true)
        }
      }, [constadorFavoritos])
    useEffect(() => {
    const favoritosController = new FavoritosController()
    favoritosController.contarFavoritos(setContadorFavoritos)
    }, [])
    return<>
        <LinkLocalFavoritos to="/favoritos">
            <IconeFavoritoLocal />
            {mostraConstadorFavoritos > 0 && <QuantidadeDeItens>{constadorFavoritos}</QuantidadeDeItens>}
            </LinkLocalFavoritos>
    </>
}
const LinkLocalFavoritos = styled(Linque)`
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
`
const IconeFavoritoLocal = styled(IconeFavorito)`
    width: 1.8rem; /* Reduza o tamanho do ícone de favoritos */
    height: 1.8rem;
    color: #333;
    margin-left: 0.8rem; /* Ajuste o espaçamento esquerdo */
    @media(max-width: 400px) {
    margin-left: 0;
    }
    cursor: pointer;
    &:hover {
    color: #000;
    }
`
const QuantidadeDeItens = styled.p`
    width: 1.5rem;
    height: 1.5rem;
    background-color: #2b2b2b;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    position: relative;
    top: -0.7rem;
    left: -0.7rem;
`