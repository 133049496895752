import { listarCategoriasPorSistema } from "componentes/painel-de-controle/sistema/Controller"
import { useEffect, useState } from "react"
import styled from "styled-components"
export const MenuGenesi = () => {
    const [categorias, setCategorias] = useState([])
    useEffect(() => {
        listarCategoriasPorSistema(4, setCategorias)
    }, []);
    if(categorias){

    return <>
            <Flex>
                {Array.isArray(categorias) && categorias.map((categoria, index) => (
                    <Divisao key={index}>
                        <Figure>
                            <Imagem src={categoria.url_arquivo} alt={categoria.titulo} />
                            <FigCaption>
                                <Titulo>{categoria.titulo}</Titulo>
                            </FigCaption>
                        </Figure>
                    </Divisao>
                ))}
            </Flex>
    </>
    }else{
        return<>
            Sem categoria cadastrada
        </>
    }
}
const Flex= styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`
const Titulo = styled.h1`
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    color: #3f3f3f;
`
const Divisao = styled.div`
    border-bottom: 1px solid #ccc;
    border-radius: .5rem;
    background-color: whitesmoke;
    box-shadow: 0 0 .1rem rgba(0,0,0,.2);
    border-radius: 1em;
    margin: 1em;
    width: 13rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const Figure = styled.figure`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    `;

const Imagem = styled.img`
    width: 95%;
    height: 11vh;
    object-fit: cover;
    margin: 0 auto;
    border-radius: .2rem;
    object-position: center;
    transition: 0.3s;
    &:hover{
        transform: scale(1.03);
        
    }
`
const FigCaption = styled.figcaption`
    text-align: center;
    position: relative;
    padding: 1em 1rem 0 1rem;
`

